import { useEffect, useState } from 'react';
import { isNull } from 'lodash/fp';
import { round } from 'lodash';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import { formatDecimalNumber, formatMoneyValue, formatPercentValue } from '../../../../../util';
import { translateBackend } from '../../../../../assets/i18n/config';
import { Box, Link } from '../../../../1-primative';
import {
  TableCell,
} from '../../../../2-component';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { currencySymbol, getCurrencyFlag } from '../../../../../util/currency';
import { Holding } from '../../../../../interfaces';
import {
  find1Day, find1Month, find1Week, find3Day,
} from './tableHoldings';

export const SecurityHoldingCell = ({
  holding, type, history, totalMarketValue, useCustodianData, missesFxRate, displayCurrency, isFirst, maxQuantityDecimalPlaces,
}: {
  holding: Holding, type: string, history: any[], totalMarketValue: number,
  useCustodianData?: boolean, missesFxRate?: boolean, displayCurrency?: boolean, isFirst?: boolean, maxQuantityDecimalPlaces?: number,
}) => {
  const { sys } = useThemeTokens();

  const [nativeCurrency, setNativeCurrency] = useState(CurrencyCodes.CAD);

  useEffect(() => {
    setNativeCurrency(holding?.originalCurrency?.currency ?? holding?.currency ?? CurrencyCodes.CAD);
  }, [holding]);

  const getLeadingComponent = () => (
    <Box
      sx={{
        height: 20,
        width: 20,
        borderRadius: 10,
        overflow: 'hidden',
      }}
    >
      <img alt={nativeCurrency} src={getCurrencyFlag(nativeCurrency)} style={{ objectFit: 'cover', height: '100%' }}/>
    </Box>
  );

  const oneDayDifferenceCents = () => (
    round((holding.totalCents ?? 0) - (useCustodianData ? (holding.eodPriceCents ?? 0) * (holding.quantity ?? 0) : ((find1Day(holding, history)?.totalCents) ?? 0)))
  );

  const threeDayDifferenceCents = () => (
    (holding.totalCents ?? 0) - ((find3Day(holding, history)?.totalCents) ?? 0)
  );

  const oneWeekDifferenceCents = () => (
    (holding.totalCents ?? 0) - ((find1Week(holding, history)?.totalCents) ?? 0)
  );

  const oneMonthDifferenceCents = () => (
    (holding.totalCents ?? 0) - ((find1Month(holding, history)?.totalCents) ?? 0)
  );

  switch (type) {
    case 'symbol':
      return (
        <TableCell
          maxWidth='250px'
          sx={{ minWidth: '60px' }}
          leadingComponent={displayCurrency ? getLeadingComponent() : undefined}
          isFirst={isFirst}
        >
          {holding.financialProduct?.ticker || CurrencyCodes.CAD}
        </TableCell>
      );
    case 'securityName':
      return (
        <TableCell maxWidth='250px' leadingComponent={displayCurrency ? getLeadingComponent() : undefined} isFirst={isFirst}>
          {holding.financialProduct?.url ? (
            <Link href={holding.financialProduct.url} target='_blank'>
              {translateBackend(holding.financialProduct.translatedName)}
            </Link>
          ) : (
            <>
              {translateBackend(holding.financialProduct?.translatedName)}
            </>
          )}
        </TableCell>
      );
    case 'quantity':
      return (
        <TableCell right number leadingComponent={displayCurrency ? getLeadingComponent() : undefined} isFirst={isFirst}>
          {isNull(holding.financialProduct?.isCash) || !holding.financialProduct?.isCash
            ? formatDecimalNumber(holding.quantity, maxQuantityDecimalPlaces)
            : '-'}
        </TableCell>
      );
    case 'bookCostNative':
      return (
        <TableCell right number isFirst={isFirst}>
          {isNull(holding.financialProduct?.isCash) || !holding.financialProduct?.isCash
            ? formatMoneyValue(holding?.originalCurrency?.adjustedCostBaseCents ?? holding.adjustedCostBaseCents, currencySymbol[nativeCurrency] ?? '$')
            : '-'}
        </TableCell>
      );
    case 'bookCost':
      return (
        <TableCell right number isFirst={isFirst}>
          {isNull(holding.financialProduct?.isCash) || !holding.financialProduct?.isCash ? (
            formatMoneyValue(holding.adjustedCostBaseCents, currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$')
          ) : '-'}
        </TableCell>
      );
    case 'bookValue':
      return (
        <TableCell right number leadingComponent={displayCurrency ? getLeadingComponent() : undefined} isFirst={isFirst}>
          {isNull(holding.financialProduct?.isCash) || !holding.financialProduct?.isCash
            ? formatMoneyValue((holding.adjustedCostBaseCents ?? 0) * (holding.quantity ?? 0), currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$')
            : '-'}
        </TableCell>
      );
    case 'currentPriceNative':
      return (
        <TableCell right number leadingComponent={displayCurrency ? getLeadingComponent() : undefined} isFirst={isFirst}>
          {isNull(holding.financialProduct?.isCash) || !holding.financialProduct?.isCash
            ? formatMoneyValue((holding?.originalCurrency?.currentPriceCents ?? holding.currentPriceCents), currencySymbol[nativeCurrency] ?? '$')
            : '-'}
        </TableCell>
      );
    case 'currentPrice':
      return (
        <TableCell right number leadingComponent={displayCurrency ? getLeadingComponent() : undefined} isFirst={isFirst}>
          {isNull(holding.financialProduct?.isCash) || !holding.financialProduct?.isCash ? (
            formatMoneyValue(holding.currentPriceCents, currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$')
          ) : '-'}
        </TableCell>
      );
    case 'unrealizedGainLoss':
      return (
        <TableCell
          right
          number
          isFirst={isFirst}
          sx={{
            textAlign: 'right',
            color: ((useCustodianData ? (holding.currentPriceCents ?? 0)
              : (holding.financialProduct?.currentPriceCents ?? 0) * (holding.quantity ?? 0)) - (holding.adjustedCostBaseCents ?? 0) * (holding.quantity ?? 0)) >= 0
              ? sys.color.positive : sys.color.negative,
            whiteSpace: 'nowrap',
          }}
          leadingComponent={displayCurrency ? getLeadingComponent() : undefined}
        >
          {
            isNull(holding.financialProduct?.isCash) || !holding.financialProduct?.isCash ? (
              <>
                {`${formatMoneyValue(
                  ((useCustodianData ? (holding.currentPriceCents ?? 0)
                    : (holding.financialProduct?.currentPriceCents ?? 0)) * (holding.quantity ?? 0)) - ((holding.adjustedCostBaseCents ?? 0) * (holding.quantity ?? 0)),
                  currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$',
                )} `}
                ({formatPercentValue(
                  ((useCustodianData ? (holding.currentPriceCents ?? 0)
                    : (holding.financialProduct?.currentPriceCents ?? 0)) - (holding.adjustedCostBaseCents ?? 0)) / ((holding.adjustedCostBaseCents ?? 0) === 0
                    ? 1 : (holding.adjustedCostBaseCents ?? 0)),
                )})
              </>
            ) : '-'
          }
        </TableCell>
      );
    case 'oneDayChange':
      return (
        <TableCell
          right
          number
          isFirst={isFirst}
          sx={{
            textAlign: 'right',
            color: oneDayDifferenceCents() === 0 ? sys.color.onSurface : oneDayDifferenceCents() >= 0
              ? sys.color.positive : sys.color.negative,
            whiteSpace: 'nowrap',
          }}
          leadingComponent={displayCurrency ? getLeadingComponent() : undefined}
        >
          {
            isNull(holding.financialProduct?.isCash) || !holding.financialProduct?.isCash ? (
              <>
                {`${formatMoneyValue(oneDayDifferenceCents(), currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$')} `}
                ({formatPercentValue(oneDayDifferenceCents() / (useCustodianData ? (holding.eodPriceCents ?? 0) * (holding.quantity ?? 0)
                  : ((find1Day(holding, history)?.totalCents) ?? 0)))})
              </>
            ) : '-'
          }
        </TableCell>
      );
    case 'threeDayChange':
      return (
        <TableCell
          right
          number
          isFirst={isFirst}
          sx={{
            textAlign: 'right',
            color: threeDayDifferenceCents() === 0 ? sys.color.onSurface : threeDayDifferenceCents() >= 0
              ? sys.color.positive : sys.color.negative,
            whiteSpace: 'nowrap',
          }}
          leadingComponent={displayCurrency ? getLeadingComponent() : undefined}
        >
          {
            isNull(holding.financialProduct?.isCash) || !holding.financialProduct?.isCash ? (
              <>
                {`${formatMoneyValue(threeDayDifferenceCents(), currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$')} `}
                ({formatPercentValue((threeDayDifferenceCents()
                ) / ((find3Day(holding, history)?.totalCents) ?? 0))})
              </>
            ) : '-'
          }
        </TableCell>
      );
    case 'oneWeekChange':
      return (
        <TableCell
          right
          number
          isFirst={isFirst}
          sx={{
            textAlign: 'right',
            color: oneWeekDifferenceCents() === 0 ? sys.color.onSurface : oneWeekDifferenceCents() >= 0
              ? sys.color.positive : sys.color.negative,
            whiteSpace: 'nowrap',
          }}
          leadingComponent={displayCurrency ? getLeadingComponent() : undefined}
        >
          {
            isNull(holding.financialProduct?.isCash) || !holding.financialProduct?.isCash ? (
              <>
                {`${formatMoneyValue(oneWeekDifferenceCents(), currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$')} `}
                ({formatPercentValue((oneWeekDifferenceCents()
                ) / ((find1Week(holding, history)?.totalCents) ?? 0))})
              </>
            ) : '-'
          }
        </TableCell>
      );
    case 'oneMonthChange':
      return (
        <TableCell
          right
          number
          isFirst={isFirst}
          sx={{
            textAlign: 'right',
            color: oneMonthDifferenceCents() === 0 ? sys.color.onSurface : oneMonthDifferenceCents() >= 0
              ? sys.color.positive : sys.color.negative,
            whiteSpace: 'nowrap',
          }}
          leadingComponent={displayCurrency ? getLeadingComponent() : undefined}
        >
          {
            isNull(holding.financialProduct?.isCash) || !holding.financialProduct?.isCash ? (
              <>
                {`${formatMoneyValue(oneMonthDifferenceCents(), currencySymbol[holding.currency ?? CurrencyCodes.CAD] ?? '$')} `}
                ({formatPercentValue((oneMonthDifferenceCents()
                ) / ((find1Month(holding, history)?.totalCents) ?? 0))})
              </>
            ) : '-'
          }
        </TableCell>
      );
    case 'percentOfTotal':
      return (
        <TableCell right number leadingComponent={displayCurrency ? getLeadingComponent() : undefined} isFirst={isFirst}>
          {(isNull(holding.financialProduct?.isCash) || !holding.financialProduct?.isCash) ? formatPercentValue((holding.totalCents ?? 0) / totalMarketValue) : '-'}
        </TableCell>
      );
    case 'currentValue':
    case 'marketValueNative':
      return (
        <TableCell right number leadingComponent={displayCurrency ? getLeadingComponent() : undefined} isFirst={isFirst}>
          {formatMoneyValue((holding?.originalCurrency?.totalCents ?? holding.totalCents ?? 0), currencySymbol[nativeCurrency] ?? '$')}
        </TableCell>
      );
    case 'marketValue':
      return (
        <TableCell right number leadingComponent={displayCurrency ? getLeadingComponent() : undefined} isFirst={isFirst}>
          {holding?.originalCurrency ? formatMoneyValue(holding.totalCents, currencySymbol[holding.currency as CurrencyCodes ?? CurrencyCodes.CAD]) : '-'}
        </TableCell>
      );
    default:
      return (
        <TableCell leadingComponent={displayCurrency ? getLeadingComponent() : undefined} isFirst={isFirst}>
          {(holding as any)[type]}
        </TableCell>
      );
  }
};
