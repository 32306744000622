/* eslint-disable max-len */
import {
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/AddRounded';
import dayjs from 'dayjs';
import {
  gql,
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import {
  filter, intersection, isNull, isUndefined, map, pick, pickBy,
} from 'lodash/fp';
import { useMemo, useState } from 'react';
import omitDeep from 'omit-deep-lodash';
import { CountryCodes } from '@onevesthq/ov-enums';
import {
  User, SourceOfFunds, FATCAStatus, TaxIdTypes, eligibleTaxIdTypes,
} from '../../../interfaces';
import { encryptedTaxIdPlaceholder } from '../../../interfaces/user';
import { formatMoneyValue, generateAddressString } from '../../../util';
import { Field } from './profile';
import ClientDetailBox from './clientDetailBox';
import { countryList } from '../../../ovComponents/resources';
import { colors } from '../../../theme/colors';
import DetailItem from './detailItem';
import { usePermissions } from '../../../providers/userContextProvider';
import { useGlobalStats } from '../../../providers/globalStatsHooks';
import { FETCH_IDS, UPDATE_USER_ID } from './iDs';
import { CREATE_USER_ID_VERIFICATION_INFORMATION } from './newIdVerification';
import { FETCH_USER, useClientContext } from '..';
import { FETCH_FILE_DOCUMENTS } from '../../bankAccountsToReview/components/BankAccountDocumentViewer';
import { IdVerificationsTable } from './idVerifications/idVerificationsTable';
import { EditIdVerification } from './idVerifications/editIdVerification';
import ConfirmationModal from '../../../components/modals/confirmationModal';
import { useGlobalToast } from '../../../providers/globalToastProvider';

const headerStyle = {
  fontSize: '12px',
  color: colors.gray500,
};

const infoStyle = {
  fontWeight: 'Bold',
  fontSize: '16px',
};

const chipStyle = {
  height: 'auto',
  py: '6px',
  '& .MuiChip-label': {
    display: 'block',
    whiteSpace: 'normal', // so that long chips wrap rather than overflow
  },
};

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const UPDATE_FRAUD_RISK = gql`
  mutation updateFraudRisk($input: UpdateFraudRiskInput!) {
    updateFraudRisk(input: $input) {
      fraudRisk {
        id
      }
    }
  }
`;

export interface ID {
  documentExpiryDate: string,
  documentID: string,
  documentIssuingJurisdication: string,
  documentType: string,
  id: string,
  methodOfIDVerification: string,
  userName: string,
  verifiedDate: string,
  secondaryDocumentExpiryDate?: string,
  secondaryDocumentID?: string,
  secondaryDocumentIssuingJurisdication?: string,
  secondaryDocumentType?: string,
  secondaryDocumentUserName?: string,
}

// country-specific "missing SSN" or "missing SIN" copy where there's no taxId set
const anticipatedTaxIdType = (countryOfTaxResidence?: CountryCodes): string => {
  if (countryOfTaxResidence === CountryCodes.CA) return 'SIN';
  if (countryOfTaxResidence === CountryCodes.US) return 'SSN';
  return 'Tax ID';
};

const ClientDetails = ({ user }: { user: User }) => {
  const { t } = useTranslation(['feeAndBilling', 'client', 'base']);
  const [idVerification, setIdVerification] = useState<ID | undefined>();
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [documentArray, setDocumentArray] = useState<any[]>();
  const { permissions } = usePermissions();
  const { complianceReviewRefetch } = useGlobalStats();
  const [openConfirmationModal, setOpenconfirmationModal] = useState(false);
  const { showToast } = useGlobalToast();
  const [currentSwitchStage, setCurrentSwitchStage] = useState(false);
  const clientContext = useClientContext();

  const isIndividual = user.type === 'INDIVIDUAL';

  const detailsFields: Field[] = [
    {
      key: 'lastSuitabilityReviewAt',
      permission: 'client_suitability',
      column: 12,
      type: 'date',
      label: t('client:details.profileStatusBox.lastCheckDate'),
    },
    {
      key: 'readyToSignAgreement',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'booleanSelect',
      booleanSelectType: 'TrueOrFalse',
      label: t('client:details.profileStatusBox.clientAgreementsAreReadyToSignTitle'),
    },
  ];

  /* determines applicable Tax ID types per country and per client type (individual/non-individual) */
  const taxIdTypesShown: TaxIdTypes[] = useMemo(() => {
    const country: CountryCodes = user.countryOfTaxResidence
      ? user.countryOfTaxResidence as CountryCodes
      : (clientContext?.orgSettings.applicableLocalization?.countries?.[0] ?? CountryCodes.CA);

    const types = eligibleTaxIdTypes(country, isIndividual);
    if (user.taxIdType && !types.includes(user.taxIdType)) {
      types.push(user.taxIdType);
    }
    return types;
  }, [user, clientContext, isIndividual]);

  const employmentFields: Field[] = [
    {
      key: 'employmentStatus',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'select',
      options: ['EMPLOYED', 'SELF_EMPLOYED', 'STAY_AT_HOME_PARENT', 'UNEMPLOYED', 'STUDENT', 'RETIRED'],
      label: t('client:details.employmentBox.employmentStatusTitle'),
    },
    {
      key: 'jobTitle',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'text',
      label: t('client:details.employmentBox.jobTitle'),
      showIf: {
        employmentStatus: ['EMPLOYED', 'SELF_EMPLOYED'],
      },
      validation: {
        rule: /^(?!^\s*$).*|^$/,
        message: 'client:profile.fieldValidations.noEmpty',
      },
    },
    {
      key: 'companyType',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'text',
      label: t('client:details.employmentBox.companyTypeTitle'),
      showIf: {
        employmentStatus: ['EMPLOYED', 'SELF_EMPLOYED'],
      },
      validation: {
        rule: /^(?!^\s*$).*|^$/,
        message: 'client:profile.fieldValidations.noEmpty',
      },
    },
    {
      key: 'employerName',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'text',
      label: t('client:details.employmentBox.employerNameTitle'),
      showIf: {
        employmentStatus: ['EMPLOYED', 'SELF_EMPLOYED'],
      },
      validation: {
        rule: /^(?!^\s*$).*|^$/,
        message: 'client:profile.fieldValidations.noEmpty',
      },
    },
    {
      key: 'employerCompanyAddress',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'address',
      label: t('client:details.employmentBox.employerCompanyAddressTitle'),
      showIf: {
        employmentStatus: ['EMPLOYED', 'SELF_EMPLOYED'],
      },
      validation: {
        rule: /^(?!^\s*$).*|^$/,
        message: 'client:profile.fieldValidations.noEmpty',
      },
    },
    {
      key: 'jobTitle',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'text',
      label: t('client:details.employmentBox.previous.jobTitle'),
      showIf: {
        employmentStatus: ['UNEMPLOYED', 'RETIRED'],
      },
      validation: {
        rule: /^(?!^\s*$).*|^$/,
        message: 'client:profile.fieldValidations.noEmpty',
      },
    },
    {
      key: 'companyType',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'text',
      label: t('client:details.employmentBox.previous.companyTypeTitle'),
      showIf: {
        employmentStatus: ['UNEMPLOYED', 'RETIRED'],
      },
      validation: {
        rule: /^(?!^\s*$).*|^$/,
        message: 'client:profile.fieldValidations.noEmpty',
      },
    },
    {
      key: 'employerName',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'text',
      label: t('client:details.employmentBox.previous.employerNameTitle'),
      showIf: {
        employmentStatus: ['UNEMPLOYED', 'RETIRED'],
      },
      validation: {
        rule: /^(?!^\s*$).*|^$/,
        message: 'client:profile.fieldValidations.noEmpty',
      },
    },
    {
      key: 'employerCompanyAddress',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'address',
      label: t('client:details.employmentBox.previous.employerCompanyAddressTitle'),
      showIf: {
        employmentStatus: ['UNEMPLOYED', 'RETIRED'],
      },
      validation: {
        rule: /^(?!^\s*$).*|^$/,
        message: 'client:profile.fieldValidations.noEmpty',
      },
    },
    {
      key: 'employmentSituation',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'select',
      options: ['STABLE_AND_SECURE', 'STABLE_BUT_MAY_CHANGE_IN_FUTURE', 'SOMEWHAT_STABLE', 'UNEMPLOYED_BUT_LOOKING', 'OTHER'],
      label: t('client:details.employmentBox.employmentSituationTitle'),
    },
    {
      key: 'employmentSituationOtherDescription',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'text',
      label: t('client:details.employmentBox.employmentSituationOtherDescriptionTitle'),
      showIf: {
        employmentSituation: ['OTHER'],
      },
      validation: {
        rule: /^(?!^\s*$).*|^$/,
        message: 'client:profile.fieldValidations.noEmpty',
      },
    },
    {
      key: 'studentAreaOfStudy',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'text',
      label: t('client:details.employmentBox.studentAreaOfStudy'),
      showIf: {
        employmentStatus: ['STUDENT'],
      },
      validation: {
        rule: /^(?!^\s*$).*|^$/,
        message: 'client:profile.fieldValidations.noEmpty',
      },
    },
  ];

  const entityInfoFields: Field[] = [
    {
      key: 'type',
      permission: 'client_basic',
      label: t('nonIndividualClients:entityType'),
      type: 'nonIndividualEntityType',
      column: 12,
    },
    {
      key: 'companyType',
      permission: 'client_low_risk_pii',
      label: t('nonIndividualClients:natureOfBusiness'),
      column: 12,
    },
    {
      key: 'countryOfRegistration',
      permission: 'client_basic',
      label: t('client:details.entityInformation.countryOfRegistration'),
      column: 6,
    },
    {
      key: 'registrationNumber',
      permission: 'client_basic',
      label: t('client:details.entityInformation.registrationNumber'),
      column: 6,
    },
  ];
  const feeFields: Field[] = [
    {
      key: 'feeTierId',
      permission: 'client_group',
      column: 12,
      label: t('feeAndBilling:feeReport.table.feeGrid'),
      type: 'feeTier',
    },
    {
      key: 'billingScheduleId',
      permission: 'client_group',
      column: 12,
      label: t('feeAndBilling:feeReport.table.billingSchedule'),
      type: 'billingSchedule',
    },
  ];
  const suitabilityFields: Field[] = [
    ...(isIndividual ? [
      ...(user.annualIncomeStr ? [{
        key: 'annualIncomeStr',
        permission: 'client_low_risk_pii',
        column: 12,
        label: t('client:details.suitabilityBox.annualIncomeRangeTitle'),
        type: 'text',
        required: false,
      }] : []),
      {
        key: 'annualIncomeCents',
        permission: 'client_suitability',
        column: 4,
        label: t('client:details.suitabilityBox.annualIncomeTitle'),
        type: 'cents',
        readonly: !!user.annualIncomeStr,
      },
      {
        key: 'financialLiquidAssetsCents',
        permission: 'client_suitability',
        column: 4,
        label: t('client:details.suitabilityBox.totalLiquidAssetsTitle'),
        type: 'cents',
      },
      {
        key: 'financialFixedAssetsCents',
        permission: 'client_suitability',
        column: 4,
        label: t('client:details.suitabilityBox.totalFixedAssetsTitle'),
        type: 'cents',
      },
      {
        key: 'totalDebtCents',
        permission: 'client_suitability',
        column: 4,
        label: t('client:details.suitabilityBox.totalDebtTitle'),
        type: 'cents',
      },
      {
        key: 'annualDebtPaymentsCents',
        permission: 'client_suitability',
        column: 8,
        label: t('client:details.suitabilityBox.annualDebtPaymentsTitle'),
        type: 'cents',
      },
    ] : []) as Field[],
    {
      key: 'sourceOfFunds',
      permission: 'client_suitability',
      column: 12,
      type: 'multiSelect',
      label: t('client:details.suitabilityBox.sourceOfFundsTitle'),
      options: Object.values(SourceOfFunds),
    },
    {
      key: 'sourceOfFundsOtherDescription',
      permission: 'client_suitability',
      column: 12,
      type: 'text',
      label: t('client:details.suitabilityBox.sourceOfFundsOtherDescriptionTitle'),
      showIf: {
        sourceOfFunds: ['OTHER'],
      },
    },
    ...(isIndividual ? [
      {
        key: 'sourceOfWealth',
        permission: 'client_suitability',
        column: 12,
        type: 'multiSelect',
        label: t('client:details.suitabilityBox.sourceOfWealthTitle'),
        options: [
          'EMPLOYMENT_INCOME', 'SALE_OF_LARGE_ASSET', 'PROPERTY_SALE', 'INHERITANCE',
          'FAMILY_ESTATE', 'INVESTMENT', 'BUSINESS_OWNERSHIP_INTEREST', 'DEBT', 'LOTTERY_WINNING', 'OTHER',
        ],
      },
      {
        key: 'sourceOfWealthOtherDescription',
        permission: 'client_suitability',
        column: 12,
        type: 'text',
        label: t('client:details.suitabilityBox.sourceOfWealthOtherDescriptionTitle'),
        showIf: {
          sourceOfWealth: ['OTHER'],
        },
      },
    ] : []) as Field[],
    {
      key: 'investmentKnowledge',
      permission: 'client_suitability',
      column: isIndividual ? 6 : 12,
      type: 'select',
      label: t('client:details.suitabilityBox.investmentKnowledgeTitle'),
      options: ['NONE', 'LIMITED', 'SOME', 'EXPERT'],
    },
    ...(isIndividual ? [
      {
        key: 'isAccreditedInvestor',
        permission: 'client_suitability',
        column: 6,
        type: 'booleanSelect',
        label: t('client:details.suitabilityBox.isAccreditedInvestorTitle'),
      },
    ] : []) as Field[],
    {
      key: 'riskQuestion1',
      permission: 'client_suitability',
      column: 12,
      type: 'select',
      label: t('client:details.suitabilityBox.riskQuestion1Title'),
      // TODO OV-7529 more intuitive risk level questions
      options: ['LEVEL_1', 'LEVEL_2', 'LEVEL_3', 'LEVEL_4', 'LEVEL_5'],
    },
    {
      key: 'riskQuestion2',
      permission: 'client_suitability',
      column: 12,
      type: 'select',
      label: t('client:details.suitabilityBox.riskQuestion2Title'),
      // TODO OV-7529 more intuitive risk level questions
      options: ['LEVEL_1', 'LEVEL_2', 'LEVEL_3', 'LEVEL_4', 'LEVEL_5'],
    },
    {
      key: 'uniqueCircumstances',
      permission: 'client_suitability',
      column: 12,
      type: 'note',
      label: t('client:details.suitabilityBox.uniqueCircumstancesTitle'),
      validation: {
        message: 'client:profile.fieldValidations.noEmpty',
        maxLength: 1000,
      },
    },
  ];

  const complianceFields: Field[] = [
    {
      key: 'isOfficerOfPublicCompany',
      permission: 'client_low_risk_pii',
      column: 6,
      type: 'booleanSelect',
      placeholder: t('client:details.placeholders.selectAnOption'),
      label: t('client:details.personAndEntitiesOfInterestBox.officerOfAPublicCompanyTitle'),
    },
    {
      key: 'isOwnerOfPublicCompany',
      permission: 'client_low_risk_pii',
      column: 6,
      type: 'booleanSelect',
      label: t('client:details.personAndEntitiesOfInterestBox.ownerOfPublicCompanyTitle'),
    },
    {
      key: 'ssSymbols',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'text',
      placeholder: t('client:details.placeholders.companySymbol'),
      label: t('client:details.personAndEntitiesOfInterestBox.publicCompanySymbol'),
    },
    {
      key: 'isMemberOfIiroc',
      permission: 'client_low_risk_pii',
      column: 6,
      type: 'booleanSelect',
      label: t('client:details.personAndEntitiesOfInterestBox.memberOfIIROCTitle'),
    },
    {
      key: 'insiderSymbols',
      permission: 'client_low_risk_pii',
      column: 6,
      type: 'text',
      placeholder: t('client:details.placeholders.companySymbol'),
      label: t('client:details.personAndEntitiesOfInterestBox.IIROCCompanySymbolTitle'),
    },
    {
      key: 'politicallyExposedDomesticPerson',
      permission: 'client_low_risk_pii',
      column: 6,
      type: 'booleanSelect',
      label: t('client:details.personAndEntitiesOfInterestBox.politicallyExposedDomesticPersonTitle'),
    },
    {
      key: 'politicallyExposedForeignPerson',
      permission: 'client_low_risk_pii',
      column: 6,
      type: 'booleanSelect',
      label: t('client:details.personAndEntitiesOfInterestBox.politicallyExposedForeignPersonTitle'),
    },
    {
      key: 'closeAssociateOfPEP',
      permission: 'client_low_risk_pii',
      column: 6,
      type: 'booleanSelect',
      label: t('client:details.personAndEntitiesOfInterestBox.closeAssociateOfPEPTitle'),
    },
    {
      key: 'headOfInternationalOrganization',
      permission: 'client_low_risk_pii',
      column: 6,
      type: 'booleanSelect',
      label: t('client:details.personAndEntitiesOfInterestBox.headOfInternationalOrganizationTitle'),
    },
    {
      key: 'powerOfAttorneyGranted',
      permission: 'client_low_risk_pii',
      column: 6,
      type: 'booleanSelect',
      label: t('client:details.personAndEntitiesOfInterestBox.powerOfAttorneyGrantedTitle'),
    },
    {
      key: 'isVulnerablePerson',
      permission: 'client_basic',
      column: 6,
      type: 'booleanSelect',
      label: t('client:details.personAndEntitiesOfInterestBox.isVulnerablePerson'),
    },
    {
      key: 'complianceState',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'select',
      options: ['APPROVED', 'NEEDS_REVIEW', 'REJECTED'],
      label: t('client:details.personAndEntitiesOfInterestBox.complianceStateTitle'),
    },
    {
      key: 'complianceIssueSource',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'text',
      placeholder: t('client:details.placeholders.issueSource'),
      label: t('client:details.personAndEntitiesOfInterestBox.complianceIssueSourceTitle'),
    },
  ];
  const nonIndividualComplianceFields: Field[] = [
    {
      key: 'isOwnerOfPublicCompany',
      permission: 'client_low_risk_pii',
      column: 6,
      type: 'booleanSelect',
      label: t('client:details.personAndEntitiesOfInterestBox.ownerOfPublicCompanyTitle'),
    },
    {
      key: 'isForThirdParty',
      permission: 'client_low_risk_pii',
      column: 6,
      type: 'booleanSelect',
      label: t('client:details.entityOfInterestBox.isForThirdPartyTitle'),
    },
    {
      key: 'ssSymbols',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'text',
      label: t('client:details.personAndEntitiesOfInterestBox.publicCompanySymbol'),
      showIf: {
        isOwnerOfPublicCompany: [true],
      },
    },
    {
      key: 'isEntityRegulated',
      permission: 'client_basic',
      column: 12,
      type: 'booleanSelect',
      label: t('client:details.entityOfInterestBox.isEntityRegulatedTitle'),
    },
    {
      key: 'regulatorName',
      permission: 'client_basic',
      column: 6,
      type: 'text',
      label: t('client:details.entityOfInterestBox.regulatorNameTitle'),
      showIf: {
        isEntityRegulated: [true],
      },
    },
    {
      key: 'firmNumber',
      permission: 'client_basic',
      column: 6,
      type: 'text',
      label: t('client:details.entityOfInterestBox.regulatorRegistrationNumber'),
      showIf: {
        isEntityRegulated: [true],
      },
    },
    {
      key: 'isReportingIssuer',
      permission: 'client_basic',
      column: 12,
      type: 'booleanSelect',
      label: t('client:details.entityOfInterestBox.isReportingIssuerTitle'),
    },
    {
      key: 'issuerName',
      permission: 'client_basic',
      column: 4,
      type: 'text',
      label: t('client:details.entityOfInterestBox.issuerNameTitle'),
      showIf: {
        isReportingIssuer: [true],
      },
    },
    {
      key: 'issuerExchange',
      permission: 'client_basic',
      column: 4,
      type: 'text',
      label: t('client:details.entityOfInterestBox.issuerExchangeTitle'),
      showIf: {
        isReportingIssuer: [true],
      },
    },
    {
      key: 'issuerTicker',
      permission: 'client_basic',
      column: 4,
      type: 'text',
      label: t('client:details.entityOfInterestBox.issuerTickerTitle'),
      showIf: {
        isReportingIssuer: [true],
      },
    },
    {
      key: 'isNonProfit',
      permission: 'client_basic',
      column: 12,
      type: 'booleanSelect',
      label: t('client:details.entityOfInterestBox.isNonProfitTitle'),
      placeholder: t('client:details.placeholders.selectAnOption'),
    },
    {
      key: 'isRegisteredWithCRA',
      permission: 'client_basic',
      column: 6,
      type: 'booleanSelect',
      label: t('client:details.entityOfInterestBox.isRegisteredWithCRATitle'),
      placeholder: t('client:details.placeholders.selectAnOption'),
      showIf: {
        isNonProfit: [true],
      },
    },
    {
      key: 'receivesDonationsFromPublic',
      permission: 'client_basic',
      column: 6,
      type: 'booleanSelect',
      label: t('client:details.entityOfInterestBox.receivesDonationsFromPublic'),
      placeholder: t('client:details.placeholders.selectAnOption'),
      showIfAny: {
        isNonProfit: [true],
      },
    },
    {
      key: 'charityRegistrationNumber',
      permission: 'client_basic',
      column: 12,
      type: 'text',
      placeholder: t('client:details.placeholders.registrationNumber'),
      label: t('client:details.entityOfInterestBox.charityRegistrationNumber'),
      showIf: {
        isNonProfit: [true],
        isRegisteredWithCRA: [true],
      },
    },
    {
      key: 'isAFinancialEntity',
      permission: 'client_basic',
      column: 6,
      type: 'booleanSelect',
      label: t('client:details.entityOfInterestBox.isAFinancialEntityTitle'),
    },
    {
      key: 'isAffiliateOfFinancialEntity',
      permission: 'client_basic',
      column: 6,
      type: 'booleanSelect',
      label: t('client:details.entityOfInterestBox.isAffiliateOfFinancialEntityTitle'),
    },
    {
      key: 'instructionsFromFinancialEntity',
      permission: 'client_basic',
      column: 12,
      type: 'booleanSelect',
      label: t('client:details.entityOfInterestBox.instructionsFromFinancialEntityTitle'),
    },
    {
      key: 'isPublicBody',
      permission: 'client_basic',
      column: 6,
      type: 'booleanSelect',
      label: t('client:details.entityOfInterestBox.isPublicBodyTitle'),
    },
    {
      key: 'isLargeCorporation',
      permission: 'client_basic',
      column: 6,
      type: 'booleanSelect',
      label: t('client:details.entityOfInterestBox.isLargeCorporation'),
    },
    {
      key: 'complianceState',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'select',
      options: ['APPROVED', 'NEEDS_REVIEW', 'REJECTED'],
      label: t('client:details.personAndEntitiesOfInterestBox.complianceStateTitle'),
    },
    {
      key: 'complianceIssueSource',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'text',
      placeholder: t('client:details.placeholders.issueSource'),
      label: t('client:details.personAndEntitiesOfInterestBox.complianceIssueSourceTitle'),
    },
  ];
  const riskLevelFields: Field[] = [
    {
      key: 'riskReasons',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'riskReason',
      label: t('client:details.riskLevelBox.reason'),
    },
    {
      key: 'score',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'select',
      placeholder: t('client:details.placeholders.selectRiskLevel'),
      label: t('client:details.riskLevelBox.newFraudRiskScore'),
      options: ['0', '1', '2', '3'],
    },
    {
      key: 'notes',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'note',
      placeholder: t('client:details.placeholders.note'),
      label: t('client:details.riskLevelBox.notes'),
    },
  ];

  let citizenshipAndTaxStatusFields: Field[] = [
    ...(isIndividual ? [
      {
        key: 'maritalStatus',
        permission: 'client_low_risk_pii',
        label: t('client:details.maritalStatus'),
        column: 6,
        type: 'select',
        options: ['SINGLE', 'MARRIED', 'COMMON_LAW', 'DIVORCED', 'SEPARATED', 'WIDOWED', 'CIVIL_UNION'],
      },
      {
        key: 'numberOfDependents',
        permission: 'client_low_risk_pii',
        label: t('client:details.citizenshipAndTaxStatusInformationBox.numberOfDependents'),
        column: 6,
        type: 'number',
        placeholder: 0,
        decimalPlaces: 0,
        validation: {
          rule: /^[0-9]*$/,
          message: 'client:profile.fieldValidations.number',
        },
      },
      {
        key: 'citizenships',
        permission: 'client_low_risk_pii',
        label: t('client:details.citizenshipAndTaxStatusInformationBox.citizenshipsTitle'),
        column: 6,
        type: 'citizenship',
      },
    ] : [
      {
        key: 'taxNumber',
        permission: 'client_basic',
        label: t('client:details.citizenshipAndTaxStatusInformationBox.taxNumber'),
        column: 6,
      },
      {
        key: 'yearEnd',
        type: 'date',
        permission: 'client_basic',
        label: t('client:details.citizenshipAndTaxStatusInformationBox.yearEnd'),
        defaultValue: null,
        column: 6,
      },
      {
        key: 'fatcaStatus',
        permission: 'client_basic',
        label: t('client:edit.fatcaStatus'),
        column: 6,
        type: 'select',
        options: ['PUBLICLY_TRADED_NFFE', 'INTERNATIONAL_ORGANIZATION', 'GOVERNMENT', 'CENTRAL_BANK', 'ACTIVE_NFFE', 'PASSIVE_NFFE', 'OTHER'],
      },
      {
        key: 'fatcaStatusOtherDescription',
        permission: 'client_basic',
        label: t('client:edit.fatcaStatusOtherDescription'),
        column: 12,
        showIf: {
          fatcaStatus: 'OTHER',
        },
      },
    ]) as Field[],
    {
      key: 'countryOfTaxResidence',
      label: t('client:details.citizenshipAndTaxStatusInformationBox.countryOfTaxResidenceTitle'),
      permission: 'client_low_risk_pii',
      column: 6,
      options: 'singleSelect',
      type: 'citizenship',
    },
  ];

  if (taxIdTypesShown.length > 0) {
    citizenshipAndTaxStatusFields.push(
      taxIdTypesShown.length > 1
        ? {
          key: 'taxIdType',
          type: 'radioGroup',
          permission: 'client_high_risk_pii',
          column: 12,
          label: t('client:details.employmentBox.taxIdTypeTitle'),
          options: taxIdTypesShown,
        } : {
          key: 'taxIdType',
          type: 'hidden',
          column: 12,
          permission: 'client_high_risk_pii',
        },
    );
    citizenshipAndTaxStatusFields.push({
      key: 'taxId',
      type: 'taxId',
      permission: 'client_high_risk_pii',
      column: 12,
      dontFetch: true,
    });
  }

  citizenshipAndTaxStatusFields = [...citizenshipAndTaxStatusFields, {
    key: 'foreignTaxInformation',
    permission: 'client_high_risk_pii',
    column: 12,
    type: 'foreignTax',
  },
  ];
  const trustedContactFields: Field[] = [
    {
      key: 'name',
      permission: 'client_high_risk_pii',
      column: 6,
      label: t('client:details.trustedContact.name'),
      type: 'text',
      required: true,
      validation: {
        rule: /.*\S.*/,
        message: 'client:details.trustedContact.noEmpty',
      },
      parentObject: 'trustedContactPerson',
    },
    {
      key: 'relation',
      permission: 'client_low_risk_pii',
      label: t('client:details.trustedContact.relation'),
      column: 6,
      type: 'select',
      options: ['CHILD', 'FRIEND', 'GRANDPARENT', 'OTHER', 'PARENT', 'SIBLING', 'SPOUSE'],
      required: true,
      validation: {
        rule: /.*\S.*/,
        message: 'client:details.trustedContact.noEmpty',
      },
      parentObject: 'trustedContactPerson',
    },
    {
      key: 'email',
      permission: 'client_high_risk_pii',
      column: 6,
      label: t('client:details.trustedContact.email'),
      type: 'text',
      required: true,
      validation: {
        rule: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        message: 'client:details.trustedContact.emailFormatting',
      },
      parentObject: 'trustedContactPerson',
    },
    {
      key: 'phone',
      permission: 'client_high_risk_pii',
      column: 6,
      label: t('client:details.trustedContact.phone'),
      type: 'text',
      required: true,
      validation: {
        rule: /^(?:\+?1)?(?:[-.\s]?)?(?:\(?([2-9][0-9]{2})\)?[-.\s]?([2-9][0-9]{2})[-.\s]?([0-9]{4}))$/,
        message: 'client:details.trustedContact.phoneFormatting',
      },
      parentObject: 'trustedContactPerson',
    },
    {
      key: 'physicalAddress',
      permission: 'client_high_risk_pii',
      column: 12,
      label: t('client:details.trustedContact.address'),
      type: 'address',
      required: true,
      validation: {
        rule: /.*\S.*/,
        message: 'client:details.trustedContact.noEmpty',
      },
      parentObject: 'trustedContactPerson',
    },
  ];
  const spouseInformationFields: Field[] = [
    {
      key: 'spouseFirstName',
      permission: 'client_low_risk_pii',
      label: t('client:details.spouseInformationBox.spouseFirstName'),
      column: 6,
      type: 'text',
    },
    {
      key: 'spouseMiddleName',
      permission: 'client_low_risk_pii',
      label: t('client:details.spouseInformationBox.spouseMiddleName'),
      column: 6,
      type: 'text',
    },
    {
      key: 'spouseLastName',
      permission: 'client_low_risk_pii',
      label: t('client:details.spouseInformationBox.spouseLastName'),
      column: 6,
      type: 'text',
    },
    {
      key: 'spouseEmail',
      permission: 'client_high_risk_pii',
      column: 6,
      label: t('client:details.spouseInformationBox.spouseEmail'),
      type: 'text',
      validation: {
        rule: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$|^$/,
        message: 'client:details.trustedContact.emailFormatting',
      },
    },
    {
      key: 'spouseAddress',
      permission: 'client_high_risk_pii',
      column: 12,
      label: t('client:details.spouseInformationBox.spouseAddress'),
      type: 'address',
      required: true,
    },
    {
      key: 'spouseEmploymentType',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'text',
      label: t('client:details.spouseInformationBox.spouseEmploymentType'),
    },
    {
      key: 'spouseEmployerName',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'text',
      label: t('client:details.spouseInformationBox.spouseEmployerName'),
    },
    {
      key: 'spouseJobTitle',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'text',
      label: t('client:details.spouseInformationBox.spouseJobTitle'),
    },
    {
      key: 'spouseCompanyType',
      permission: 'client_low_risk_pii',
      column: 12,
      type: 'text',
      label: t('client:details.spouseInformationBox.spouseCompanyType'),
    },
  ];
  const [updateUserID] = useMutation(UPDATE_USER_ID, {
    variables: {
      input: {
        documentExpiryDate: idVerification?.documentExpiryDate,
        documentID: idVerification?.documentID,
        documentIssuingJurisdication: idVerification?.documentIssuingJurisdication,
        documentType: idVerification?.documentType,
        idVerificationId: idVerification?.id,
        methodOfIDVerification: idVerification?.methodOfIDVerification,
        userName: idVerification?.userName,
        verifiedDate: idVerification?.verifiedDate || dayjs().format('YYYY-MM-DD'),
        secondaryDocumentExpiryDate: idVerification?.secondaryDocumentExpiryDate ?? undefined,
        secondaryDocumentID: idVerification?.secondaryDocumentID ?? undefined,
        secondaryDocumentIssuingJurisdication: idVerification?.secondaryDocumentIssuingJurisdication ?? undefined,
        secondaryDocumentType: idVerification?.secondaryDocumentType ?? undefined,
        secondaryDocumentUserName: idVerification?.secondaryDocumentUserName ?? undefined,
      },
    },
    refetchQueries: [FETCH_IDS, FETCH_USER(permissions)],
  });

  const [fetchFleDocuments] = useLazyQuery(FETCH_FILE_DOCUMENTS, {
    variables: {
      input: {
        filter: {
          sourceIds: [idVerification?.id],
          types: ['ID_VERIFICATION'],
        },
      },
    },
    onCompleted: (data: any) => {
      setDocumentArray(data?.fetchFileDocuments?.fileDocuments || []);
    },
  });
  const [createUserIDVerificationInformation] = useMutation(CREATE_USER_ID_VERIFICATION_INFORMATION, {
    variables: {},
    refetchQueries: [FETCH_IDS, FETCH_USER(permissions)],
  });

  const [updateFraudRisk] = useMutation(UPDATE_FRAUD_RISK, {
    variables: {},
    refetchQueries: [FETCH_USER(permissions)],
  });

  const isNullOrUndefined = (fields: any[]): boolean => fields.includes(undefined) || fields.includes(null);

  const hasSuitabilityReview = (): boolean => !!user.lastSuitabilityReviewAt;

  const hasTrustedContactPerson = (): boolean => !!user.trustedContactPerson;

  const isSuitabilityReviewRecent = (): boolean => {
    const presentDate = dayjs();
    const differentMonth = presentDate.diff(user.lastSuitabilityReviewAt, 'month');
    return differentMonth < 12;
  };

  const hasEmployeeStatus = (): boolean => !(user.employmentStatus === undefined || user.employmentStatus === null);
  const employmentSectionHasIncompleteFields = () => isNullOrUndefined([user.employmentStatus, (user.companyType || user.jobTitle || user.studentAreaOfStudy)]);
  const spouseSectionHasIncompleteFields = () => isNullOrUndefined([user.spouseFirstName, (user.spouseJobTitle || user.spouseCompanyType || user.spouseLastName)]);

  const personsOfInterestSectionHasIncompleteFields = (): boolean => user.isOfficerOfPublicCompany === undefined || user.isOwnerOfPublicCompany === undefined
    || (user.isOwnerOfPublicCompany && user.ssSymbols === undefined) || (user.isOwnerOfPublicCompany && user.ssSymbols && user.ssSymbols.length === 0)
    || user.isMemberOfIiroc === undefined || (user.isMemberOfIiroc && user.insiderSymbols === undefined) || (user.isMemberOfIiroc && user.insiderSymbols && user.insiderSymbols.length === 0)
    || user.politicallyExposedDomesticPerson === undefined || user.politicallyExposedForeignPerson === undefined
    || user.closeAssociateOfPEP === undefined || user.headOfInternationalOrganization === undefined
    || user.powerOfAttorneyGranted === undefined
    || user.isVulnerablePerson === undefined
    || user.isOwnerOfEntity === undefined || !(user.complianceState);

  const entityOfInterestSectionHasIncompleteFields = (): boolean => (
    user.isOwnerOfPublicCompany === undefined
    || user.isForThirdParty === undefined
    || (user.isOwnerOfPublicCompany && user.ssSymbols === undefined)
    || (user.isOwnerOfPublicCompany && user.ssSymbols && user.ssSymbols.length === 0)
    || user.isEntityRegulated === undefined
    || (user.isEntityRegulated && !user.regulatorName)
    || (user.isEntityRegulated && !user.firmNumber)
    || user.isReportingIssuer === undefined
    || (user.isReportingIssuer && !user.issuerName)
    || (user.isReportingIssuer && !user.issuerExchange)
    || (user.isReportingIssuer && !user.issuerTicker)
    || user.isNonProfit === undefined
    || (user.isNonProfit && user.isRegisteredWithCRA === undefined)
    || (user.isNonProfit && user.receivesDonationsFromPublic === undefined)
    || (user.isNonProfit && user.isRegisteredWithCRA && !user.charityRegistrationNumber)
    || (user.isAFinancialEntity === undefined)
    || (user.isAffiliateOfFinancialEntity === undefined)
    || (user.instructionsFromFinancialEntity === undefined)
    || user.isPublicBody === undefined
    || user.isLargeCorporation === undefined
  );

  const suitabilityScoreSectionHasIncompleteFields = isNullOrUndefined([user.riskQuestion1, user.riskQuestion2]);

  const citizenshipSectionHasIncompleteFields = (): boolean => isNullOrUndefined([user.maritalStatus, user.countryOfTaxResidence]) || user.citizenships?.length === 0;

  const taxSectionHasIncompleteFields = (): boolean => isNullOrUndefined([user.taxNumber, user.yearEnd, user.countryOfTaxResidence]);

  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    refetchQueries: [FETCH_USER(permissions)],
  });

  const fetchAndUpdateFields = (data: Partial<User>, fields: Field[]) => {
    const updateAbleFields = map((x: Field) => x.key, filter((x: Field) => permissions.includes(`write:${x.permission}`), fields));
    const updateUserData: Partial<User> = omitDeep(data, '__typename');
    if (updateUserData.taxId === undefined) delete updateUserData.taxIdType;
    updateUser({
      variables: {
        input: {
          userId: user.id,
          ...pick(intersection(updateAbleFields, fields.map((x: Field) => x.key)), pickBy((value) => !isNull(value), updateUserData)),
        },
      },
    });
  };

  const fetchAndUpdateEntityOfInterestFields = (data: any, fields: Field[]) => {
    const updateAbleFields = map((x: Field) => x.key, filter((x: Field) => permissions.includes(`write:${x.permission}`), fields));
    const picChangedValues = pick(intersection(updateAbleFields, fields.map((x: Field) => x.key)), pickBy((value) => !isNull(value), omitDeep(data, '__typename')));
    const pickNullValues: any = pick(intersection(updateAbleFields, fields.filter((a: Field) => a.type === 'booleanSelect').map((x: Field) => x.key)), pickBy((value) => (isNull(value) || isUndefined(value)), omitDeep(data, '__typename')));
    /* eslint-disable guard-for-in */
    for (const key in pickNullValues) {
      pickNullValues[key] = false;
    }
    updateUser({
      variables: {
        input: {
          userId: user.id,
          ...picChangedValues,
          ...pickNullValues,
        },
      },
    });
  };

  const fetchAndUpdateEmployementFields = (data: any, fields: Field[]) => {
    const updateAbleFields = map((x: Field) => x.key, filter((x: Field) => permissions.includes(`write:${x.permission}`), fields));
    const updatingEmpData: any = pick(intersection(updateAbleFields, fields.map((x: Field) => x.key)), pickBy((value) => !isNull(value), omitDeep(data, '__typename')));
    updateUser({
      variables: {
        input: {
          ...updatingEmpData,
          userId: user.id,
          employmentSituation: updatingEmpData.employmentSituation === '' ? undefined : updatingEmpData.employmentSituation,
        },
      },
    });
  };

  const fetchUpdateTrustedContactPerson = (data: any, fields: Field[]) => {
    const updateAbleFields = map((x: Field) => x.key, filter((x: Field) => permissions.includes(`write:${x.permission}`), fields));
    const updatingTrustedContact = pick(intersection(updateAbleFields, fields.map((x: Field) => x.key)), pickBy((value) => !isNull(value), data.trustedContactPerson));
    const newTrustedContact = {
      ...updatingTrustedContact,
      physicalAddress: {
        ...updatingTrustedContact.physicalAddress,
        __typename: undefined,
      },
    };
    updateUser({
      variables: {
        input: {
          userId: user.id,
          trustedContactPerson: {
            ...newTrustedContact,
          },
        },
      },
    }).then();
  };

  const updateIdCheckRetrigger = async (updatingUser: User, iDCheckRetrigger: boolean) => {
    updateUser({
      variables: {
        input: {
          userId: updatingUser.id,
          iDCheckRetrigger,
        },
      },
    }).then();
    if (!currentSwitchStage) {
      showToast({ severity: 'warning', message: t('client:confirmationDialog.warning') });
    }
    setOpenconfirmationModal(false);
  };

  const isEntityOfInterest = user?.incompleteFields?.some(
    (item) => ['isOfficerOfPublicCompany', 'isOwnerOfPublicCompany', 'isMemberOfIiroc', 'politicallyExposedForeignPerson',
      'politicallyExposedDomesticPerson', 'closeAssociateOfPEP', 'headOfInternationalOrganization',
      'isNonProfit', 'isRegisteredWithCRA', 'isEntityRegulated', 'isReportingIssuer',
      'isAFinancialEntity', 'isAffiliateOfFinancialEntity', 'instructionsFromFinancialEntity',
      'isPublicBody',
    ].includes(item),
  );

  const complianceState = user.complianceState === 'APPROVED' ? 'Completed' : 'Error';

  const HorizontalDivider = () => <Grid item xs={12}><hr color={colors.neutral40} /></Grid>;

  return (
    <Grid container spacing={2} sx={{ overflowWrap: 'break-word' }}>
      <Grid item xs={12} lg={6}>
        {/* profile status */}
        {permissions.includes('read:client_suitability') && (
          <ClientDetailBox
            title={t('client:details.profileStatusBox.header')}
            user={{ ...user, lastSuitabilityReviewAt: dayjs(user.lastSuitabilityReviewAt).format('YYYY-MM-DD') }}
            section="DETAILS"
            buttonText={t('client:edit.buttons.save')}
            modalButton={t('client:form.edit')}
            fields={detailsFields}
            state={hasSuitabilityReview() ? 'Completed' : 'Empty'}
            meta={{
              canUpdate: permissions.includes('write:client_suitability'),
              emptyMeta: {
                emptyStateType: 'Not Available',
                notAvailableMeta: {
                  header: t('client:details.profileStatusBox.notAvailableMeta.header'),
                  body: t('client:details.profileStatusBox.notAvailableMeta.body'),
                },
              },
            }}
            onUpdate={(data) => fetchAndUpdateFields(data, detailsFields)}
          >
            <Grid container spacing={2}>
              <DetailItem
                showValue={isSuitabilityReviewRecent()}
                title={t('client:details.profileStatusBox.annualCheckStatusTitle')}
                checker={isSuitabilityReviewRecent}
                value={t('client:details.profileStatusBox.approved')}
                valueType="text"
                errorMessage={t('client:details.profileStatusBox.overdue')}
              />
              <DetailItem
                showValue
                title={t('client:details.profileStatusBox.lastCheckDate')}
                checker={isSuitabilityReviewRecent}
                value={user.lastSuitabilityReviewAt}
                valueType="date"
              />
              <DetailItem
                showValue={isSuitabilityReviewRecent()}
                title={t('client:details.profileStatusBox.clientAgreementsAreReadyToSignTitle')}
                value={user?.readyToSignAgreement ? t('shared:yes') : t('shared:no')}
                valueType="text"
              />
            </Grid>
          </ClientDetailBox>
        )}

        {/* employment */}
        {isIndividual && permissions.includes('read:client_low_risk_pii') && (
          <ClientDetailBox
            title={t('client:details.employmentBox.header')}
            user={user}
            section="DETAILS"
            buttonText={t('client:edit.buttons.save')}
            modalButton={t('client:form.edit')}
            fields={employmentFields}
            state={(user.employmentStatus || user.companyType || user.jobTitle || user.studentAreaOfStudy) ? 'Completed' : 'Empty'}
            meta={{
              canUpdate: permissions.includes('write:client_low_risk_pii'),
              showAlert: user.employmentStatus === 'UNEMPLOYED' ? false : employmentSectionHasIncompleteFields(),
              emptyMeta: {
                emptyStateType: 'Required',
                requiredMeta: {
                  header: t('client:details.employmentBox.requiredMeta.header'),
                  buttonText: t('client:details.employmentBox.requiredMeta.buttonText'),
                },
              },
            }}
            onUpdate={(data) => fetchAndUpdateEmployementFields(data, employmentFields)}
          >
            <Grid container spacing={2}>
              <DetailItem
                title={t('client:details.employmentBox.employmentStatusTitle')}
                checker={hasEmployeeStatus}
                value={t(`client:edit.employmentStatusOptions.${user.employmentStatus}`)}
                errorMessage={t('client:details.errorMessages.notSelected')}
              />
              <DetailItem
                title={t('client:details.employmentBox.employmentSituationTitle')}
                checker={() => true}
                value={user.employmentSituation ? t(`client:edit.employmentSituationOptions.${user.employmentSituation}`) : `${user.employmentStatus === 'UNEMPLOYED' ? t('client:edit.employmentSituationOptions.UNEMPLOYED_BUT_LOOKING') : ''}`}
                errorMessage={t('client:details.errorMessages.notSelected')}
              />
              <DetailItem
                title={t('client:details.employmentBox.employmentSituationOtherDescriptionTitle')}
                hideElement={user.employmentSituation?.toLowerCase() !== 'other'}
                value={user?.employmentSituationOtherDescription}
              />
              <DetailItem
                title={t('client:details.employmentBox.companyTypeTitle')}
                errorMessage={t('client:details.errorMessages.notSelected')}
                value={user.companyType}
                hideElement={!(user.employmentStatus && ['EMPLOYED', 'SELF_EMPLOYED', 'RETIRED', 'UNEMPLOYED'].includes(user.employmentStatus))}
              />
              <DetailItem
                title={t('client:details.employmentBox.employerNameTitle')}
                value={user.employerName}
                checker={() => true}
                errorMessage={t('client:details.errorMessages.notSelected')}
                hideElement={!(user.employmentStatus && ['EMPLOYED', 'SELF_EMPLOYED', 'RETIRED', 'UNEMPLOYED'].includes(user.employmentStatus))}
              />
              <DetailItem
                title={t('client:details.employmentBox.employerCompanyAddressTitle')}
                value={generateAddressString(user.employerCompanyAddress)}
                checker={() => true}
                hideElement={!(user.employmentStatus && ['EMPLOYED', 'SELF_EMPLOYED', 'RETIRED', 'UNEMPLOYED'].includes(user.employmentStatus))}
              />
              <DetailItem
                title={t('client:details.employmentBox.studentAreaOfStudy')}
                errorMessage={t('client:details.errorMessages.notSelected')}
                value={user.studentAreaOfStudy}
                hideElement={!(user.employmentStatus && ['STUDENT'].includes(user.employmentStatus))}
              />
              <DetailItem
                title={t('client:details.employmentBox.jobTitle')}
                errorMessage={t('client:details.errorMessages.notSelected')}
                value={user.jobTitle}
                hideElement={!(user.employmentStatus && ['EMPLOYED', 'SELF_EMPLOYED', 'RETIRED', 'UNEMPLOYED'].includes(user.employmentStatus))}
              />
            </Grid>
          </ClientDetailBox>
        )}

        {/* (non-individual) Entity Information */}
        {!isIndividual && (
          <ClientDetailBox
            title={t('client:details.entityInformation.header')}
            user={user}
            state={(!user.countryOfRegistration && !user.registrationNumber) ? 'Empty' : 'Completed'}
            section='DETAILS'
            buttonText={t('client:edit.buttons.save')}
            modalButton={t('client:form.edit')}
            fields={entityInfoFields}
            loading={loading}
            onUpdate={(data) => {
              fetchAndUpdateFields(data, entityInfoFields);
            }}
            meta={{
              canUpdate: true,
              showAlert: !user.countryOfRegistration || !user.registrationNumber || (permissions.includes('read:client_low_risk_pii') && !user.companyType),
              emptyMeta: {
                emptyStateType: 'Required',
                requiredMeta: {
                  header: 'Information required',
                  buttonText: 'ADD DATA',
                },
              },
            }}
          >
            <Grid container spacing={2}>
              <DetailItem
                title={t('nonIndividualClients:entityType')}
                value={t(`entityTypes:${user.type}`)}
                errorMessage={t('client:details.errorMessages.notSelected')}
                grid={6}
              />
              {permissions.includes('read:client_low_risk_pii') && (
                <DetailItem
                  title={t('nonIndividualClients:natureOfBusiness')}
                  value={user.companyType}
                  errorMessage={t('client:details.errorMessages.notSelected')}
                  grid={6}
                />
              )}
              <DetailItem
                title={t('client:details.entityInformation.countryOfRegistration')}
                value={user.countryOfRegistration}
                errorMessage={t('client:details.errorMessages.notSelected')}
              />
              <DetailItem
                title={t('client:details.entityInformation.registrationNumber')}
                value={user.registrationNumber}
                errorMessage={t('client:details.errorMessages.notSelected')}
              />
            </Grid>
          </ClientDetailBox>
        )}

        {/* Person and Entities of Interest (individual) */}
        {isIndividual && permissions.includes('read:client_low_risk_pii') && (
          <ClientDetailBox
            title={t('client:details.personAndEntitiesOfInterestBox.header')}
            user={user}
            section="DETAILS"
            buttonText={t('client:edit.buttons.save')}
            modalButton={t('client:form.edit')}
            fields={complianceFields}
            state={isEntityOfInterest ? 'Empty' : complianceState}
            meta={{
              label: isEntityOfInterest ? 'Incomplete' : t(`client:edit.complianceStateOptions.${user.complianceState}`),
              canUpdate: permissions.includes('write:client_low_risk_pii'),
              showAlert: personsOfInterestSectionHasIncompleteFields(),
              emptyMeta: {
                emptyStateType: 'Required',
                requiredMeta: {
                  header: 'Information required',
                  buttonText: 'ADD DATA',
                },
              },
            }}
            loading={loading}
            onUpdate={(data) => {
              fetchAndUpdateEntityOfInterestFields(data, complianceFields);
              complianceReviewRefetch();
            }}
          >
            <Grid container spacing={2}>
              <DetailItem
                title={t('client:details.personAndEntitiesOfInterestBox.officerOfAPublicCompanyTitle')}
                checker={() => (user.isOfficerOfPublicCompany !== undefined)}
                value={user.isOfficerOfPublicCompany ? 'Yes' : 'No'}
                errorMessage={t('client:details.errorMessages.notSelected')}
                hideElement={user.isOfficerOfPublicCompany === false}
              />
              <DetailItem
                title={t('client:details.personAndEntitiesOfInterestBox.ownerOfPublicCompanyTitle')}
                checker={() => (user.isOwnerOfPublicCompany !== undefined)}
                value={user.isOwnerOfPublicCompany ? 'Yes' : 'No'}
                errorMessage={t('client:details.errorMessages.notSelected')}
                hideElement={user.isOwnerOfPublicCompany === false}
              />
              <DetailItem
                title={t('client:details.personAndEntitiesOfInterestBox.publicCompanySymbol')}
                checker={() => !!((user.isOwnerOfPublicCompany || user.isOfficerOfPublicCompany) && user.ssSymbols && user.ssSymbols?.length > 0 && user.ssSymbols[0])}
                value={user.ssSymbols && user.ssSymbols[0]}
                errorMessage={t('client:details.errorMessages.notSelected')}
                hideElement={user.isOwnerOfPublicCompany === false && user.isOfficerOfPublicCompany === false}
              />
              <DetailItem
                title={t('client:details.personAndEntitiesOfInterestBox.memberOfIIROCTitle')}
                checker={() => !!(user.isMemberOfIiroc)}
                value={user.isMemberOfIiroc ? 'Yes' : 'No'}
                errorMessage={t('client:details.errorMessages.notSelected')}
                hideElement={user.isMemberOfIiroc === false}
              />
              <DetailItem
                title={t('client:details.personAndEntitiesOfInterestBox.IIROCCompanySymbolTitle')}
                checker={() => !!(user.isMemberOfIiroc && user.insiderSymbols && user.insiderSymbols?.length > 0)}
                value={user.insiderSymbols && user.insiderSymbols[0]}
                errorMessage={t('client:details.errorMessages.notSelected')}
                hideElement={user.isMemberOfIiroc === false}
              />
              <DetailItem
                title={t('client:details.personAndEntitiesOfInterestBox.politicallyExposedDomesticPersonTitle')}
                checker={() => !!(user.politicallyExposedDomesticPerson)}
                value={user.politicallyExposedDomesticPerson ? 'Yes' : 'No'}
                errorMessage={t('client:details.errorMessages.notSelected')}
                hideElement={user.politicallyExposedDomesticPerson === false}
              />
              <DetailItem
                title={t('client:details.personAndEntitiesOfInterestBox.politicallyExposedForeignPersonTitle')}
                checker={() => !!(user.politicallyExposedForeignPerson)}
                value={user.politicallyExposedForeignPerson ? 'Yes' : 'No'}
                errorMessage={t('client:details.errorMessages.notSelected')}
                hideElement={user.politicallyExposedForeignPerson === false}
              />
              <DetailItem
                title={t('client:details.personAndEntitiesOfInterestBox.closeAssociateOfPEPTitle')}
                checker={() => !!(user.closeAssociateOfPEP)}
                value={user.closeAssociateOfPEP ? 'Yes' : 'No'}
                errorMessage={t('client:details.errorMessages.notSelected')}
                hideElement={user.closeAssociateOfPEP === false}
              />
              <DetailItem
                title={t('client:details.personAndEntitiesOfInterestBox.headOfInternationalOrganizationTitle')}
                checker={() => !!(user.headOfInternationalOrganization)}
                value={user.headOfInternationalOrganization ? 'Yes' : 'No'}
                errorMessage={t('client:details.errorMessages.notSelected')}
                hideElement={user.headOfInternationalOrganization === false}
              />
              <DetailItem
                title={t('client:details.personAndEntitiesOfInterestBox.powerOfAttorneyGrantedTitle')}
                checker={() => !!(user.powerOfAttorneyGranted)}
                value={user.powerOfAttorneyGranted ? 'Yes' : 'No'}
                errorMessage={t('client:details.errorMessages.notSelected')}
                hideElement={user.powerOfAttorneyGranted === false}
              />
              <DetailItem
                title={t('client:details.personAndEntitiesOfInterestBox.isVulnerablePerson')}
                checker={() => !!(user.isVulnerablePerson)}
                value={user.isVulnerablePerson ? 'Yes' : 'No'}
                errorMessage={t('client:details.errorMessages.notSelected')}
                hideElement={user.isVulnerablePerson === false || user.isVulnerablePerson === null}
              />
              <DetailItem
                title={t('client:details.personAndEntitiesOfInterestBox.complianceStateTitle')}
                checker={() => !!(user.complianceState)}
                value={t(`client:edit.complianceStateOptions.${user.complianceState}`)}
                errorMessage={t('client:details.errorMessages.notSelected')}
              />
              <DetailItem
                title={t('client:details.personAndEntitiesOfInterestBox.complianceIssueSourceTitle')}
                checker={() => !!(user.complianceState === 'APPROVED' || user.complianceIssueSource?.trim())}
                value={user.complianceIssueSource}
                errorMessage={t('client:details.errorMessages.notSelected')}
                hideElement={user.complianceState === 'APPROVED' && !user.complianceIssueSource}
              />
            </Grid>
          </ClientDetailBox>
        )}

        {/* Entity of Interest (non-individual) */}
        {!isIndividual && (
          <ClientDetailBox
            title={t('client:details.entityOfInterestBox.header')}
            user={user}
            section="DETAILS"
            buttonText={t('client:edit.buttons.save')}
            modalButton={t('client:form.edit')}
            fields={nonIndividualComplianceFields}
            state={isEntityOfInterest ? 'Empty' : complianceState}
            meta={{
              label: isEntityOfInterest ? 'Incomplete' : t(`client:edit.complianceStateOptions.${user.complianceState}`),
              canUpdate: permissions.includes('write:client_low_risk_pii'),
              showAlert: entityOfInterestSectionHasIncompleteFields(),
              emptyMeta: {
                emptyStateType: 'Required',
                requiredMeta: {
                  header: 'Information required',
                  buttonText: 'ADD DATA',
                },
              },
            }}
            loading={loading}
            onUpdate={(data) => {
              fetchAndUpdateEntityOfInterestFields(data, nonIndividualComplianceFields);
              complianceReviewRefetch();
            }}
          >
            <Grid container spacing={2}>
              <DetailItem
                title={t('client:details.personAndEntitiesOfInterestBox.ownerOfPublicCompanyTitle')}
                value={t('shared:yes')}
                checker={() => !!(user.isOwnerOfPublicCompany)}
                hideElement={user.isOwnerOfPublicCompany === false}
                errorMessage={t('client:details.errorMessages.notSelected')}
                grid={6}
              />
              <DetailItem
                title={t('client:details.entityOfInterestBox.isForThirdPartyTitle')}
                value={t('shared:yes')}
                checker={() => !!(user.isForThirdParty)}
                hideElement={user.isForThirdParty === false}
                errorMessage={t('client:details.errorMessages.notSelected')}
                grid={6}
              />
              <DetailItem
                title={t('client:details.personAndEntitiesOfInterestBox.publicCompanySymbol')}
                value={user.ssSymbols ? user.ssSymbols[0] : ''}
                checker={() => !!(user.ssSymbols && user.ssSymbols[0])}
                hideElement={user.isOwnerOfPublicCompany === false}
                errorMessage={t('client:details.errorMessages.notSelected')}
                grid={6}
              />
              {(user.isOwnerOfPublicCompany !== false || user.isForThirdParty !== false)
                && <HorizontalDivider />
              }
              <DetailItem
                title={t('client:details.entityOfInterestBox.isEntityRegulatedTitle')}
                value={t('shared:yes')}
                checker={() => !!(user.isEntityRegulated)}
                hideElement={user.isEntityRegulated === false}
                errorMessage={t('client:details.errorMessages.notSelected')}
                grid={3}
              />
              <DetailItem
                title={t('client:details.entityOfInterestBox.regulatorNameTitle')}
                value={user.regulatorName}
                checker={() => user.isEntityRegulated === false || !!user.regulatorName}
                hideElement={user.isEntityRegulated !== true}
                errorMessage={t('client:details.errorMessages.notSelected')}
                grid={3}
              />
              <DetailItem
                title={t('client:details.entityOfInterestBox.regulatorRegistrationNumber')}
                value={user.firmNumber}
                checker={() => user.isEntityRegulated === false || !!user.firmNumber}
                hideElement={user.isEntityRegulated !== true}
                errorMessage={t('client:details.errorMessages.notSelected')}
                grid={6}
              />
              <DetailItem
                title={t('client:details.entityOfInterestBox.isReportingIssuerTitle')}
                value={t('shared:yes')}
                checker={() => !!(user.isReportingIssuer)}
                hideElement={user.isReportingIssuer === false}
                errorMessage={t('client:details.errorMessages.notSelected')}
                grid={12}
              />
              <DetailItem
                title={t('client:details.entityOfInterestBox.issuerNameTitle')}
                value={user.issuerName}
                checker={() => !!(user.issuerName)}
                hideElement={user.isReportingIssuer !== true}
                errorMessage={t('client:details.errorMessages.notSelected')}
                grid={4}
              />
              <DetailItem
                title={t('client:details.entityOfInterestBox.issuerExchangeTitle')}
                value={user.issuerExchange}
                checker={() => !!(user.issuerExchange)}
                hideElement={user.isReportingIssuer !== true}
                errorMessage={t('client:details.errorMessages.notSelected')}
                grid={4}
              />
              <DetailItem
                title={t('client:details.entityOfInterestBox.issuerTickerTitle')}
                value={user.issuerTicker}
                checker={() => !!(user.issuerTicker)}
                hideElement={user.isReportingIssuer !== true}
                errorMessage={t('client:details.errorMessages.notSelected')}
                grid={4}
              />
              <DetailItem
                title={t('client:details.entityOfInterestBox.isNonProfitTitle')}
                value={t('shared:yes')}
                checker={() => (user.isNonProfit !== undefined)}
                hideElement={user.isNonProfit !== true}
                errorMessage={t('client:details.errorMessages.notSelected')}
                grid={12}
              />
              <DetailItem
                title={t('client:details.entityOfInterestBox.isRegisteredWithCRATitle')}
                value={t('shared:yes')}
                hideElement={!user.isNonProfit || user.isRegisteredWithCRA !== true}
                checker={() => !user.isNonProfit || !!user.isRegisteredWithCRA}
                errorMessage={t('client:details.errorMessages.notSelected')}
                grid={6}
              />
              <DetailItem
                title={t('client:details.entityOfInterestBox.receivesDonationsFromPublic')}
                value={t('shared:yes')}
                checker={() => !user.isNonProfit || !!user.receivesDonationsFromPublic}
                hideElement={!user.isNonProfit || user.receivesDonationsFromPublic !== true}
                errorMessage={t('client:details.errorMessages.notSelected')}
                grid={6}
              />
              <DetailItem
                title={t('client:details.entityOfInterestBox.charityRegistrationNumber')}
                value={user.charityRegistrationNumber}
                checker={() => !user.isNonProfit || !user.isRegisteredWithCRA || !!user.charityRegistrationNumber}
                hideElement={!(user.isNonProfit && user.isRegisteredWithCRA)}
                errorMessage={t('client:details.errorMessages.notSelected')}
                grid={12}
              />
              {user.isNonProfit
                && <HorizontalDivider />
              }

              <DetailItem
                title={t('client:details.entityOfInterestBox.isAFinancialEntityTitle')}
                value={t('shared:yes')}
                checker={() => !!(user.isAFinancialEntity)}
                hideElement={user.isAFinancialEntity === false}
                errorMessage={t('client:details.errorMessages.notSelected')}
                grid={6}
              />
              <DetailItem
                title={t('client:details.entityOfInterestBox.isAffiliateOfFinancialEntityTitle')}
                value={t('shared:yes')}
                checker={() => !!(user.isAffiliateOfFinancialEntity)}
                hideElement={user.isAffiliateOfFinancialEntity === false}
                errorMessage={t('client:details.errorMessages.notSelected')}
                grid={6}
              />
              <DetailItem
                title={t('client:details.entityOfInterestBox.instructionsFromFinancialEntityTitle')}
                value={t('shared:yes')}
                checker={() => !!(user.instructionsFromFinancialEntity)}
                hideElement={user.instructionsFromFinancialEntity === false}
                errorMessage={t('client:details.errorMessages.notSelected')}
                grid={12}
              />
              {(user.isAFinancialEntity !== false || user.isAffiliateOfFinancialEntity !== false || user.instructionsFromFinancialEntity !== false)
                && <HorizontalDivider />
              }
              <DetailItem
                title={t('client:details.entityOfInterestBox.isPublicBodyTitle')}
                value={t('shared:yes')}
                checker={() => !!(user.isPublicBody)}
                hideElement={user.isPublicBody === false}
                errorMessage={t('client:details.errorMessages.notSelected')}
                grid={6}
              />
              <DetailItem
                title={t('client:details.entityOfInterestBox.isLargeCorporation')}
                value={t('shared:yes')}
                checker={() => !!(user.isLargeCorporation)}
                hideElement={user.isLargeCorporation === false}
                errorMessage={t('client:details.errorMessages.notSelected')}
                grid={6}
              />
              {(user.isPublicBody !== false || user.isLargeCorporation !== false)
                && <HorizontalDivider />
              }
              {permissions.includes('read:client_low_risk_pii') && (
                <>
                  <DetailItem
                    title={t('client:details.personAndEntitiesOfInterestBox.complianceStateTitle')}
                    checker={() => !!(user.complianceState)}
                    value={t(`client:edit.complianceStateOptions.${user.complianceState}`)}
                    errorMessage={t('client:details.errorMessages.notSelected')}
                  />
                  <DetailItem
                    title={t('client:details.personAndEntitiesOfInterestBox.complianceIssueSourceTitle')}
                    checker={() => !!(user.complianceState === 'APPROVED' || user.complianceIssueSource?.trim())}
                    value={user.complianceIssueSource}
                    errorMessage={t('client:details.errorMessages.notSelected')}
                    hideElement={user.complianceState === 'APPROVED' && !user.complianceIssueSource}
                  />
                </>
              )}
            </Grid>
          </ClientDetailBox>
        )}

        {/* risk level */}
        {permissions.includes('read:fraud_risk_basic') && (<ClientDetailBox
          title={t('client:details.riskLevelBox.header', { riskLevel: t(`client:details.riskLevelBox.riskLevel.${user?.fraudRisk?.score}`) })}
          user={user.fraudRisk}
          section="DETAILS"
          modalButton={t('client:form.edit')}
          fields={riskLevelFields}
          buttonText={t('client:edit.buttons.save')}
          state={user?.fraudRisk?.score ? 'Completed' : 'Empty'}
          meta={{
            canUpdate: permissions.includes('write:client_low_risk_pii'),
            emptyMeta: {
              emptyStateType: 'Not Available',
              notAvailableMeta: {
                header: t('client:details.riskLevelBox.notAvailableMeta.header'),
                body: t('client:details.riskLevelBox.notAvailableMeta.body'),
              },
            },
          }}
          onUpdate={(e) => {
            updateFraudRisk({
              variables: {
                input: {
                  fraudRiskId: user?.fraudRisk?.id,
                  score: Number(e.score),
                  notes: e.notes ?? undefined,
                },
              },
            }).then();
          }}
          loading={loading}
        >
          <Grid container spacing={2}>
            <DetailItem
              title={t('client:details.riskLevelBox.reason')}
              checker={() => true}
              grid={12}
              value={user.fraudRisk?.riskReasons !== null && (
                user.fraudRisk?.riskReasons?.map((reason, index) => <span key={index}>{reason.translatedDescription?.en}<br /></span>)
              )
              }
              hideElement={user.fraudRisk?.riskReasons?.length === 0}
            />
            <DetailItem
              title={t('client:details.riskLevelBox.previousChangeQuestion')}
              grid={12}
              checker={() => (true)}
              value={(isNullOrUndefined([user.fraudRisk?.lastUpdatedBy]) || user.fraudRisk?.lastUpdatedBy) === 'System' ? 'No' : 'Yes'}
              errorMessage={t('client:details.errorMessages.notSelected')}
              hideElement={false}
            />
            <DetailItem
              title={t('client:details.riskLevelBox.notes')}
              valueType='mark-down'
              grid={12}
              checker={() => (true)}
              value={user.fraudRisk?.notes}
              errorMessage={t('client:details.errorMessages.notSelected')}
              hideElement={user.fraudRisk?.notes === null}
            />
          </Grid>
        </ClientDetailBox>)}

        {/* ID Verification */}
        {permissions.includes('read:client_high_risk_pii') && (
          <ClientDetailBox
            isIdVerification
            title={t('client:details.idVerificationBox.header')}
            modalTitle={t('client:details.idVerificationBox.addIDVerification')}
            user={user}
            modalTitlePrefix={t('client:details.idVerificationBox.modalBoxPredix')}
            section="DETAILS"
            buttonText={t('client:edit.buttons.save')}
            modalButton={t('client:form.add')}
            icon={(<AddIcon data-testid="add-icon" />)}
            state={user.iDVerified ? 'Completed' : 'Empty'}
            meta={{
              label: user.iDVerified ? t('client:details.idVerificationBox.idVerifiedLabel') : t('client:details.idVerificationBox.idNotVerifiedLabel'),
              canUpdate: permissions.includes('write:client_high_risk_pii'),
              emptyMeta: {
                emptyStateType: 'Required',
                requiredMeta: {
                  header: t('client:details.idVerificationBox.requiredMeta.header'),
                  buttonText: t('client:details.idVerificationBox.requiredMeta.buttonText'),
                },
              },
              actionItem: (<FormGroup sx={{
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '10px',
              }}>
                <FormControlLabel
                  control={<Switch
                    disabled={!user.iDCheckRetrigger}
                    checked={!user.iDCheckRetrigger}
                    onChange={async (event: any) => {
                      setOpenconfirmationModal(true);
                      setCurrentSwitchStage(!event.target.checked);
                    }}
                  />}
                  label=''
                />
              </FormGroup>),
            }}
            onUpdate={(e) => {
              createUserIDVerificationInformation({
                variables: {
                  input: {
                    ...e,
                    verifiedDate: e.verifiedDate ?? dayjs().format('YYYY-MM-DD'),
                    userId: user.id,
                  },
                },
              }).then();
            }}
          >
            <IdVerificationsTable
              userId={user.id}
              onClick={async (idV: any) => {
                setIdVerification(idV);
                await fetchFleDocuments({
                  variables: {
                    input: {
                      filter: {
                        sourceIds: [idV?.id],
                        types: ['ID_VERIFICATION'],
                      },
                    },
                  },
                });
                setOpenUpdateModal(true);
              }} />
          </ClientDetailBox>
        )}
      </Grid>

      <Grid item xs={12} lg={6}>
        {/* Client Fees */}
        {permissions.includes('read:client_billing') && (
          <ClientDetailBox
            title={t('client:details.clientFeesBox.header')}
            user={user}
            section="DETAILS"
            modalButton={t('client:form.edit')}
            buttonText={t('client:edit.buttons.save')}
            fields={feeFields}
            state={user?.applicableFeeTier?.name !== undefined && user?.applicableBillingSchedule?.frequency !== undefined ? 'Completed' : 'Empty'}
            meta={{
              label: user?.applicableFeeTier?.name !== undefined && user?.applicableBillingSchedule?.frequency !== undefined ? '' : '',
              showAlert: user.applicableBillingSchedule === undefined || (user.applicableBillingSchedule && user.applicableBillingSchedule.frequency === undefined),
              canUpdate: permissions.includes('write:client_billing'),
              emptyMeta: {
                emptyStateType: 'Required',
                requiredMeta: {
                  header: t('client:details.clientFeesBox.requiredMeta.header'),
                  buttonText: t('client:details.clientFeesBox.requiredMeta.buttonText'),
                },
              },
            }}
            onUpdate={(e) => {
              if (e.newBillingScheduleId || e.newFeeTierId) {
                updateUser({
                  variables: {
                    input: {
                      userId: user.id,
                      billingScheduleId: e.newBillingScheduleId === 'inherited' ? null : e.newBillingScheduleId,
                      feeTierId: e.newFeeTierId === 'inherited' ? null : e.newFeeTierId,
                    },
                  },
                });
              }
            }}
          >
            <Grid container spacing={2}>
              <DetailItem
                title={t('client:details.clientFeesBox.feeGridTitle')}
                value={user?.applicableFeeTier?.name}
                subtitle={(user.feeTier === null && user.applicableFeeTier) ? t('client:details.clientFeesBox.organizationDefault') : undefined}
              />
              <DetailItem
                title={t('client:details.clientFeesBox.billingCycleTitle')}
                checker={() => !!(user.applicableBillingSchedule && user.applicableBillingSchedule.frequency)}
                value={t(`feeAndBilling:billingSchedule.frequencies.${user?.applicableBillingSchedule?.frequency}`)}
                subtitle={(user.billingSchedule === null && user.applicableBillingSchedule) ? t('client:details.clientFeesBox.organizationDefault') : undefined}
                errorMessage={t('client:details.errorMessages.notSelected')}
              />

            </Grid>
          </ClientDetailBox>
        )}

        {/* Suitability Score */}
        {permissions.includes('read:client_suitability') && (
          <ClientDetailBox
            title={user.suitabilityScore === null
              ? t('client:details.suitabilityBox.suitabilityScoreNotAvailableLabel')
              : t('client:details.suitabilityBox.suitabilityScoreLabel', { suitabilityScore: user.suitabilityScore })}
            user={user}
            section="DETAILS"
            modalButton={t('client:form.edit')}
            buttonText={t('client:edit.buttons.save')}
            fields={suitabilityFields}
            disableWhenVerifying
            state={suitabilityScoreSectionHasIncompleteFields ? 'Empty' : 'Completed'}
            meta={{
              showAlert: suitabilityScoreSectionHasIncompleteFields,
              canUpdate: permissions.includes('write:client_suitability'),
              emptyMeta: {
                emptyStateType: 'Required',
                requiredMeta: {
                  header: t('client:details.suitabilityBox.requiredMeta.header'),
                  buttonText: t('client:details.suitabilityBox.requiredMeta.buttonText'),
                },
              },
            }}
            onUpdate={(data) => fetchAndUpdateFields(data, suitabilityFields)}
          >
            <Grid container spacing={2}>
              {isIndividual && (
                <>
                  {user.annualIncomeStr && <Grid item xs={4}>
                    <Typography style={headerStyle}>{t('client:details.suitabilityBox.annualIncomeRangeTitle')}</Typography>
                    <Typography style={infoStyle}>{user.annualIncomeStr}</Typography>
                  </Grid>}
                  <Grid item xs={4}>
                    <Typography style={headerStyle}>{t('client:details.suitabilityBox.annualIncomeTitle')}</Typography>
                    <Typography style={infoStyle}>{formatMoneyValue(user.annualIncomeCents)}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={headerStyle}>{t('client:details.suitabilityBox.totalLiquidAssetsTitle')}</Typography>
                    <Typography style={infoStyle}>{formatMoneyValue(user.financialLiquidAssetsCents)}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={headerStyle}>{t('client:details.suitabilityBox.totalFixedAssetsTitle')}</Typography>
                    <Typography style={infoStyle}>{formatMoneyValue(user.financialFixedAssetsCents)}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={headerStyle}>{t('client:details.suitabilityBox.totalDebtTitle')}</Typography>
                    <Typography style={infoStyle}>{formatMoneyValue(user.totalDebtCents)}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={headerStyle}>{t('client:details.suitabilityBox.annualDebtPaymentsTitle')}</Typography>
                    <Typography style={infoStyle}>{formatMoneyValue(user.annualDebtPaymentsCents)}</Typography>
                  </Grid>
                </>
              )}
              {(user.sourceOfFunds && user.sourceOfFunds.length !== 0) && (
                <Grid item xs={6}>
                  <Typography style={headerStyle} sx={{ mb: '5px' }}>{t('client:details.suitabilityBox.sourceOfFundsTitle')}</Typography>
                  <Grid container spacing={1}>
                    {user?.sourceOfFunds?.map((value, index) => (
                      <Grid item key={index}>
                        <Chip label={t(`client:edit.sourceOfFundsOptions.${value}`)} style={infoStyle} sx={chipStyle} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
              <DetailItem
                title={t('client:details.suitabilityBox.sourceOfFundsOtherDescriptionTitle')}
                hideElement={!user.sourceOfFundsOtherDescription}
                value={user.sourceOfFundsOtherDescription}
                grid={12}
              />
              {isIndividual && (
                <>
                  {(user.sourceOfWealth && user.sourceOfWealth.length !== 0) && (
                    <Grid item xs={6}>
                      <Typography style={headerStyle} sx={{ mb: '5px' }}>{t('client:details.suitabilityBox.sourceOfWealthTitle')}</Typography>
                      <Grid container spacing={1}>
                        {user?.sourceOfWealth?.map((value, index) => (
                          <Grid item key={index}>
                            <Chip label={t(`client:edit.sourceOfWealthOptions.${value}`)} style={infoStyle} sx={chipStyle} />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}

                  <DetailItem
                    title={t('client:details.suitabilityBox.sourceOfWealthOtherDescriptionTitle')}
                    checker={() => true}
                    hideElement={!user.sourceOfWealthOtherDescription}
                    value={user.sourceOfWealthOtherDescription}
                    grid={12}
                  />
                </>
              )}
              <DetailItem
                title={t('client:details.suitabilityBox.investmentKnowledgeTitle')}
                value={t(`client:edit.investmentKnowledgeOptions.${user.investmentKnowledge}`)}
                hideElement={!user.investmentKnowledge}
              />
              {isIndividual && (
                <DetailItem
                  title={t('client:details.suitabilityBox.isAccreditedInvestorTitle')}
                  value={t(user.isAccreditedInvestor ? t('shared:yes') : t('shared:no'))}
                  hideElement={user.isAccreditedInvestor === null}
                />
              )}
              <DetailItem
                title={t('client:details.suitabilityBox.riskQuestion1Title')}
                checker={() => !!(user.riskQuestion1)}
                value={t(`client:edit.riskQuestion1Options.${user.riskQuestion1}`)}
                grid={6}
                errorMessage={t('client:details.errorMessages.notSelected')}
              />
              <DetailItem
                title={t('client:details.suitabilityBox.riskQuestion2Title')}
                checker={() => !!(user.riskQuestion2)}
                value={t(`client:edit.riskQuestion2Options.${user.riskQuestion2}`)}
                grid={6}
                errorMessage={t('client:details.errorMessages.notSelected')}
              />
              <DetailItem
                title={t('client:details.suitabilityBox.uniqueCircumstancesTitle')}
                valueType='mark-down'
                checker={() => true}
                value={(user.uniqueCircumstances && user.uniqueCircumstances.length > 0) ? user.uniqueCircumstances : t('client:details.suitabilityBox.none')}
                grid={12}
              />
            </Grid>
          </ClientDetailBox>
        )}

        {/* Marital, Citizenship and Tax Status */}
        {(permissions.includes('read:client_low_risk_pii') || permissions.includes('read:client_high_risk_pii')) && (
          <ClientDetailBox
            title={isIndividual
              ? t('client:details.citizenshipAndTaxStatusInformationBox.individualHeader')
              : t('client:details.citizenshipAndTaxStatusInformationBox.nonIndividualHeader')
            }
            user={{ ...user, taxIdType: user.taxIdType ?? taxIdTypesShown?.[0] }}
            section="DETAILS"
            modalButton={t('client:form.edit')}
            buttonText={t('client:edit.buttons.save')}
            fields={citizenshipAndTaxStatusFields}
            state={((user?.citizenships ?? []).length > 0 || user.maritalStatus || user.taxNumber || user.yearEnd || user.countryOfTaxResidence) ? 'Completed' : 'Empty'}
            meta={{
              label: '',
              canUpdate: permissions.includes('write:client_low_risk_pii') || permissions.includes('write:client_high_risk_pii'),
              showAlert: isIndividual ? citizenshipSectionHasIncompleteFields() : taxSectionHasIncompleteFields(),
              emptyMeta: {
                emptyStateType: 'Required',
                requiredMeta: {
                  header: 'Information required',
                  buttonText: 'ADD DATA',
                },
              },
            }}
            onUpdate={(data) => fetchAndUpdateFields(data, citizenshipAndTaxStatusFields)}
          >
            <Grid container spacing={2}>
              {permissions.includes('read:client_low_risk_pii') && (
                <>
                  {isIndividual && (
                    <>
                      <Grid item xs={6}>
                        <Typography style={headerStyle}>{t('client:details.citizenshipAndTaxStatusInformationBox.maritalStatusTitle')}</Typography>
                        <Typography style={infoStyle}>{user.maritalStatus ? t(`client:edit.maritalStatusOptions.${user.maritalStatus}`) : ''}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography style={headerStyle}>{t('client:details.citizenshipAndTaxStatusInformationBox.numberOfDependents')}</Typography>
                        <Typography style={infoStyle}>{user?.numberOfDependents ?? 0}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography style={headerStyle} sx={{ mb: '5px' }}>{t('client:details.citizenshipAndTaxStatusInformationBox.citizenshipsTitle')}</Typography>
                        <Grid container spacing={1}>
                          {user?.citizenships?.map((code, index) => (
                            <Grid key={index} item mb={1}>
                              <Chip style={infoStyle} sx={chipStyle} label={countryList.find((country) => country.code === code)?.name ?? code} />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {!isIndividual && (
                    <>
                      <Grid item xs={6}>
                        <DetailItem
                          showValue
                          title={t('client:details.citizenshipAndTaxStatusInformationBox.taxNumber')}
                          value={user.taxNumber}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DetailItem
                          showValue
                          title={t('client:details.citizenshipAndTaxStatusInformationBox.yearEnd')}
                          value={user.yearEnd}
                          valueType="date"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography style={headerStyle}>{t('client:edit.fatcaStatus')}</Typography>
                        <Typography style={infoStyle}>{user.fatcaStatus ? t(`client:edit.fatcaStatusOptions.${user.fatcaStatus}`) : ''}</Typography>
                      </Grid>
                      {(user.fatcaStatus === FATCAStatus.OTHER) && (<Grid item xs={6}>
                        <Typography style={headerStyle}>{t('client:edit.fatcaStatusOtherDescription')}</Typography>
                        <Typography style={infoStyle}>{user.fatcaStatusOtherDescription}</Typography>
                      </Grid>)}
                    </>
                  )}

                  <Grid item xs={6}>
                    <Typography style={headerStyle} sx={{ mb: '5px' }}>{t('client:details.citizenshipAndTaxStatusInformationBox.countryOfTaxResidenceTitle')}</Typography>
                    {user.countryOfTaxResidence && (
                      <Chip style={infoStyle} sx={chipStyle} label={countryList.find((country) => country.code === user.countryOfTaxResidence)?.name ?? user.countryOfTaxResidence} />
                    )}
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <DetailItem
                  title={user.taxIdType ? t(`taxId:${user.taxIdType}`) : anticipatedTaxIdType(user.countryOfTaxResidence)}
                  checker={() => !!user.taxIdExists}
                  value={user.taxIdExists ? encryptedTaxIdPlaceholder(user.taxIdType) : ''}
                  errorMessage={t('client:details.errorMessages.notSelected')}
                />
              </Grid>
              {permissions.includes('read:client_high_risk_pii') && (
                <>
                  {
                    (user?.foreignTaxInformation?.length ?? 0) > 0 && (
                      <Grid item xs={12}>
                        <Typography style={headerStyle} mb={1}>{t('client:details.citizenshipAndTaxStatusInformationBox.foreignTaxInformationTitle')}</Typography>
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow sx={{ backgroundColor: colors.gray200, borderRadius: '25px 25px 0px 0px' }}>
                                <TableCell style={{ fontSize: '14px', fontWeight: '600' }}>{t('client:details.citizenshipAndTaxStatusInformationBox.countryTitle')}</TableCell>
                                <TableCell style={{ fontSize: '14px', fontWeight: '600' }}>{t('client:details.citizenshipAndTaxStatusInformationBox.taxIdentificationNumberTitle')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {user?.foreignTaxInformation?.map((foreignTax, index) => (
                                <TableRow
                                  key={index}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell style={{ fontSize: '14px', fontWeight: '500' }} component="th" scope="row">
                                    {foreignTax.foreignTaxCountry}
                                  </TableCell>
                                  <TableCell style={{ fontSize: '14px', fontWeight: '500' }}>{foreignTax.foreignTaxNumber}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )
                  }
                </>
              )}
            </Grid>
          </ClientDetailBox>
        )}
        {/* Trusted Contact Person */}
        {isIndividual && permissions.includes('read:client_low_risk_pii') && (
          <ClientDetailBox
            title={t('client:details.trustedContact.title')}
            user={user}
            section="trustedContactPerson"
            buttonText={t('client:edit.buttons.save')}
            fields={trustedContactFields}
            modalButton={t('client:form.edit')}
            state={hasTrustedContactPerson() ? 'Completed' : 'Empty'}
            meta={{
              label: '',
              canUpdate: permissions.includes('write:client_low_risk_pii'),
              emptyMeta: {
                emptyStateType: 'Not Available',
                notAvailableMeta: {
                  header: t('client:details.trustedContact.notAvailableMeta.header'),
                  body: t('client:details.trustedContact.notAvailableMeta.body'),
                },
              },
            }}
            onUpdate={(data) => fetchUpdateTrustedContactPerson(data, trustedContactFields)}
          >
            <Grid container spacing={2}>
              {permissions.includes('read:client_low_risk_pii') && (
                <>
                  <Grid item xs={4}>
                    <Typography style={headerStyle}>{t('client:details.trustedContact.name')}</Typography>
                    <Typography style={infoStyle}>{user?.trustedContactPerson?.name}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={headerStyle}>{t('client:details.trustedContact.relation')}</Typography>
                    <Typography style={infoStyle}>{`${t(`client:details.trustedContact.relationOptions.${user?.trustedContactPerson?.relation}`)}`}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={headerStyle}>{t('client:details.trustedContact.email')}</Typography>
                    <Typography style={infoStyle}>{user?.trustedContactPerson?.email}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={headerStyle}>{t('client:details.trustedContact.phone')}</Typography>
                    <Typography style={infoStyle}>{user?.trustedContactPerson?.phone}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography style={headerStyle}>{t('client:details.trustedContact.address')}</Typography>
                    <Typography style={infoStyle}>{generateAddressString(user?.trustedContactPerson?.physicalAddress)}</Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </ClientDetailBox>
        )}
        {/* spouse information */}
        {permissions.includes('read:client_low_risk_pii') && ['COMMON_LAW', 'MARRIED'].includes(user.maritalStatus ?? '') && (
          <ClientDetailBox
            title={t('client:details.spouseInformationBox.header')}
            user={user}
            section="DETAILS"
            buttonText={t('client:edit.buttons.save')}
            modalButton={t('client:form.edit')}
            fields={spouseInformationFields}
            state={(user.spouseFirstName || user.spouseLastName) ? 'Completed' : 'Empty'}
            meta={{
              canUpdate: permissions.includes('write:client_low_risk_pii'),
              showAlert: spouseSectionHasIncompleteFields(),
              emptyMeta: {
                emptyStateType: 'Required',
                requiredMeta: {
                  header: t('client:details.spouseInformationBox.requiredMeta.header'),
                  buttonText: t('client:details.spouseInformationBox.requiredMeta.buttonText'),
                },
              },
            }}
            onUpdate={(data) => fetchAndUpdateFields(data, spouseInformationFields)}
          >
            <Grid container spacing={2} mb={2}>
              <DetailItem
                title={t('client:details.spouseInformationBox.spouseFirstName')}
                grid={4}
                checker={() => true}
                value={user.spouseFirstName}
              />
              <DetailItem
                title={t('client:details.spouseInformationBox.spouseMiddleName')}
                grid={4}
                checker={() => true}
                value={user.spouseMiddleName}
              />
              <DetailItem
                title={t('client:details.spouseInformationBox.spouseLastName')}
                grid={4}
                checker={() => true}
                value={user.spouseLastName}
              />
            </Grid>
            <Grid container spacing={2} mb={2}>
              <DetailItem
                title={t('client:details.spouseInformationBox.spouseEmployerName')}
                grid={6}
                checker={() => true}
                value={user.spouseEmployerName}
              />
              <DetailItem
                title={t('client:details.spouseInformationBox.spouseJobTitle')}
                grid={6}
                checker={() => true}
                value={user.spouseJobTitle}
              />
              <DetailItem
                title={t('client:details.spouseInformationBox.spouseEmploymentType')}
                grid={6}
                checker={() => true}
                value={user.spouseEmploymentType}
              />
              <DetailItem
                title={t('client:details.spouseInformationBox.spouseCompanyType')}
                grid={6}
                checker={() => true}
                value={user.spouseCompanyType}
              />
            </Grid>
            <Grid container spacing={2} mb={2}>
              <DetailItem
                title={t('client:details.spouseInformationBox.spouseEmail')}
                grid={4}
                checker={() => true}
                hideElement={!user.spouseEmail}
                value={user.spouseEmail}
              />
              <DetailItem
                title={t('client:details.spouseInformationBox.spouseAddress')}
                grid={8}
                checker={() => true}
                hideElement={!user.spouseAddress}
                value={generateAddressString(user.spouseAddress)}
              />
            </Grid>
          </ClientDetailBox>
        )}
      </Grid>
      {
        openUpdateModal ? (
          <EditIdVerification
            idVerification={idVerification}
            setIdVerification={setIdVerification}
            onSubmit={() => {
              updateUserID();
              setOpenUpdateModal(false);
            }}
            onClose={() => setOpenUpdateModal(false)}
            documentArray={documentArray}
          />
        ) : undefined
      }
      {
        (<ConfirmationModal
          title={t('client:confirmationDialog.title')}
          bodyText={t('client:confirmationDialog.subtitle')}
          open={openConfirmationModal}
          maxWidth='sm'
          confirmButton={t('client:confirmationDialog.continueButton')}
          cancelButton={t('client:confirmationDialog.cancelButton')}
          onConfirm={async () => {
            await updateIdCheckRetrigger(user, currentSwitchStage);
          }}
          onCancel={() => {
            setOpenconfirmationModal(false);
          }}
        />)
      }
    </Grid >
  );
};

export default ClientDetails;
