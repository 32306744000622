import { translateBackend } from 'assets/i18n/config';
import { Box, Checkbox } from '../..';
import InfoDialog from '../../2-component/infoDialog/infoDialog';

export const CheckboxEdit = ({
  option, options, setOptions, i,
}: {
  option: any, options: any, setOptions: (x: any) => void, i: number
}) => (
  <Box sx={{ mb: 2 }} display='flex'>
      <Checkbox
        label={translateBackend(option.label)}
        checked={option.value}
        onChange={(checked: boolean) => {
          const newOptions = [...options];
          newOptions[i].value = checked;
          setOptions(newOptions);
        }}
      />
    {option.info && (
      <InfoDialog information={translateBackend(option.info)} center />
    )}
  </Box>
);
