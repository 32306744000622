import {
  Autocomplete, Box, FormControlLabel, Grid, Switch, TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { CountryCodes } from '@onevesthq/ov-enums';
import { countryList } from '../../ovComponents/resources';

type ForeignTaxInformation = {
  foreignTaxCountry: string,
  foreignTaxNumber: string
};

const TaxInformationField = ({
  onChange,
  readonly = false,
  taxInformation,
  countryOfTaxResidence,
}: {
  onChange: ((e: ForeignTaxInformation[]) => void),
  readonly?: boolean,
  taxInformation: ForeignTaxInformation[],
  countryOfTaxResidence: CountryCodes
}) => {
  const { t } = useTranslation('components');
  const [foreignTax, setForeignTax] = useState(taxInformation);
  const hasForeignTax: boolean = taxInformation && taxInformation.length > 0;

  const addNewForeignTax = (): void => {
    setForeignTax((prevState) => ([...prevState, { foreignTaxCountry: '', foreignTaxNumber: '' }]));
  };
  const resetForeignTax = (): void => {
    setForeignTax([]);
  };

  useEffect(() => {
    onChange(foreignTax.filter((item) => item.foreignTaxCountry));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foreignTax]);

  const removeFromForeignTax = (index: number) => {
    setForeignTax([...foreignTax.filter((_element, idx) => idx !== index)]);
  };

  const onCountryChanged = (index: number, newCountryName: string) => {
    const newCountryCode = countryList.find((country) => country.name === newCountryName)?.code ?? '';
    const updatedForeignTaxInfo = { ...foreignTax[index], foreignTaxCountry: newCountryCode };
    setForeignTax((prevState) => prevState.map((fti, i) => (i === index ? updatedForeignTaxInfo : fti)));
  };

  const onTINChanged = (index: number, newTIN: string) => {
    const updatedForeignTaxInfo = { ...foreignTax[index], foreignTaxNumber: newTIN };
    setForeignTax((prevState) => prevState.map((fti, i) => (i === index ? updatedForeignTaxInfo : fti)));
  };

  const contryListExceptResidenceCountry = useMemo(() => countryList.filter((e) => e.code !== countryOfTaxResidence), [countryOfTaxResidence]);

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        <FormControlLabel
          control={<Switch
            disabled={readonly}
            checked={hasForeignTax}
            onChange={(e) => {
              if (e.target.checked) {
                if (foreignTax.length === 0) addNewForeignTax();
              } else {
                resetForeignTax();
              }
            }}
          />}
          label={t('components:taxInformationField.label')}
        />
      </Box>
      {
        foreignTax.map((item, index) => (
          <Grid key={index} container spacing={2} mt={1}>
            <Grid key={`country-${index}`} item md={readonly ? 6 : 5} xs={12}>
              <Autocomplete
                disablePortal
                value={!item.foreignTaxCountry ? null : { label: countryList.find((country) => country.code === foreignTax[index].foreignTaxCountry)?.name ?? '' }}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                data-testid="autocomplete"
                readOnly={readonly}
                onChange={(e: any, value) => {
                  onCountryChanged(index, value?.label ?? '');
                }}
                options={contryListExceptResidenceCountry.map((country) => ({ label: country.name }))}
                renderInput={(params) => <TextField {...params} data-testid={`autocomplete-${index}`} label={t('components:taxInformationField.country')} />}
              />
            </Grid>
            <Grid key={`tin-${index}`} item md={readonly ? 6 : 5} xs={readonly ? 12 : 8}>
              <TextField
                fullWidth
                data-testid={`tin-${index}`}
                value={foreignTax[index].foreignTaxNumber}
                label={t('components:taxInformationField.tin')}
                onChange={(e) => {
                  onTINChanged(index, e.target.value);
                }}
                InputProps={{
                  readOnly: readonly,
                }}
              />
            </Grid>
            {readonly ? undefined
              : <>
                <Grid key={`delete-icon-${index}`} item md={1} xs={2} display={'flex'} alignItems="center">
                  <DeleteRoundedIcon data-testid={`remove-button-${index}`} sx={{ cursor: 'pointer' }} onClick={() => removeFromForeignTax(index)} />
                </Grid>
                <Grid key={`add-icon-${index}`} item md={1} xs={2} display={'flex'} alignItems="center">
                  {index === (foreignTax.length - 1) ? <AddRoundedIcon data-testid="add-button" sx={{ cursor: 'pointer' }} onClick={() => addNewForeignTax()} /> : undefined}
                </Grid>
              </>
            }
          </Grid>
        ))
      }
    </>
  );
};

export default TaxInformationField;
