import { gql } from '@apollo/client';

export const FETCH_ACCOUNT = gql`
  query fetchAccount($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        id
        type
        custodianAccountNumber
        state
        user {
          firstName
          lastName
        }
        affiliations {
          type
          user {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const STATISTICS_CONTRIBUTIONS = `
  statistics {
    depositContributionCents(startDate: $startDate, endDate: $endDate, types: $types, useFiscalDate: $useFiscalDate)
    withdrawContributionCents(startDate: $startDate, endDate: $endDate, types: $types, useFiscalDate: $useFiscalDate)
  }
`;

const CUSTODIAN_STATISTICS_CONTRIBUTIONS = `
  custodianStatistics(startDate: $startDate, endDate: $endDate) {
    depositContributionCents
    withdrawContributionCents
    minWithdrawalCents
    maxWithdrawalCents
  }
`;

export const FETCH_ACCOUNT_CUSTOM_FIELDS = gql`
  query fetchAccount($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        id
        custodianCustomFields {key, value}
      }
    }
  }
`;

export const FETCH_ACCOUNT_CONTRIBUTIONS = (useCustodianData: boolean | undefined = undefined) => gql`
  query fetchAccount($accountId: ObjectID!, $startDate: Date, $endDate: Date, ${useCustodianData ? '' : '$types: [TransactionTypes], $useFiscalDate: Boolean'}) {
    fetchAccount(accountId: $accountId) {
      account {
        id
        ${useCustodianData ? CUSTODIAN_STATISTICS_CONTRIBUTIONS : STATISTICS_CONTRIBUTIONS}
      }
    }
  }
`;
