import {
  Box, Card, CircularProgress,
} from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useState, createContext, useContext,
} from 'react';
import { generateClientInitials, generateClientNameString } from '../../util';
import ConfirmationModal from '../../components/modals/confirmationModal';
import AuditLog from '../../components/tables/auditLog';
import Notes from '../../components/notes';
import { NoteObjectTypes } from '../../interfaces/note';
import CorporationStatements from '../corporation/components/CorporationStatements';
import HouseholdDocumentsTab from './components/householdDocumentsTab';
import { FETCH_HOUSEHOLDS } from '../households/components/householdsTable';
import { UserContext, usePermissions } from '../../providers/userContextProvider';
import {
  Account, AccountStates, ClientGroup, NavigationStyles, Organization, Relationship,
} from '../../interfaces';
import { HouseholdSummary } from '../../components/layout/client/householdSummary';
import { usePageState } from '../../util/usePageState';
import { TabPanel, Tab, Tabs } from '../../components/tabs/ovTabs';
import { HouseholdOverview } from './components/HouseholdOverview';
import EditHousehold from './components/editHousehold';
import { HouseholdMembers } from './components/HouseholdMembers';
import { HouseholdTransfers } from './components/HouseholdTransfers';
import { HouseholdTrades } from './components/HouseholdTrades';
import { HouseholdTransactions } from './components/HouseholdTransactions';
import { ViewPage } from '../../ovComponents';
import filterHouseholdsWithAccess from '../../util/filterHouseholdsWithAccess';

export const FETCH_HOUSEHOLD = (permissions: string[]) => gql`
  query fetchHousehold($id: ObjectID!) {
    fetchClientGroup(clientGroupId: $id) {
      clientGroup {
        id
        name
        primaryUser {
          id
          ${permissions.includes('read:client_low_risk_pii') ? 'firstName middleName lastName primaryEmail' : ''}
          ${permissions.includes('read:client_high_risk_pii') ? 'phone' : ''}
          physicalAddress { city province streetName postal unitNumber houseNumber neighborhood country }
          ${permissions.includes('read:client_low_risk_pii') ? 'entityName' : ''}
        }
        relationships {
          type
          user {
            id
            state
            ${permissions.includes('read:client_low_risk_pii') ? 'firstName middleName lastName primaryEmail' : ''}
            ${permissions.includes('read:client_high_risk_pii') ? 'phone' : ''}
            incompleteFields
            iDVerified
            ${permissions.includes('read:client_low_risk_pii') ? 'complianceState' : ''}
            ${permissions.includes('read:client_suitability') ? 'lastSuitabilityReviewAt' : ''}
            ${permissions.includes('read:client_suitability') ? 'financialLiquidAssetsCents financialFixedAssetsCents totalDebtCents' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'entityName' : ''}
            type
          }
          accessType
        }
        allIncompleteFormAgreements { id }
        feeTier { id name }
        ${permissions.includes('read:fee_tier') ? 'applicableFeeTier { id name }' : ''}
        billingSchedule { id frequency }
        ${permissions.includes('read:billing_schedule') ? 'applicableBillingSchedule { id frequency }' : ''}
        organization {
          id
          name
          allowPortfolioPerGoal
          availableFeatureFlags
          applicableLocalization {
            countries
          }
        }
        statistics {
          marketValueCents
        }
        accounts {
          id type state
          ${permissions.includes('read:account_number') ? 'custodianAccountNumber' : ''}
          user {
            id
            ${permissions.includes('read:client_low_risk_pii') ? 'firstName middleName lastName' : ''}
          }
          feePaymentAccount {
            id type
            ${permissions.includes('read:account_number') ? 'custodianAccountNumber' : ''}
          }
        }
      }
    }
  }
`;

const TRANSITION_CLIENT_GROUP = gql`
  mutation($input:TransitionClientGroupInput!) {
    transitionClientGroup(input:$input) {
      clientGroup {
        id
      }
    }
  }
`;

const activeAccountsOnly = (acc:Account) => acc.state !== AccountStates.INACTIVE && acc.state !== AccountStates.CANCELED;

interface HouseholdContextType {
  orgSettings: Organization
  members: { id:string, initials: string, name: string }[],
  indexedMembers: Record<string, number>
  totalMarketValueCents: number
}

export const HouseholdContext = createContext<HouseholdContextType | undefined>(undefined);
export const useHouseholdContext = () => useContext(HouseholdContext);

const HouseHold = () => {
  const { t } = useTranslation('household');
  const navigate = useNavigate();
  const { permissions } = usePermissions();
  const [tab, setTab] = usePageState('overview', 'tab');
  const params = useParams();
  const [clientGroup, setClientGroup] = useState<ClientGroup>();
  const [showMemberOnly, setShowMemberOnly] = usePageState<string | undefined>(undefined, 'memberOnly');
  const {
    userContext, activeEntity,
  } = useContext(UserContext);

  const { loading, refetch } = useQuery(FETCH_HOUSEHOLD(permissions), {
    skip: permissions.length === 0,
    fetchPolicy: 'cache-and-network',
    variables: { id: params.clientGroupId },
    onCompleted: (data: any) => setClientGroup({
      ...data.fetchClientGroup.clientGroup,
      accounts: data.fetchClientGroup.clientGroup.accounts.filter(activeAccountsOnly),
    }),
  });
  const [editorOpen, setEditorOpen] = useState(false);
  const [archiveConfirmationModalOpen, setArchiveConfirmationModalOpen] = useState(false);
  const [transitionClientGroup] = useMutation(TRANSITION_CLIENT_GROUP);

  if (loading) {
    return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress sx={{ marginTop: 50 }} />
    </Box>
    );
  }

  if (!clientGroup) return <></>;
  if (activeEntity?.id && userContext?.role?.navigationStyle === NavigationStyles.SIMPLE) {
    const households = filterHouseholdsWithAccess([clientGroup], activeEntity.id);
    if (households.length === 0 && activeEntity?.id) navigate('/');
  }

  return (
  <HouseholdContext.Provider value={{
    orgSettings: clientGroup.organization as Organization,
    members: clientGroup.relationships.map((rel:Relationship) => ({ id: rel.user.id, initials: generateClientInitials(rel.user), name: generateClientNameString(rel.user) })),
    indexedMembers: Object.fromEntries(clientGroup.relationships.map((rel:Relationship, index:number) => [rel.user.id, index])),
    totalMarketValueCents: clientGroup.statistics?.marketValueCents ?? 0,
  }}>
    { userContext.role?.householdPageConfiguration ? (
      <ViewPage pageConfiguration={userContext.role.householdPageConfiguration} />
    ) : (
      <>
        <HouseholdSummary
          clientGroup={clientGroup}
          onHighlightClicked={() => setEditorOpen(true)}
          updateProfile={() => setEditorOpen(true)}
          archiveHousehold={() => setArchiveConfirmationModalOpen(true)}
          showMemberOnly={showMemberOnly}
          setShowMemberOnly={setShowMemberOnly}
        />
        <Tabs value={tab} onChange={(event, newValue) => { setTab(newValue); }}
          variant="scrollable" scrollButtons="auto"
          sx={{
            borderBottom: 1, borderColor: 'divider', marginTop: 2, marginBottom: 2,
          }}
        >
          <Tab label={t('tabs.overview')} value="overview"/>
          <Tab label={t('tabs.members')} value="members"/>
          { permissions.includes('read:files')
            && <Tab label={t('tabs.documents')} value="documents"/>
          }
          {permissions.includes('read:account_statement_basic')
          && <Tab label={t('tabs.statements')} value="statements"/>
          }
          {permissions.includes('read:transfer_basic')
            && <Tab label={t('tabs.transfers')} value="transfers"/>
          }
          {permissions.includes('read:trade_basic')
            && <Tab label={t('tabs.trades')} value="trades"/>
          }
          { permissions.includes('read:transaction')
            && <Tab label={t('tabs.ledger')} value="ledger"/>
          }
          {permissions.includes('read:notes')
          && <Tab label={t('tabs.notes')} value="notes"/>
          }
          {permissions.includes('read:audit_logs')
            && <Tab label={t('tabs.auditLog')} value="auditlog" />
          }
        </Tabs>
        <TabPanel value={tab} index="overview">
          <HouseholdOverview clientGroup={clientGroup} onlyMemberId={showMemberOnly}/>
        </TabPanel>
        <TabPanel value={tab} index="members">
          <HouseholdMembers clientGroup={clientGroup}/>
        </TabPanel>
        <TabPanel value={tab} index="documents">
          <Card>
            <HouseholdDocumentsTab clientGroup={clientGroup}/>
          </Card>
        </TabPanel>
        <TabPanel value={tab} index="statements">
          <Card>
            <CorporationStatements clientGroup={clientGroup}/>
          </Card>
        </TabPanel>
        <TabPanel value={tab} index="transfers">
          <Card>
            <HouseholdTransfers clientGroup={clientGroup}/>
          </Card>
        </TabPanel>
        <TabPanel value={tab} index="trades">
          <Card>
            <HouseholdTrades clientGroup={clientGroup}/>
          </Card>
        </TabPanel>
        <TabPanel value={tab} index="ledger">
          <Card>
            <HouseholdTransactions clientGroup={clientGroup}/>
          </Card>
        </TabPanel>
        <TabPanel value={tab} index="notes">
          <Card>
            <Notes objectId={clientGroup.id} objectType={NoteObjectTypes.CLIENT_GROUP} />
          </Card>
        </TabPanel>
        <TabPanel value={tab} index="auditlog">
          <Card>
            <AuditLog objectId={clientGroup.id} objectType="CLIENT_GROUP" />
          </Card>
        </TabPanel>

        {editorOpen
          && <EditHousehold
            houseHold={clientGroup}
            afterEdit={() => {
              setEditorOpen(false);
              refetch();
            }}
          />
        }

        {archiveConfirmationModalOpen && (
          <ConfirmationModal
            open={true}
            title={t('archiveHouseholdConfirmation.areYouSure')}
            bodyText={t('archiveHouseholdConfirmation.thereAreMembers', { numMembers: clientGroup.relationships.length })}
            onConfirm={ () => {
              transitionClientGroup({
                variables: { input: { clientGroupId: clientGroup.id, transition: 'archive' } },
                refetchQueries: [FETCH_HOUSEHOLDS],
                onCompleted: () => {
                  setArchiveConfirmationModalOpen(false);
                  navigate('/households');
                },
              });
            }}
            onCancel={() => setArchiveConfirmationModalOpen(false)}
            confirmButton={t('archiveHouseholdConfirmation.yesSure')}
            cancelButton={t('shared:cancel')}
          />
        )}
      </>
    )}
  </HouseholdContext.Provider>
  );
};

export default HouseHold;
