import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import dayjs from 'dayjs';
import { gql, useMutation } from '@apollo/client';
import { useAuthContext } from 'providers/ovApolloProvider';
import { auth0RefreshTokenSilently } from 'providers/auth0Client';
import { Account } from 'interfaces';
import { AffiliateType } from './affiliate';
import { Box, Grid, Typography } from '../../../../../1-primative';
import {
  AddressField,
  Button,
  Checkbox,
  DateField,
  Dialog, DialogContent, DialogFooter, DialogTitle, Form, IconButton, MenuItem, SelectField, TextField,
} from '../../../../../2-component';
import { SelectionTile } from '../../../../../3-pattern';
import { RelationType } from '../accountConfig';
import { delay } from '../../../../../../util';
import { UserContext } from '../../../../../../providers/userContextProvider';

const RELATION_TYPES = [
  'CHILD',
  'COMMON_LAW',
  'GRANDCHILD',
  'GRANDPARENT',
  'GUARDIAN',
  'NEPHEW_NIECE',
  'OTHER',
  'PARENT',
  'PRIMARY_CAREGIVER',
  'SIBLING',
  'SPOUSE',
];

const CREATE_USER = gql`
  mutation createAffiliate($input: CreateAffiliateInput!) {
    createAffiliate(input: $input) {
      user {
        id
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const UPDATE_AFFILIATIONS = gql`
  mutation updateAffiliations($input: UpdateAffiliationsInput!) {
    updateAffiliations(input: $input) {
      account {
        id
      }
    }
  }
`;

export const AffiliateModal = ({
  open, setOpen, affiliate, action = 'create', fields = [], type, accountId, affiliates, refetch, allAffiliates, account, useAccountHoldersAddress, title,
}: {
  open: boolean, setOpen: (open: boolean) => void, affiliate?: any, action?: string, fields?: any[], type: AffiliateType,
  accountId: string, affiliates: any[], refetch?: any, allAffiliates: any[], account?: Partial<Account>, useAccountHoldersAddress?: boolean,
  title?: string,
}) => {
  const { t } = useTranslation(['affiliationTypes', 'client']);
  const { setAuthToken } = useAuthContext();
  const { userContext } = useContext(UserContext);
  const [showEncryptedSin, setShowEncryptedSin] = useState(false);
  const [affiliateData, setAffiliateData] = useState<any>(affiliate);
  const [focused, setFocused] = useState<string[]>([]);

  const [createUser, { loading: createLoading }] = useMutation(CREATE_USER);
  const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_USER);
  const [updateAffiliations, { loading }] = useMutation(UPDATE_AFFILIATIONS);

  useEffect(() => {
    if (affiliate?.user && Object.keys(affiliate?.user).length !== 0) setAffiliateData(affiliate);
  }, [affiliate]);

  useEffect(() => {
    setShowEncryptedSin(affiliate?.user?.sinExists);
  }, [affiliate]);

  const refreshAuthToken = async (): Promise<void> => {
    await delay(2000);
    const newToken = await auth0RefreshTokenSilently({
      orgSubdomain: userContext.organization?.subdomain,
    });
    if (newToken) setAuthToken(newToken);
  };

  const submit = () => {
    if (action === 'create') {
      const userData = { ...affiliateData.user };
      if (!userData?.dateOfBirth) {
        delete userData?.dateOfBirth;
      }
      if (!userData?.dateOfDeath) {
        delete userData?.dateOfDeath;
      }
      createUser({
        variables: {
          input: {
            ...userData,
          },
        },
        onCompleted: (res) => {
          updateAffiliations({
            variables: {
              input: {
                accountId,
                affiliations: [
                  ...(allAffiliates || []).map((item: any) => ({
                    allocation: item.type === type ? Math.floor(100 / ((affiliates.filter((x: any) => x.type === item.type).length || 0) + 1)) : item.allocation,
                    relation: item.relation,
                    type: item.type,
                    userId: item.user.id,
                  })),
                  {
                    userId: res.createAffiliate.user.id,
                    relation: affiliateData.relation || RelationType.OTHER,
                    type,
                    allocation: fieldTypes.includes('allocation') ? Math.floor(100 / ((affiliates.filter((x: any) => x.type === type).length || 0) + 1)) : 0,
                  },
                ],
              },
            },
            onCompleted: async () => {
              refetch && refetch();
              setOpen(false);
              await refreshAuthToken();
            },
          });
        },
      });
    } else {
      const userData = { ...affiliateData.user };
      delete userData?.id;
      delete userData?.sinExists;
      if (!userData?.dateOfBirth) {
        delete userData?.dateOfBirth;
      }
      if (!userData?.dateOfDeath) {
        delete userData?.dateOfDeath;
      }
      delete userData?.__typename;
      delete userData?.physicalAddress?.__typename;

      updateUser({
        variables: {
          input: {
            userId: affiliateData.user.id,
            ...userData,
          },
        },
        onCompleted: () => {
          const newAff = allAffiliates.filter((item: any) => item.id !== affiliateData.id);

          updateAffiliations({
            variables: {
              input: {
                accountId,
                affiliations: [
                  ...newAff.map((item: any) => ({
                    allocation: item.allocation,
                    relation: item.relation,
                    type: item.type,
                    userId: item.user.id,
                  })),
                  {
                    userId: affiliateData.user.id,
                    relation: affiliateData.relation || RelationType.OTHER,
                    type,
                    allocation: affiliateData.allocation,
                  },
                ],
              },
            },
            onCompleted: () => {
              refetch && refetch();
              setOpen(false);
            },
          });
        },
      });
    }
  };

  const fieldTypes = fields.map((field: any) => (field.type));

  const validate = () => {
    const invalidFields: string[] = [];
    if (fieldTypes.includes('fullName')) {
      if (!affiliateData.user.firstName) {
        invalidFields.push('firstName');
      }
      if (!affiliateData.user.lastName) {
        invalidFields.push('lastName');
      }
    }
    if (fieldTypes.includes('physicalAddress')) {
      if (!affiliateData.user.physicalAddress?.streetName) {
        invalidFields.push('physicalAddress');
      }
    }
    if (fieldTypes.includes('dateOfBirth')) {
      if (!affiliateData.user.dateOfBirth) {
        invalidFields.push('dateOfBirth');
      }
    }
    if (fieldTypes.includes('dateOfDeath')) {
      if (!affiliateData.user.dateOfDeath) {
        invalidFields.push('dateOfDeath');
      }
    }
    if (fieldTypes.includes('gender')) {
      if (!affiliateData.user.gender) {
        invalidFields.push('gender');
      }
    }
    if (fieldTypes.includes('sin') && !showEncryptedSin) {
      if (!affiliateData.user.sin || (affiliateData.user.sin && affiliateData.user.sin.length !== 9)) {
        invalidFields.push('sin');
      }
    }
    if (fieldTypes.includes('primaryEmail')) {
      if (!affiliateData.user.primaryEmail) {
        invalidFields.push('primaryEmail');
      }
    }
    if (fieldTypes.includes('employmentStatus')) {
      if (!affiliateData.user.employmentStatus) {
        invalidFields.push('employmentStatus');
      }
      if (['RETIRED', 'EMPLOYED', 'SELF_EMPLOYED'].includes(affiliateData.user.employmentStatus) && !affiliateData.user.companyType) {
        invalidFields.push('companyType');
      }
      if (['RETIRED', 'EMPLOYED', 'SELF_EMPLOYED'].includes(affiliateData.user.employmentStatus) && !affiliateData.user.jobTitle) {
        invalidFields.push('jobTitle');
      }
      if (affiliateData.user.employmentStatus === 'STUDENT' && !affiliateData.user.studentAreaOfStudy) {
        invalidFields.push('studentAreaOfStudy');
      }
    }
    if (fieldTypes.includes('relation')) {
      if (!affiliateData.relation) {
        invalidFields.push('relation');
      }
    }
    setFocused(invalidFields);
    return invalidFields.length === 0;
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='headingLarge'>{title ?? t(`button.${type}`)}</Typography>
          <IconButton onClick={() => setOpen(false)}><Close /></IconButton>
        </Box>
      </DialogTitle>
      <Form onSubmit={() => {
        if (validate()) {
          submit();
        }
      }}>
        <DialogContent>
          {fieldTypes.includes('fullName') && (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  data-testid="affiliate-first-name"
                  label={t('client:details.firstName')}
                  value={affiliateData.user.firstName || ''}
                  onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, firstName: e.target.value } })}
                  onBlur={() => setFocused([...focused, 'firstName'])}
                  error={!affiliateData.user.firstName && focused.includes('firstName')}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  data-testid="affiliate-middle-name"
                  label={t('client:details.middleName')}
                  value={affiliateData.user.middleName || ''}
                  onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, middleName: e.target.value } })}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  data-testid="affiliate-last-name"
                  label={t('client:details.lastName')}
                  value={affiliateData.user.lastName || ''}
                  onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, lastName: e.target.value } })}
                  onBlur={() => setFocused([...focused, 'lastName'])}
                  error={!affiliateData.user.lastName && focused.includes('lastName')}
                />
              </Grid>
            </Grid>
          )}
          {fieldTypes.includes('physicalAddress') && (
            <AddressField
              data-testid="affiliate-physical-address"
              sx={{ mt: 2 }}
              address={affiliateData.user.physicalAddress || {}}
              onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, physicalAddress: e } })}
              label={t('client:details.physicalAddress')}
              onBlur={() => setFocused([...focused, 'physicalAddress'])}
              error={!affiliateData.user.physicalAddress?.streetName && focused.includes('physicalAddress')}
              manualAddressEntry={true}
            />
          )}
          {useAccountHoldersAddress && (
            <Box display='flex' flexDirection='row' sx={{ flexFlow: 'wrap' }} mt={1}>
              <Checkbox
                data-testid="assign-account-holder-address"
                chip
                label={t('affiliationTypes:assignAccountHoldersAddress')}
                checked={false}
                onChange={(checked: boolean) => {
                  if (checked) {
                    const accountHolderAddress = account?.user?.physicalAddress;
                    delete accountHolderAddress.__typename;
                    setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, physicalAddress: accountHolderAddress } });
                  } else {
                    setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, physicalAddress: '' } });
                  }
                }}
              />
            </Box>
          )}
          {fieldTypes.includes('dateOfBirth') && (
            <DateField
              data-testid="affiliate-date-of-birth"
              sx={{ mt: 2 }}
              onChange={(date: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, dateOfBirth: dayjs(date?.toString()).format('YYYY-MM-DD') } })}
              label={t('client:details.dateOfBirth')}
              fullWidth
              value={affiliateData.user.dateOfBirth || ''}
              onBlur={() => setFocused([...focused, 'dateOfBirth'])}
              error={!affiliateData.user.dateOfBirth && focused.includes('dateOfBirth')}
            />
          )}
          {fieldTypes.includes('dateOfDeath') && (
            <DateField
              data-testid="affiliate-date-of-death"
              sx={{ mt: 2 }}
              onChange={(date: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, dateOfDeath: dayjs(date?.toString()).format('YYYY-MM-DD') } })}
              label={t('client:details.dateOfDeath')}
              fullWidth
              value={affiliateData.user.dateOfDeath || ''}
              onBlur={() => setFocused([...focused, 'dateOfDeath'])}
              error={!affiliateData.user.dateOfDeath && focused.includes('dateOfDeath')}
            />
          )}
          {fieldTypes.includes('sin') && (
            <TextField
              data-testid="affiliate-sin"
              sx={{ mt: 2 }}
              type={showEncryptedSin ? undefined : '3-3-3'}
              label={t('client:details.sin')}
              value={showEncryptedSin ? '*** *** ***' : affiliateData.user.sin}
              onChange={(e: any) => {
                if (showEncryptedSin) {
                  setShowEncryptedSin(false);
                } else {
                  setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, sin: e.target.value } });
                }
              }}
              onBlur={() => setFocused([...focused, 'sin'])}
              error={(!affiliateData.user.sin || (affiliateData.user.sin && affiliateData.user.sin.length !== 9)) && focused.includes('sin')}
            />
          )}
          {fieldTypes.includes('primaryEmail') && (
            <TextField
              data-testid="affiliate-primary-email"
              fullWidth
              sx={{ mt: 2 }}
              label={t('client:details.primaryEmail')}
              value={affiliateData.user.primaryEmail || ''}
              onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, primaryEmail: e.target.value } })}
              onBlur={() => setFocused([...focused, 'primaryEmail'])}
              error={!affiliateData.user.primaryEmail && focused.includes('primaryEmail')}
            />
          )}
          {fieldTypes.includes('gender') && (
            <Box mt={2}>
              <Typography variant='labelSmall' colorVariant='variant' sx={{ mb: 1 }}>{t('client:details.gender')}</Typography>
              <SelectionTile
                data-testid="affiliate-gender"
                direction='row'
                value={affiliateData.user.gender || ''}
                onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, gender: e.target.value } })}
                options={[{ value: 'Male', label: t('client:details.Male') }, { value: 'Female', label: t('client:details.Female') }]}
              />
            </Box>
          )}
          {fieldTypes.includes('employmentStatus') && (
            <SelectField
              data-testid="affiliate-employment-status"
              fullWidth
              sx={{ mt: 2 }}
              label={t('client:details.employmentStatus')}
              value={affiliateData.user.employmentStatus || ''}
              onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, employmentStatus: e.target.value } })}
              onBlur={() => setFocused([...focused, 'employmentStatus'])}
              error={!affiliateData.user.employmentStatus && focused.includes('employmentStatus')}
            >
              <MenuItem data-testid="affiliate-employment-status-employed" value='EMPLOYED'>{t('client:edit.employmentStatusOptions.EMPLOYED')}</MenuItem>
              <MenuItem data-testid="affiliate-employment-status-self-employed" value='SELF_EMPLOYED'>{t('client:edit.employmentStatusOptions.SELF_EMPLOYED')}</MenuItem>
              <MenuItem data-testid="affiliate-employment-status-unemployed" value='UNEMPLOYED'>{t('client:edit.employmentStatusOptions.UNEMPLOYED')}</MenuItem>
              <MenuItem data-testid="affiliate-employment-status-student" value='STUDENT'>{t('client:edit.employmentStatusOptions.STUDENT')}</MenuItem>
              <MenuItem data-testid="affiliate-employment-status-retired" value='RETIRED'>{t('client:edit.employmentStatusOptions.RETIRED')}</MenuItem>
            </SelectField>
          )}
          {['RETIRED', 'EMPLOYED', 'SELF_EMPLOYED'].includes(affiliateData.user.employmentStatus) && (
            <TextField
              data-testid="affiliate-company-type"
              onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, companyType: e.target.value } })}
              label={t('client:details.companyType')}
              fullWidth
              sx={{ mt: 2 }}
              value={affiliateData.user.companyType}
              onBlur={() => setFocused([...focused, 'companyType'])}
              error={!affiliateData.user.companyType && focused.includes('companyType')}
            />
          )}
          {['RETIRED', 'EMPLOYED', 'SELF_EMPLOYED'].includes(affiliateData.user.employmentStatus) && (
            <TextField
              data-testid="affiliate-job-title"
              onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, jobTitle: e.target.value } })}
              label={t('client:details.jobTitle')}
              fullWidth
              sx={{ mt: 2 }}
              value={affiliateData.user.jobTitle}
              onBlur={() => setFocused([...focused, 'jobTitle'])}
              error={!affiliateData.user.jobTitle && focused.includes('jobTitle')}
            />
          )}
          {affiliateData.user.employmentStatus === 'STUDENT' && (
            <TextField
              data-testid="affiliate-area-of-study"
              onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, studentAreaOfStudy: e.target.value } })}
              label={t('client:details.studentAreaOfStudy')}
              fullWidth
              sx={{ mt: 2 }}
              value={affiliateData.user.studentAreaOfStudy}
              onBlur={() => setFocused([...focused, 'studentAreaOfStudy'])}
              error={!affiliateData.user.studentAreaOfStudy && focused.includes('studentAreaOfStudy')}
            />
          )}
          {fieldTypes.includes('relation') && (
            <SelectField
              data-testid="affiliate-relation"
              fullWidth
              sx={{ mt: 2 }}
              label={t('client:details.relation')}
              value={affiliateData.relation || ''}
              onChange={(e: any) => setAffiliateData({ ...affiliateData, relation: e.target.value })}
              onBlur={() => setFocused([...focused, 'relation'])}
              error={!affiliateData.relation && focused.includes('relation')}
            >
              {fields.find((field) => field.type === 'relation').options
                ? fields.find((field) => field.type === 'relation').options.map((item: any, idx: number) => (
                  <MenuItem key={idx} value={item.value}>{item.label}</MenuItem>
                ))
                : RELATION_TYPES.map((item: any, idx: number) => (
                  <MenuItem data-testid={`affiliate-relation-${item}`} key={idx} value={item}>{t(`client:details.relationOptions.${item}`)}</MenuItem>
                ))}
            </SelectField>
          )}
        </DialogContent>
        <DialogFooter>
          <Box display='flex' justifyContent='end' p={1}>
            <Button
              data-testid="create-btn"
              label={action === 'create' ? t('client:form.add') : t('client:form.update')}
              type='submit' variant='tonal'
              disabled={createLoading || updateLoading || loading}
            />
          </Box>
        </DialogFooter>
      </Form>
    </Dialog>
  );
};
