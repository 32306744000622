import i18n from '../../../../assets/i18n/config';

export const INDIVIDUAL_FORMS = [
  'CLIENT_ACCOUNT_AGREEMENT',
  'DISCRETIONARY_MANAGEMENT_AGREEMENT',
  'ONEVEST_RELATIONSHIP_DISCLOSURE_BROCHURE',
  'ELECTRONIC_DELIVERY_AGREEMENT',
  'DECLARATION_OF_TAX_DISCLOSURE',
  'WAIVER_OF_CONFIDENTIALITY',
  'W9',
  'W8BEN',
];
export const NON_INDIVIDUAL_FORMS = [
  'ARTICLES_OF_INCORPORATION_OR_EQUIVALENT',
  'DECLARATION_OF_TAX_DISCLOSURE_E',
  'DISCRETIONARY_MANAGEMENT_AGREEMENT',
  'ELECTRONIC_DELIVERY_AGREEMENT',
  'ONEVEST_RELATIONSHIP_DISCLOSURE_BROCHURE',
  'W_8IMY',
  'W_8BEN_E',
  'TRUST',
  'TRUST_AGREEMENT',
  'PUBLICLY_LISTED_ENTITY',
  'PRIVATE_COMPANY',
  'INVESTMENT_FUND',
  'REGULATED_ENTITY',
  'CORPORATE_RESOLUTION',
];
export const ACCOUNT_FORMS = [
  'FEE_REDIRECTION',
  'BENEFICIARY_DESIGNATION_FORM',
  'RESP_SINGLE_PLAN',
  'JOINT_CLIENT_ACCOUNT_AGREEMENT',
  'EDUCATION_SAVINGS_GRANTS_APPLICATION',
  'RESP_FAMILY_PLAN',
  'CORPORATE_ACCOUNT_AGREEMENT',
  'DEATH_CERTIFICATE',
  'LAST_WILL',
  'NON_INDIVIDUAL_ENTITY_ACCOUNT_AGREEMENT',
  'TRUST_AGREEMENT',
  'TRUST_PARTICIPANT_IDENTIFICATION_FORM',
  'EDUCATION_SAVINGS_GRANTS_APPLICATION',
  'RESP_SINGLE_PLAN',
  'LRSP',
  'FHSA',
  'TFSA',
  'RRSP',
  'CASH_JOINT',
  'LRSP_ADDENDUM',
  'RLSP',
  'RLSP_ADDENDUM',
  'LIF',
  'LIF_ADDENDUM',
  'LRIF',
  'LRIF_ADDENDUM',
  'PRIF',
  'PRIF_ADDENDUM',
  'RLIF',
  'RLIF_ADDENDUM',
  'RRIF',
  'RIF_SPOUSAL',
  'RRSP_SPOUSAL',
  'LIRA',
  'CLB_FOR_ADULTS',
];
export const SUB_ACCOUNT_FORMS = ['INVESTMENT_POLICY_STATEMENT'];
export const SCHEDULE_TRANSFER_FORMS = ['SWP_AGREEMENT', 'PAD_AGREEMENT'];
export const CLIENT_GROUP_FORMS = ['ARTICLES_OF_INCORPORATION_OR_EQUIVALENT', 'CORPORATE_RESOLUTION', 'DECLARATION_OF_TAX_DISCLOSURE_E'];
export const GOAL_FORMS = ['GOAL_INVESTMENT_POLICY_STATEMENT'];

export const isUserForm = (formTemplateType: string) => INDIVIDUAL_FORMS.includes(formTemplateType) || NON_INDIVIDUAL_FORMS.includes(formTemplateType);
export const isAccountForm = (formTemplateType: string) => ACCOUNT_FORMS.includes(formTemplateType);
export const isSubAccountForm = (formTemplateType: string) => SUB_ACCOUNT_FORMS.includes(formTemplateType);
export const isGoalForm = (formTemplateType: string) => GOAL_FORMS.includes(formTemplateType);
export const isScheduleTransferForm = (formTemplateType: string) => SCHEDULE_TRANSFER_FORMS.includes(formTemplateType);
export const isClientGroupForm = (formTemplateType: string) => CLIENT_GROUP_FORMS.includes(formTemplateType);

export const getMenuLabel = (formTemplateType: string) => {
  if (isUserForm(formTemplateType)) return i18n.t('orgSettings:documentManagement.previewObject.selectUser');
  if (isAccountForm(formTemplateType)) return i18n.t('orgSettings:documentManagement.previewObject.selectAccount');
  if (isSubAccountForm(formTemplateType)) return i18n.t('orgSettings:documentManagement.previewObject.selectSubAccount');
  if (isGoalForm(formTemplateType)) return i18n.t('orgSettings:documentManagement.previewObject.selectGoal');
  if (isClientGroupForm(formTemplateType)) return i18n.t('orgSettings:documentManagement.previewObject.selectClientGroup');
  if (isScheduleTransferForm(formTemplateType)) return i18n.t('orgSettings:documentManagement.previewObject.selectScheduleTransfer');
  return i18n.t('orgSettings:documentManagement.previewObject.selectUser');
};

export const getTitleLabelType = (formTemplateType: string) => {
  if (isUserForm(formTemplateType)) return i18n.t('orgSettings:documentManagement.objectType.USER');
  if (isAccountForm(formTemplateType)) return i18n.t('orgSettings:documentManagement.objectType.ACCOUNT');
  if (isSubAccountForm(formTemplateType)) return i18n.t('orgSettings:documentManagement.objectType.SUB_ACCOUNT');
  if (isGoalForm(formTemplateType)) return i18n.t('orgSettings:documentManagement.objectType.GOAL');
  if (isClientGroupForm(formTemplateType)) return i18n.t('orgSettings:documentManagement.objectType.CLIENT_GROUP');
  if (isScheduleTransferForm(formTemplateType)) return i18n.t('orgSettings:documentManagement.objectType.SCHEDULE_TRANSFER');
  return i18n.t('orgSettings:documentManagement.objectType.USER');
};
