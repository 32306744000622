import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { Add } from '@mui/icons-material';
import { CustomFieldConditionalRule, CustomFieldConditionalRulesComparisonTypes } from '../../../../interfaces/customField';
import {
  Button,
  IconButton, MenuItem, SelectField,
  TextField,
} from '../../../2-component';
import { Box } from '../../../1-primative';

const BuildCustomFieldRules = ({ customField, setCustomField, disabled = false }: { customField: any; setCustomField: (customField: any) => void; disabled?: boolean }) => {
  const { t } = useTranslation(['workflowCompletions']);
  let rules = customField.conditionalRules && customField.conditionalRules.length > 0
    ? customField.conditionalRules : [{ field: '', comparison: CustomFieldConditionalRulesComparisonTypes.EQUALS, value: '' }];
  const hasPendingLines = !!rules.find((rule: any) => !rule.field || !rule.value);
  return (
    <Box sx={{ mb: 1, width: '100%' }}>
      {rules.map((rule: any, i: number) => (
        <Box gap={2} key={i} display='flex' flexDirection='row' alignItems='end' marginBottom={2}>
          <TextField
            label={t('eventTriggerRule.field')}
            value={rule.field ?? ''}
            sx={{ flex: 1 }}
            fullWidth
            onChange={(e: any) => {
              rules[i].field = e.target.value;
              setCustomField({ ...customField, conditionalRules: rules });
            }}
          />
          <SelectField
            label={t('eventTriggerRule.comparison')}
            value={rule.comparison || ''}
            fullWidth
            sx={{ flex: 1 }}
            onChange={(e: any) => {
              rules[i].comparison = e.target.value;
              setCustomField({ ...customField, conditionalRules: rules });
            }}
          >
            {Object.values(CustomFieldConditionalRulesComparisonTypes).map((comp) => (
              <MenuItem key={comp} value={comp}>
                {comp}
              </MenuItem>
            ))}
          </SelectField>
          <TextField
            label={t('eventTriggerRule.value')}
            value={rule.value ?? ''}
            fullWidth
            sx={{ flex: 1 }}
            onChange={(e: any) => {
              rules[i].value = e.target.value;
              setCustomField({ ...customField, conditionalRules: rules });
            }}
          />
          {rules.length > 0 && !disabled && (
            <IconButton
              data-testid='edit-button'
              onClick={() => {
                rules = rules.filter((f: any, index: number) => index !== i);
                setCustomField({ ...customField, conditionalRules: rules });
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ))}
      <Button
        variant='tonal'
        leadingIcon={Add}
        label={t('eventTriggerRule.addNewRule')}
        disabled={hasPendingLines || disabled}
        onClick={() => {
          const newLine: CustomFieldConditionalRule = { field: '', comparison: CustomFieldConditionalRulesComparisonTypes.EQUALS, value: '' };
          rules = [...rules, newLine];
          setCustomField({ ...customField, conditionalRules: rules });
        }}
      />
    </Box>
  );
};

export default BuildCustomFieldRules;
