/* eslint-disable react-hooks/exhaustive-deps */
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash/fp';
import { translateBackend } from 'assets/i18n/config';
import { additionalInfo } from 'ovComponents/4-module/configurableOptionFields';
import InfoDialog from 'ovComponents/2-component/infoDialog/infoDialog';
import { CountryCodes } from '@onevesthq/ov-enums';
import { gql, useLazyQuery } from '@apollo/client';
import { invalidFields } from '../utils';
import {
  Button, TextField, Typography, Form, ForeignTaxField, CitizenshipField, Box, DateField, HelpText, Grid, SelectField, MenuItem,
  RadioGroup,
  Radio,
} from '../../../..';
import {
  FATCAStatus, TaxIdTypes, User, eligibleTaxIdTypes,
  encryptedTaxIdPlaceholder,
} from '../../../../../interfaces';
import { ForeignTaxInformation } from '../../../../../interfaces/foreignTaxInformation';
import { getCountryName } from '../../../../resources';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';

const VALIDATE_TAX_ID = gql`
  query validateTaxId($taxIdType: TaxIdTypes!, $taxId: String!) {
    validateTaxId(taxIdType: $taxIdType, taxId: $taxId)
  }
`;

export const ResidencyInformationVisual = ({
  options, userData, loading, updateUser, updating, continueFunc, taxIdExists, applicableCountries = [], grid, updateMode,
  workflowCompletion,
}: {
  options: any, userData: Partial<User>, loading: boolean, updateUser: any, updating: boolean, continueFunc: any, taxIdExists?: boolean, applicableCountries?: CountryCodes[],
  workflowCompletion?: any, grid?: boolean, updateMode?: boolean
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { t } = useTranslation(['client', 'workflowCompletions']);
  const [focused, setFocused] = useState<string[]>([]);
  const [showEncryptedTaxId, setShowEncryptedTaxId] = useState<string | undefined>();
  const [invalidFieldsList, setInvalidFieldsList] = useState<string[]>([]);
  const [invalidFieldTaxId, setInvalidFieldTaxId] = useState<boolean>(false);

  const validate = () => {
    const fields = invalidFields({
      ...options,
      foreignTaxInformation: {
        ...options.foreignTaxInformation,
        requiredIf: (data: any) => data?.foreignTaxInformation?.length > 0,
      },
      countryOfTaxResidence: { ...options.countryOfTaxResidence, enabled: countryOfTaxResidenceEnabled },
      sin: { enabled: false },
      taxId: { ...options.taxId, enabled: optionTaxIdEnabled, requiredIf: () => !showEncryptedTaxId },
    }, userData);
    setInvalidFieldsList(fields);
    setFocused(fields);
    return fields.length === 0;
  };
  const [validateTaxId] = useLazyQuery(VALIDATE_TAX_ID);

  const submit = () => {
    if (validate() && !invalidFieldTaxId) {
      sendAnalytic(
        ovAnalyticsEvents.workflowsResidencyInformationContinueButtonSelect,
        {
          workflowStepTitle: options?.title,
          workflowId: workflowCompletion?.workflow?.id,
          workflowName: workflowCompletion?.workflow?.name,
          activeWorkflowCompletionId: workflowCompletion?.id,
          objectId: workflowCompletion?.objectId,
          objectType: workflowCompletion.objectType,
        },
      );
      continueFunc();
    }
  };

  const countryOfTaxResidenceEnabled = options?.countryOfTaxResidence?.enabled || applicableCountries.length > 1;

  /* countryOfTaxResidence logic */
  useEffect(() => {
    if (!userData) return;

    // auto select if there's only one applicable country
    if (!userData.countryOfTaxResidence && applicableCountries.length === 1) {
      updateUser({ ...userData, countryOfTaxResidence: applicableCountries[0] });
    }
  }, [applicableCountries, updateUser, userData]);

  const showForeignTaxInformationError = invalidFieldsList.includes('foreignTaxInformation') && options?.foreignTaxInformation?.required !== 'NOT_REQUIRED';
  const subTitleStyle = {
    display: 'inline-flex',
    alignItems: 'end',
  };

  /* taxId logic */
  const taxIdTypesShown: TaxIdTypes[] = useMemo(() => {
    if (options?.sin?.enabled) return [TaxIdTypes.SIN];
    if (!userData) return [];
    if (!userData.countryOfTaxResidence) return [];

    const isIndividual = userData.type === 'INDIVIDUAL';
    let types = eligibleTaxIdTypes(userData.countryOfTaxResidence, isIndividual);
    if (isIndividual && !options?.taxId?.allowITIN) types = types.filter((type) => type !== TaxIdTypes.ITIN);
    return types;
  }, [userData, options?.sin?.enabled, options?.taxId?.allowITIN]);

  const defaultTaxIdType = taxIdTypesShown[0];

  const optionTaxIdEnabled = options?.sin?.enabled || (options?.taxId?.enabled && taxIdTypesShown.length >= 1);
  const taxIdLabel = options?.sin?.enabled
    ? translateBackend(options.sin.label)
    : `${t(`taxId:${userData.taxIdType}`)} (${t(`taxId:abbreviated.${userData.taxIdType}`)})`;

  useEffect(() => {
    if (taxIdExists !== undefined && !userData.taxId) {
      setShowEncryptedTaxId(taxIdExists ? encryptedTaxIdPlaceholder(userData.taxIdType) : undefined);
    }
  }, [taxIdExists, userData.taxId, userData.taxIdType]);

  useEffect(() => {
    if (!userData) return;
    if (!optionTaxIdEnabled) return;

    // no taxIdType selected yet - pick default
    if (!userData.taxIdType && defaultTaxIdType) {
      updateUser({ ...userData, taxIdType: defaultTaxIdType });
    }
    // stale taxIdType exists that's no longer allowed - reset to default
    if (userData.taxIdType && !taxIdTypesShown.includes(userData.taxIdType)) {
      updateUser({ ...userData, taxIdType: defaultTaxIdType, taxId: undefined });
      setShowEncryptedTaxId(undefined);
    }
  }, [userData, defaultTaxIdType, updateUser, taxIdTypesShown, optionTaxIdEnabled]);

  const taxIdTypeFormatType = (type?: TaxIdTypes): '3-3-3' | '3-2-4' | '2-7' | undefined => {
    if (type === TaxIdTypes.SIN || type === TaxIdTypes.BN) return '3-3-3';
    if (type === TaxIdTypes.SSN || type === TaxIdTypes.ITIN) return '3-2-4';
    if (type === TaxIdTypes.EIN) return '2-7';
    return undefined;
  };

  useEffect(() => {
    if (!optionTaxIdEnabled) return;
    if (focused.includes('taxId') && userData.taxIdType) {
      validateTaxId({
        variables: { taxIdType: userData.taxIdType, taxId: userData.taxId ?? '' },
        onCompleted: (data) => { setInvalidFieldTaxId(!data.validateTaxId); },
      });
    }
  }, [userData, optionTaxIdEnabled, showEncryptedTaxId]);

  const lockMessage = (): string => (options.customLockedMessage ? translateBackend(options.customLockedMessage) : t('pageConfiguration:notEditableMessage'));

  const formContents = <>
    {options?.citizenships?.enabled && (
      <Grid item xs={12} md={grid ? 6 : 12}>
        <CitizenshipField
          onChange={(e: any) => updateUser({ ...userData, citizenships: e })}
          readonly={loading}
          label={translateBackend(options?.citizenships?.label)}
          infoTooltip={additionalInfo(options?.citizenships?.additionalInfo)}
          citizenships={userData.citizenships ?? []}
          error={isEmpty(userData.citizenships) && focused.includes('citizenships') && options?.citizenships?.required !== 'NOT_REQUIRED'}
          testId="citizenships"
        />
      </Grid>
    )}
    {countryOfTaxResidenceEnabled && (
      <Grid item xs={12} md={grid ? 6 : 12}>
        <TextField
          select
          fullWidth
          onChange={(e: any) => updateUser({ ...userData, countryOfTaxResidence: e.target.value })}
          disabled={loading}
          label={translateBackend(options?.countryOfTaxResidence?.label)}
          infoTooltip={additionalInfo(options?.countryOfTaxResidence?.additionalInfo)}
          value={userData.countryOfTaxResidence}
          onBlur={() => setFocused([...focused, 'countryOfTaxResidence'])}
          error={!userData.countryOfTaxResidence && focused.includes('countryOfTaxResidence') && options?.countryOfTaxResidence?.required !== 'NOT_REQUIRED'}
          data-testid="country-of-tax-residence"
        >
          {applicableCountries?.map((a: any) => (
            <MenuItem key={a} value={a}>
              {getCountryName(a)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    )}
    {optionTaxIdEnabled && (
      <Grid item xs={12} md={grid ? 6 : 12}>
        {taxIdTypesShown.length > 1 && (
          <RadioGroup
            label={t('workflowCompletions:residencyInformation.taxIdType')}
            value={userData.taxIdType}
            onChange={(e: any) => updateUser({ ...userData, taxIdType: e.target.value })}
            data-testid="tax-id-type"
          >
            {taxIdTypesShown.map((type) => <>
              <Radio key={type}
                label={type}
                value={type}
                size='small'
                disabled={!userData.countryOfTaxResidence || updating}
              />
            </>)}
          </RadioGroup>
        )}
        <TextField
          onChange={(e: any) => {
            updateUser({ ...userData, taxId: e.target.value });
            if (showEncryptedTaxId) setShowEncryptedTaxId(undefined);
          }}
          disabled={!userData.countryOfTaxResidence || updating}
          label={taxIdLabel}
          infoTooltip={additionalInfo(options?.sin?.enabled ? options.sin.additionalInfo : options?.taxId?.additionalInfo)}
          fullWidth
          type={taxIdTypeFormatType(userData.taxIdType)}
          value={showEncryptedTaxId ? '' : userData.taxId}
          placeholder={showEncryptedTaxId}
          onFocus={() => setFocused([...focused, 'taxId'])}
          error={invalidFieldTaxId}
          data-testid="tax-id"
        />
      </Grid>
    )}
    {options?.taxNumber?.enabled && (
      <Grid item xs={12} md={grid ? 6 : 12}>
        <TextField
          data-testid="tax-number"
          onChange={(e: any) => updateUser({ ...userData, taxNumber: e.target.value })}
          disabled={updating}
          label={translateBackend(options?.taxNumber?.label)}
          infoTooltip={additionalInfo(options?.taxNumber?.additionalInfo)}
          fullWidth
          lockMessage={lockMessage()}
          locked={options?.taxNumber?.required === 'NOT_EDITABLE'}
          value={userData.taxNumber ?? ''}
          onBlur={() => setFocused([...focused, 'taxNumber'])}
          error={!userData.taxNumber && focused.includes('taxNumber') && options?.taxNumber?.required !== 'NOT_REQUIRED'}
        />
      </Grid>
    )}
    {options?.yearEnd?.enabled && (
      <Grid item xs={12} md={grid ? 6 : 12}>
        <DateField
          data-testid="year-end"
          onChange={(date: any) => updateUser({ ...userData, yearEnd: dayjs(date?.toString()).format('YYYY-MM-DD') })}
          disabled={updating}
          label={translateBackend(options?.yearEnd?.label)}
          infoTooltip={additionalInfo(options?.yearEnd?.additionalInfo)}
          lockMessage={lockMessage()}
          locked={options?.yearEnd?.required === 'NOT_EDITABLE'}
          fullWidth
          value={userData.yearEnd}
          onBlur={() => setFocused([...focused, 'yearEnd'])}
          error={!userData.yearEnd && focused.includes('yearEnd') && options?.yearEnd?.required !== 'NOT_REQUIRED'}
        />
      </Grid>
    )}
    {options?.fatcaStatus?.enabled && (
      <Grid item xs={12}>
        <SelectField
          onChange={(e: any) => updateUser({ ...userData, fatcaStatus: e.target.value })}
          disabled={updating}
          label={translateBackend(options?.fatcaStatus?.label)}
          infoTooltip={additionalInfo(options?.fatcaStatus?.additionalInfo)}
          lockMessage={lockMessage()}
          locked={options?.fatcaStatus?.required === 'NOT_EDITABLE'}
          fullWidth
          value={userData.fatcaStatus ?? ''}
          onBlur={() => setFocused([...focused, 'fatcaStatus'])}
          error={
            ((!userData.fatcaStatus && focused.includes('fatcaStatus'))
              || invalidFieldsList.includes('fatcaStatus')) && options?.fatcaStatus?.required !== 'NOT_REQUIRED'}
          data-testid="fatca-status"
        >
          {Object.values(FATCAStatus).map((value) => (
            <MenuItem value={value} data-testid={`fatca-status-${value}`}>{t(`edit.fatcaStatusOptions.${value}`)}</MenuItem>
          ))}
        </SelectField>
      </Grid>
    )}
    {options?.fatcaStatus?.enabled && userData.fatcaStatus === 'OTHER' && (
      <Grid item xs={12} md={grid ? 6 : 12}>
        <TextField
          onChange={(e: any) => updateUser({ ...userData, fatcaStatusOtherDescription: e.target.value })}
          disabled={updating}
          label={t('edit.fatcaStatusOtherDescription')}
          infoTooltip={additionalInfo(options?.fatcaStatus?.additionalInfo)}
          lockMessage={lockMessage()}
          locked={options?.fatcaStatus?.required === 'NOT_EDITABLE'}
          fullWidth
          value={userData.fatcaStatusOtherDescription ?? ''}
          onBlur={() => setFocused([...focused, 'fatcaStatusOtherDescription'])}
          error={!userData.fatcaStatusOtherDescription && focused.includes('fatcaStatusOtherDescription') && options?.fatcaStatus?.required !== 'NOT_REQUIRED'}
          data-testid="fatca-status-other-description"
        />
      </Grid>
    )}
    {options?.foreignTaxInformation?.enabled && (
      <Grid item xs={12} md={grid ? 6 : 12}>
        <ForeignTaxField
          setForeignTax={(newValue: ForeignTaxInformation[]) => updateUser({ ...userData, foreignTaxInformation: newValue })}
          readonly={loading}
          label={translateBackend(options?.foreignTaxInformation?.label)}
          infoTooltip={additionalInfo(options?.foreignTaxInformation?.additionalInfo)}
          customLabels={options?.foreignTaxInformation?.customLabels}
          foreignTax={userData.foreignTaxInformation ?? []}
          error={showForeignTaxInformationError}
          data-testid="foreign-tax-information"
        />
        {showForeignTaxInformationError
          && <Box sx={{ mt: 1 }}>
            <HelpText
              tone='error'
              text={t('workflowCompletions:residencyInformation.foreignTaxInformation.error')}
            />
          </Box>}
      </Grid>
    )}
    <Box display='flex' justifyContent='end' width='100%'>
      <Button data-testid="continue-button"
        label={t(updateMode ? 'update' : 'continue')}
        disabled={loading || (!updating && updateMode) || invalidFieldTaxId}
        sx={{ mt: 3, textAlign: 'center' }} type='submit'
      />
    </Box>
  </>;

  return (
    <Form onSubmit={submit}>
      {options?.title && (
        <Typography variant='displayLarge' sx={{ mt: 1 }}>
          {translateBackend(options?.title)}
          {additionalInfo(options?.title?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.title?.additionalInfo) ?? ''} />)}
        </Typography>
      )}
      {options?.subtitle && (
        <Typography style={subTitleStyle} variant='bodyLarge' component={'div'} sx={{ mb: 3, table: { width: '100%' } }}>
          <Box display='inline-block'>
            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
          </Box>
          {additionalInfo(options?.subtitle?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.subtitle?.additionalInfo) ?? ''} />)}
        </Typography>
      )}
      <Grid container spacing={2}>
        {
          loading || formContents
        }
      </Grid>
    </Form >
  );
};

export default ResidencyInformationVisual;
