import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { ObjectType } from './statsHooks';

const SHARED_SUBACCOUNT_STATS = () => `
  id
  account {
    type
    taxRank
    user {
      firstName
    }
  }
  state
  statistics {
    marketValueCents
    moneyAvailableCents
    expectedMoneyAvailableCents
    pendingContributionCents
    cashOnHoldToTradeCents
    holdings {
      quantity
      totalCents
      expectedTotalCents
      financialProduct {
        id
        ticker
        name
        translatedName {
          en
          fr
        }
        isCash
        taxRank
        currentPriceCents
      }
    }
  }
`;

const GOAL_STATS = gql`
  query fetchGoal($goalId: ObjectID!) {
    fetchGoal(goalId: $goalId) {
      goal {
        statistics {
          marketValueCents
          cashOnHoldToTradeCents
          holdings {
            totalCents
            financialProduct {
              id
              name
              translatedName {
                en
                fr
              }
              ticker
              isCash
              taxRank
              currentPriceCents
            }
          }
        }
        financialProduct {
          id
          children {
            taxRank
            percentage
            financialProduct {
              id
              name
              ticker
              translatedName {
                en
                fr
              }
              isCash
              taxRank
              currentPriceCents
            }
          }
        }
        subAccounts {
          ${SHARED_SUBACCOUNT_STATS()}
        }
      }
    }
  }
`;

const SUB_ACCOUNT_STATS = gql`
  query fetchSubAccount($subAccountId: ObjectID!) {
    fetchSubAccount(subAccountId: $subAccountId) {
      subAccount {
        financialProduct {
          id
          children {
            taxRank
            percentage
            financialProduct {
              id
              name
              ticker
              translatedName {
                en
                fr
              }
              isCash
              taxRank
              currentPriceCents
            }
          }
        }
        ${SHARED_SUBACCOUNT_STATS()}
      }
    }
  }
`;

const queries: any = {
  Goal: GOAL_STATS,
  SubAccount: SUB_ACCOUNT_STATS,
};

const field: any = {
  Goal: 'goal',
  SubAccount: 'subAccount',
};

export const useHoldingStats = ({
  type, id,
}: {
  type: ObjectType, id: string,
}) => {
  const variables: any = {
    Goal: { goalId: id },
    SubAccount: { subAccountId: id },
  };

  const { data, loading, refetch } = useQuery(queries[type], {
    variables: variables[type],
  });

  const refetchAll = () => {
    refetch();
  };

  const [stats, setStats] = useState<any>({});

  useEffect(() => {
    if (data) {
      const firstKey = Object.keys(data)[0];
      const statsObject = data[firstKey][field[type]];
      setStats(statsObject);
    }
  }, [data, type, id]);

  return {
    stats, loading, refetchAll,
  };
};
