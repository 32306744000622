import { gql, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { usePermissions, UserContext } from './userContextProvider';

export const FLAG_STATS = gql`
  query fetchFlags($input: FetchFlagsInput!) {
    fetchFlags(input: $input) {
      totalCount
    }
  }
`;

export const SUB_ACCOUNT_STATS = gql`
  query fetchSubAccounts($input: FetchSubAccountsInput!) {
    fetchSubAccounts(input: $input) {
      totalCount
    }
  }
`;

const CLIENT_STATS = gql`
  query fetchUsers($input: FetchUsersInput!) {
    fetchUsers(input: $input) {
      totalCount
    }
  }
`;

const SUSPICIOUS_TRANSACTIONS_STATS = gql`
  query fetchAlertReports($input: FetchAlertReportsInput!) {
    fetchAlertReports(input: $input) {
      totalCount
    }
  }
`;

const FETCH_STATEMENTS_STATS = gql`
  query fetchAccountStatements($input: FetchAccountStatementsInput!) {
    fetchAccountStatements(input: $input) {
      totalCount
    }
  }
`;

export const FETCH_AWAITING_BANK_ACCOUNTS_COUNT = gql`
  query fetchBankAccounts($input: FetchBankAccountsInput!) {
    fetchBankAccounts(input: $input) {
      totalCount
    }
  }
`;

export const FETCH_AWAITING_INSTIUTIONS_COUNT = gql`
  query fetchInstitutions($input: FetchInstitutionsInput!) {
    fetchInstitutions(input: $input) {
      totalCount
    }
  }
`;

export const useGlobalStats = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { data: flagsData, refetch: flagsRefetch } = useQuery(FLAG_STATS, {
    variables: {
      input: {
        filter: { state: 'FLAGGED', organizationId: activeOrganization.id },
      },
    },
    skip: !permissions.includes('read:flags') || !activeOrganization.id,
  });
  const [flagsCount, setFlagsCount] = useState<number>(0);

  useEffect(() => {
    if (flagsData) {
      setFlagsCount(flagsData.fetchFlags.totalCount);
    }
  }, [flagsData]);

  const { data: subAccountsData, refetch: subAccountsRefetch } = useQuery(SUB_ACCOUNT_STATS, {
    variables: {
      input: {
        filter: {
          state: 'AWAITING_APPROVAL', isComplete: true, iDVerified: true, organizationId: activeOrganization.id,
        },
      },
    },
    skip: !permissions.includes('read:account_basic'),
  });
  const [subAccountsToReviewCount, setSubAccountsToReviewCount] = useState<number>(0);

  useEffect(() => {
    if (subAccountsData) {
      setSubAccountsToReviewCount(subAccountsData.fetchSubAccounts.totalCount);
    }
  }, [subAccountsData]);

  const { data: complianceReviewData, refetch: complianceReviewRefetch } = useQuery(CLIENT_STATS, {
    variables: {
      input: {
        filter: { complianceState: 'NEEDS_REVIEW', iDVerified: true, organizationId: activeOrganization.id },
      },
    },
    skip: !permissions.includes('read:client_basic'),
  });
  const [complianceReviewCount, setComplianceReviewCount] = useState<number>(0);

  useEffect(() => {
    if (complianceReviewData) {
      setComplianceReviewCount(complianceReviewData.fetchUsers.totalCount);
    }
  }, [complianceReviewData]);

  const { data: alertsData, refetch: alertsRefetch } = useQuery(SUSPICIOUS_TRANSACTIONS_STATS, {
    variables: {
      input: {
        filter: { state: 'INITIATED', organizationId: activeOrganization.id },
      },
    },
    skip: !permissions.includes('read:alert_report_basic'),
  });
  const [alertsCount, setAlertsCount] = useState<number>(0);

  useEffect(() => {
    if (alertsData) {
      setAlertsCount(alertsData.fetchAlertReports?.totalCount);
    }
  }, [alertsData]);

  const { data: statementsData, refetch: statementsRefresh } = useQuery(FETCH_STATEMENTS_STATS, {
    variables: {
      input: {
        filter: { state: 'AWAITING_REVIEW', organizationId: activeOrganization.id },
      },
    },
    skip: !permissions.includes('read:account_statement_basic'),
  });
  const [statementsToReviewCount, setStatementsToReviewCount] = useState<number>(0);

  useEffect(() => {
    if (statementsData) {
      setStatementsToReviewCount(statementsData.fetchAccountStatements.totalCount);
    }
  }, [statementsData]);

  const { data: suitabilityReviewData, refetch: suitabilityReviewRefetch } = useQuery(CLIENT_STATS, {
    variables: {
      input: {
        filter: {
          suitabilityReviewDue: true,
          organizationId: activeOrganization.id,
        },
      },
    },
    skip: !permissions.includes('read:client_suitability'),
  });
  const [annualSuitabilityReviewCount, setAnnualSuitabilityReviewCount] = useState<number>(0);

  useEffect(() => {
    if (suitabilityReviewData) {
      setAnnualSuitabilityReviewCount(suitabilityReviewData.fetchUsers.totalCount);
    }
  }, [suitabilityReviewData]);

  const { data: bankAccountData, refetch: bankAccountRefetch } = useQuery(FETCH_AWAITING_BANK_ACCOUNTS_COUNT, {
    variables: {
      input: {
        filter: {
          state: 'AWAITING_REVIEW',
          organizationId: activeOrganization.id,
        },
      },
    },
    skip: !permissions.includes('read:account_basic'),
  });
  const [bankAccountAwaitingReviewCount, setBankAccountAwaitingReviewCount] = useState<number>(0);

  useEffect(() => {
    if (bankAccountData) {
      setBankAccountAwaitingReviewCount(bankAccountData.fetchBankAccounts.totalCount);
    }
  }, [bankAccountData]);

  const { data: institutionData, refetch: institutionRefetch } = useQuery(FETCH_AWAITING_INSTIUTIONS_COUNT, {
    variables: {
      input: {
        filter: {
          state: 'AWAITING_REVIEW',
        },
      },
    },
    skip: !permissions.includes('read:institution_basic'),
  });
  const [institutionAwaitingReviewCount, setInstitutionAwaitingReviewCount] = useState<number>(0);

  useEffect(() => {
    if (institutionData) {
      setInstitutionAwaitingReviewCount(institutionData.fetchInstitutions.totalCount);
    }
  }, [institutionData]);

  return {
    flagsCount,
    flagsRefetch,
    alertsCount,
    alertsRefetch,
    subAccountsToReviewCount,
    subAccountsRefetch,
    complianceReviewCount,
    complianceReviewRefetch,
    statementsToReviewCount,
    statementsRefresh,
    annualSuitabilityReviewCount,
    suitabilityReviewRefetch,
    bankAccountAwaitingReviewCount,
    bankAccountRefetch,
    institutionAwaitingReviewCount,
    institutionRefetch,
  };
};
