import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { translateBackend } from 'assets/i18n/config';
import {
  Typography, Form,
} from '../../../..';

export const RiskProfileVisual = ({
  options,
}: {
  options: any,
}) => {
  const getMarkdown = (): string => {
    if (options?.filledTemplate) {
      return translateBackend(options?.filledTemplate);
    }
    return translateBackend(options?.template);
  };
  return (
    <Form >
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>{translateBackend(options?.subtitle)}</Typography>
      <Typography variant='bodyLarge' component={'div'} sx={{ mb: 3, table: { width: '100%' } }}>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{getMarkdown()}</ReactMarkdown>
      </Typography>
    </Form>
  );
};
