import { gql } from '@apollo/client';

export const UPDATE_CUSTOM_FIELD = gql`
  mutation updateCustomField($input: UpdateCustomFieldInput!) {
    updateCustomField(input: $input) {
      customField {
        id
      }
    }
  }
`;

export const TRANSITION_CUSTOM_FIELD = gql`
  mutation transitionCustomField($input: TransitionCustomFieldInput!) {
    transitionCustomField(input: $input) {
      customField {
        id
      }
    }
  }
`;

export const CREATE_CUSTOM_FIELD = gql`
  mutation createCustomField($input: CreateCustomFieldInput!) {
    createCustomField(input: $input) {
      customField {
        id
      }
    }
  }
`;
