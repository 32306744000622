import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Section } from './accountHighlights';

export interface AccountHighlightsInterface {
  [key: string]: {
    sections: Section[]
  }
}

export const AccountHighlightsConfig = (): AccountHighlightsInterface => {
  const { t } = useTranslation('components');
  const today = dayjs().format('YYYY-MM-DD');

  return ({
    RRSP: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('first60DaysContributions', { year: dayjs().year() }),
          key: 'rrsp60DaysContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayContributions',
          label: t('last10MonthsContributions', { year: dayjs().year() }),
          key: 'rrspLast10MonthsContributions',
          showIf: () => {
            const lastFebDay = dayjs().month(1).endOf('month').format('YYYY-MM-DD');
            const dateDiff = dayjs(today).diff(dayjs(lastFebDay));
            return dateDiff > 0;
          },
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('withdrawalsCurrentYear'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
      ],
    },
    RRSP_SPOUSAL: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('first60DaysContributions', { year: dayjs().year() }),
          key: 'rrsp60DaysContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayContributions',
          label: t('last10MonthsContributions', { year: dayjs().year() }),
          key: 'rrspLast10MonthsContributions',
          showIf: () => {
            const lastFebDay = dayjs().month(1).endOf('month').format('YYYY-MM-DD');
            const dateDiff = dayjs(today).diff(dayjs(lastFebDay));
            return dateDiff > 0;
          },
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('withdrawalsCurrentYear'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
      ],
    },
    GRSP: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('first60DaysContributions', { year: dayjs().year() }),
          key: 'rrsp60DaysContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayContributions',
          label: t('last10MonthsContributions', { year: dayjs().year() }),
          key: 'rrspLast10MonthsContributions',
          showIf: () => {
            const lastFebDay = dayjs().month(1).endOf('month').format('YYYY-MM-DD');
            const dateDiff = dayjs(today).diff(dayjs(lastFebDay));
            return dateDiff > 0;
          },
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('withdrawalsCurrentYear'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
      ],
    },
    RESP: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('YTDContributions'),
          key: 'respContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayContributions',
          label: t('governContributions'),
          key: 'respGovContributions',
          isContribution: true,
          useCustodianData: false,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('withdrawalsCurrentYear'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
      ],
    },
    RESP_ADULT: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('YTDContributions'),
          key: 'respContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayContributions',
          label: t('governContributions'),
          key: 'respGovContributions',
          isContribution: true,
          useCustodianData: false,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('withdrawalsCurrentYear'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
      ],
    },
    RESP_SINGLE: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('YTDContributions'),
          key: 'respContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayContributions',
          label: t('governContributions'),
          key: 'respGovContributions',
          isContribution: true,
          useCustodianData: false,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('withdrawalsCurrentYear'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
      ],
    },
    RESP_FAMILY: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('YTDContributions'),
          key: 'respContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayContributions',
          label: t('governContributions'),
          key: 'respGovContributions',
          isContribution: true,
          useCustodianData: false,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('withdrawalsCurrentYear'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
      ],
    },
    RESP_SINGLE_JOINT: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('YTDContributions'),
          key: 'respContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayContributions',
          label: t('governContributions'),
          key: 'respGovContributions',
          isContribution: true,
          useCustodianData: false,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('withdrawalsCurrentYear'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
      ],
    },
    RESP_FAMILY_JOINT: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('YTDContributions'),
          key: 'respContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayContributions',
          label: t('governContributions'),
          key: 'respGovContributions',
          isContribution: true,
          useCustodianData: false,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('withdrawalsCurrentYear'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
      ],
    },
    RRIF: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('YTDContributions'),
          key: 'defaultContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('withdrawalsCurrentYear'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('minWithdrawalCurrentYear'),
          key: 'minWithdrawal',
          isContribution: true,
          useCustodianData: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('maxWithdrawalCurrentYear'),
          key: 'maxWithdrawal',
          isContribution: true,
          useCustodianData: true,
        },
      ],
    },
    RIF_SPOUSAL: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('YTDContributions'),
          key: 'defaultContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('withdrawalsCurrentYear'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('minWithdrawalCurrentYear'),
          key: 'minWithdrawal',
          isContribution: true,
          useCustodianData: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('maxWithdrawalCurrentYear'),
          key: 'maxWithdrawal',
          isContribution: true,
          useCustodianData: true,
        },
      ],
    },
    PRIF: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('YTDContributions'),
          key: 'defaultContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('withdrawalsCurrentYear'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('minWithdrawalCurrentYear'),
          key: 'minWithdrawal',
          isContribution: true,
          useCustodianData: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('maxWithdrawalCurrentYear'),
          key: 'maxWithdrawal',
          isContribution: true,
          useCustodianData: true,
        },
      ],
    },
    LRIF: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('YTDContributions'),
          key: 'defaultContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('withdrawalsCurrentYear'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('minWithdrawalCurrentYear'),
          key: 'minWithdrawal',
          isContribution: true,
          useCustodianData: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('maxWithdrawalCurrentYear'),
          key: 'maxWithdrawal',
          isContribution: true,
          useCustodianData: true,
        },
      ],
    },
    RLIF: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('YTDContributions'),
          key: 'defaultContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('withdrawalsCurrentYear'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('minWithdrawalCurrentYear'),
          key: 'minWithdrawal',
          isContribution: true,
          useCustodianData: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('maxWithdrawalCurrentYear'),
          key: 'maxWithdrawal',
          isContribution: true,
          useCustodianData: true,
        },
      ],
    },
    LIF: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('YTDContributions'),
          key: 'defaultContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('withdrawalsCurrentYear'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('minWithdrawalCurrentYear'),
          key: 'minWithdrawal',
          isContribution: true,
          useCustodianData: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('maxWithdrawalCurrentYear'),
          key: 'maxWithdrawal',
          isContribution: true,
          useCustodianData: true,
        },
      ],
    },
    USA_IRA: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('currentYearContributions'),
          key: 'iraCurrentYearContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayContributions',
          label: t('priorYearContributions'),
          key: 'iraPriorYearContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('currentYearWithdrawals'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('priorYearWithdrawals'),
          key: 'priorYearWithdrawals',
          isContribution: true,
        },
      ],
    },
    USA_RO_IRA: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('currentYearContributions'),
          key: 'iraCurrentYearContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayContributions',
          label: t('priorYearContributions'),
          key: 'iraPriorYearContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('currentYearWithdrawals'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('priorYearWithdrawals'),
          key: 'priorYearWithdrawals',
          isContribution: true,
        },
      ],
    },
    USA_RT_IRA: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('currentYearContributions'),
          key: 'iraCurrentYearContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayContributions',
          label: t('priorYearContributions'),
          key: 'iraPriorYearContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('currentYearWithdrawals'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('priorYearWithdrawals'),
          key: 'priorYearWithdrawals',
          isContribution: true,
        },
      ],
    },
    USA_SEP_IRA: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('currentYearContributions'),
          key: 'iraCurrentYearContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayContributions',
          label: t('priorYearContributions'),
          key: 'iraPriorYearContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('currentYearWithdrawals'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('priorYearWithdrawals'),
          key: 'priorYearWithdrawals',
          isContribution: true,
        },
      ],
    },
    PERSONAL: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('YTDDeposits'),
          key: 'defaultContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('YTDWithdrawals'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
      ],
    },
    CASH_JOINT: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('YTDDeposits'),
          key: 'defaultContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('YTDWithdrawals'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
      ],
    },
    CORPORATE_CASH: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('YTDDeposits'),
          key: 'defaultContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('YTDWithdrawals'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
      ],
    },
    default: {
      sections: [
        {
          enabledBy: 'displayContributions',
          label: t('YTDContributions'),
          key: 'defaultContributions',
          isContribution: true,
        },
        {
          enabledBy: 'displayWithdrawals',
          label: t('withdrawalsCurrentYear'),
          key: 'defaultWithdrawals',
          isContribution: true,
        },
      ],
    },
  });
};
