import {
  isBoolean, isArray, isEmpty, isNull, isObject, isUndefined, isNumber,
} from 'lodash/fp';
import { t } from 'i18next';
import { ForeignTaxInformation } from 'interfaces/foreignTaxInformation';
import { isString } from 'lodash';

export type FormError = {
  active: boolean,
  message?: string,
};
export type FieldOption = {
  required: boolean,
  errorMessage?: string,
  requiredIf?: (data: any) => boolean,
};
export type FormErrors = { [fieldId: string]: FormError } | null;
export type FieldOptions = { [fieldId: string]: FieldOption };

export const createError = (message = t('shared:required')): FormError => ({ active: true, message });

export const validateFields = (
  fieldOptions: FieldOptions,
  data: { [fieldId: string]: any },
  candidateFields?: string[],
): FormErrors => {
  const errors: FormErrors = {};
  const fields = invalidFields(fieldOptions, data);
  fields.filter((field) => (candidateFields ? candidateFields.includes(field) : true)).forEach((field) => {
    errors[field] = createError(fieldOptions[field].errorMessage);
  });
  return isEmpty(errors) ? null : errors;
};

export const invalidFields = (options: { [fieldID: string]: { [optionName: string]: any } }, data: { [fieldID: string]: any }) => {
  const missingFields: string[] = [];

  Object.entries(options).forEach(([fieldId, fieldOptions]) => {
    if (!fieldOptions) return;
    const value = data[fieldId];
    const isEnabled = fieldOptions.enabled ?? true;
    const isRequired = isBoolean(fieldOptions.required) ? fieldOptions.required : fieldOptions.required === 'ERROR';
    const isRequiredConditionally = fieldOptions.requiredIf === undefined || fieldOptions.requiredIf(data);
    const isIncomplete = (
      isUndefined(value)
      || (isNumber(value) && value < 0)
      || (isString(value) && value.trim() === '')
      || isNull(value)
      || value === ''
      || ((isObject(value) || isArray(value)) && isEmpty(value))
      || (fieldId.includes('Address') && !value.streetName)
      || (fieldId === 'sin' && value.length !== 9)
      || (fieldId === 'foreignTaxInformation' && value.filter(
        (v: ForeignTaxInformation) => v.foreignTaxNumber === '' || v.foreignTaxCountry === '',
      ).length > 0)
    );
    if (
      isEnabled
      && isRequired
      && isRequiredConditionally
      && isIncomplete
    ) missingFields.push(fieldId);
  });

  return missingFields;
};

export function base64ToFile(base64Data: string, fileName: string): File {
  const dataParts = base64Data.split(';base64,');
  const contentType = dataParts[0].replace('data:', '');
  const decodedData = atob(dataParts[1]);
  const buffer = new ArrayBuffer(decodedData.length);
  const arrayView = new Uint8Array(buffer);
  for (let i = 0; i < decodedData.length; i++) {
    arrayView[i] = decodedData.charCodeAt(i);
  }
  const blob = new Blob([buffer], { type: contentType || 'application/octet-stream' });
  return new File([blob], fileName, { type: contentType || 'application/octet-stream' });
}

export const getEnabledCustomFields = (options: any) => {
  const applicableCustomKeys: any[] = [];
  Object.keys(options).forEach((key: string) => {
    if (options[key]?.customField && options[key].enabled) {
      applicableCustomKeys.push(options[key]);
    }
  });
  const applicableKeys = applicableCustomKeys.map((c: any) => c.customField.key);
  return applicableKeys || [];
};
