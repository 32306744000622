import React, { useContext, ReactNode } from 'react';
import { CIAMProvider } from './CIAMProvider';

// Define the type for the AuthContext
interface AuthContextType {
  loginPing: (clientHostedLoginURL: string, redirectUrl: string, clientHostedLoginClientId: string, clientHostedLoginScope: string) => Promise<void>;
  handleCallback: (clientHostedLoginURL: string, redirectUrl: string, clientHostedLoginClientId: string) => Promise<void>;
}

// Create the AuthContext with an empty initial value
const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

// Hook to use the AuthContext
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// Define the type for the AuthProvider's props
interface AuthProviderProps {
  children: ReactNode;
}

// AuthProvider component
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => <CIAMProvider AuthContext={AuthContext}>{children}</CIAMProvider>;
