import { getAccountName, getGoalName, getSubAccountName } from '../../../../interfaces';
import { generateClientNameString } from '../../../../util';
import {
  MenuItem,
} from '../../../2-component';
import {
  INDIVIDUAL_FORMS,
  NON_INDIVIDUAL_FORMS,
  ACCOUNT_FORMS,
  SUB_ACCOUNT_FORMS,
  GOAL_FORMS,
  SCHEDULE_TRANSFER_FORMS,
  CLIENT_GROUP_FORMS,
} from './formCategories';

const generateMenuOptions = ({
  data,
  formTemplateType,
}: {
  data?: any,
  formTemplateType: string,
}): JSX.Element[] => {
  const isUserForm = INDIVIDUAL_FORMS.includes(formTemplateType) || NON_INDIVIDUAL_FORMS.includes(formTemplateType);
  const isAccountForm = ACCOUNT_FORMS.includes(formTemplateType);
  const isSubAccountForm = SUB_ACCOUNT_FORMS.includes(formTemplateType);
  const isGoalForm = GOAL_FORMS.includes(formTemplateType);
  const isScheduleTransferForm = SCHEDULE_TRANSFER_FORMS.includes(formTemplateType);
  const isClientGroupForm = CLIENT_GROUP_FORMS.includes(formTemplateType);

  const getMenuDataOptions = (previewData: any): JSX.Element[] => {
    if (isUserForm) {
      return (previewData.fetchUsers?.users || []).map((user: any) => (
        <MenuItem key={user.id} value={user.id}>{generateClientNameString(user)}</MenuItem>
      ));
    }
    if (isAccountForm) {
      return (previewData.fetchAccounts?.accounts || []).map((account: any) => (
        <MenuItem key={account.id} value={account.id}>{getAccountName(account)}</MenuItem>
      ));
    }
    if (isSubAccountForm) {
      return (previewData.fetchSubAccounts?.subAccounts || []).map((subAccount: any) => (
        <MenuItem key={subAccount.id} value={subAccount.id}>{getSubAccountName(subAccount)}</MenuItem>
      ));
    }
    if (isGoalForm) {
      return (previewData.fetchGoals?.goals || []).map((goal: any) => (
        <MenuItem key={goal.id} value={goal.id}>{getGoalName(goal)}</MenuItem>
      ));
    }
    if (isClientGroupForm) {
      return (previewData.fetchClientGroups?.clientGroups || []).map((clientGroup: any) => (
        <MenuItem key={clientGroup.id} value={clientGroup.id}>{clientGroup.id} - ({clientGroup.type} - {clientGroup.name})</MenuItem>
      ));
    }
    if (isScheduleTransferForm) {
      return (previewData.fetchScheduledTransfers?.scheduledTransfers || []).map((scheduledTransfer: any) => (
        <MenuItem key={scheduledTransfer.id} value={scheduledTransfer.id}>{scheduledTransfer.type} - {scheduledTransfer.frequency}</MenuItem>
      ));
    }
    return [];
  };
  return getMenuDataOptions(data);
};
export default generateMenuOptions;
