import { Account } from './account';
import { BillingSchedule } from './billingSchedule';
import { FeeTier } from './feeTier';
import { Organization } from './organization';
import { PhysicalAddress } from './physicalAddress';
import { Relationship } from './relationship';
import { Statistics } from './stats';
import { User } from './user';

type Maybe<T> = T | null;

export enum ClientGroupTypes {
  Business = 'BUSINESS',
  Household = 'HOUSEHOLD',
}

export enum ClientGroupMemberAccessTypes {
  View = 'VIEW',
  NoAccess = 'NO_ACCESS',
}

export enum CorporateBusinessTypes {
  Corporation = 'CORPORATION',
  Llc = 'LLC',
  Partnership = 'PARTNERSHIP',
  SoleProprietorship = 'SOLE_PROPRIETORSHIP',
}

enum CorporateCountries {
  Ca = 'CA',
  Usa = 'USA',
}

export interface ClientGroup {
  id: string;
  accounts?: Account[];
  allIncompleteFormAgreements?: [],
  billingSchedule?: Maybe<BillingSchedule>;
  businessIsMemberOfIiroc?: Maybe<boolean>;
  businessType?: Maybe<CorporateBusinessTypes>;
  completedAt?: Maybe<string>;
  corporationCountry?: Maybe<CorporateCountries>;
  establishedDate?: Maybe<string>;
  feeTier?: Maybe<FeeTier>;
  incompleteFields?: Maybe<Array<string>>;
  incorporationNumber?: Maybe<string>;
  name: string;
  organization?: Maybe<Organization>;
  phone?: Maybe<string>;
  physicalAddress?: Maybe<PhysicalAddress>;
  primaryEmail?: Maybe<string>;
  primaryUser: User;
  relationships: Array<Relationship>;
  taxNumber?: Maybe<string>;
  type: ClientGroupTypes;
  applicableBillingSchedule?: Maybe<BillingSchedule>;
  applicableFeeTier?: Maybe<FeeTier>;
  statistics?: Statistics;
  custodianStatistics?: Statistics;
  state: ClientGroupStates;
  availableCurrencies?: string[],
}

export enum ClientGroupStates {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}
