import { useContext, useState } from 'react';
import { DocumentNode, useLazyQuery, useQuery } from '@apollo/client';
import { Box, Skeleton } from '../../../1-primative';
import { SelectField } from '../../../2-component';
import { UserContext } from '../../../../providers/userContextProvider';
import {
  FETCH_ACCOUNTS, FETCH_SCHEDULED_TRANSFERS,
  FETCH_CLIENT_GROUPS, FETCH_GOALS, FETCH_SUB_ACCOUNTS,
  FETCH_USERS, PREVIEW_FORM_AGREEMENT,
} from './previewObject.queries';
import {
  getMenuLabel,
  isAccountForm,
  isClientGroupForm,
  isGoalForm,
  isScheduleTransferForm,
  isSubAccountForm,
  isUserForm,
} from './formCategories';
import generateMenuOptions from './generateMenuOptions';

const PreviewObject = ({
  onSelectObjectToPreview,
  formTemplateType,
  placement,
  selectedId,
}: {
  onSelectObjectToPreview: (html: string, selectedObjectId: string) => void;
  formTemplateType: string;
  placement: 'CONTENT' | 'TITLE';
  selectedId?: string;
}) => {
  const [objectId, setObjectId] = useState(selectedId ?? '');
  const { activeOrganization } = useContext(UserContext);
  const getQueryBasedOnType = (): DocumentNode => {
    if (isUserForm(formTemplateType)) return FETCH_USERS;
    if (isAccountForm(formTemplateType)) return FETCH_ACCOUNTS;
    if (isSubAccountForm(formTemplateType)) return FETCH_SUB_ACCOUNTS;
    if (isGoalForm(formTemplateType)) return FETCH_GOALS;
    if (isClientGroupForm(formTemplateType)) return FETCH_CLIENT_GROUPS;
    if (isScheduleTransferForm(formTemplateType)) return FETCH_SCHEDULED_TRANSFERS;
    return FETCH_USERS;
  };
  const [previewAgreement, { loading: previewLoading }] = useLazyQuery(PREVIEW_FORM_AGREEMENT, {
    variables: {
      input: {},
    },
  });
  const { loading: dataLoading, data: previewData } = useQuery(getQueryBasedOnType(), {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          organizationId: isAccountForm(formTemplateType) ? undefined : activeOrganization.id,
        },
      },
    },
  });
  const isLoading = dataLoading || previewLoading;
  return (
    <Box sx={{ width: placement === 'TITLE' ? '240px' : undefined, textAlign: 'left' }}>
      <Box>
        {isLoading && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Skeleton width='100%' height='25px' variant='rectangular' />
          </Box>
        )}
        {previewData && !isLoading && (
          <SelectField
            fullWidth
            value={objectId}
            label={placement === 'CONTENT' ? getMenuLabel(formTemplateType) : ''}
            onChange={(event: any) => {
              setObjectId(event?.target.value);
              previewAgreement({
                variables: {
                  input: {
                    type: formTemplateType,
                    userId: isUserForm(formTemplateType) ? event?.target.value : undefined,
                    accountId: isAccountForm(formTemplateType) ? event?.target.value : undefined,
                    subAccountId: isSubAccountForm(formTemplateType) ? event?.target.value : undefined,
                    scheduledTransferId: isScheduleTransferForm(formTemplateType) ? event?.target.value : undefined,
                    goalId: isGoalForm(formTemplateType) ? event?.target.value : undefined,
                    clientGroupId: isClientGroupForm(formTemplateType) ? event?.target.value : undefined,
                  },
                },
                onCompleted: (previewHtmlData: any) => {
                  onSelectObjectToPreview(previewHtmlData?.previewFormAgreement?.html, event?.target.value);
                },
              });
            }}
            sx={{ width: placement === 'TITLE' ? '240px' : undefined }}
            data-testid='selectPreviewObjectField'
          >
            {generateMenuOptions({
              data: previewData,
              formTemplateType,
            })}
          </SelectField>
        )}
      </Box>
    </Box>
  );
};
export default PreviewObject;
