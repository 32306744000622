import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import { TableCell, Tooltip } from '../../../../2-component';
import { translateBackend } from '../../../../../assets/i18n/config';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { formatMoneyValue, formatPercentValue } from '../../../../../util';
import { Box, Typography } from '../../../../1-primative';
import MultiCurrency from '../../../../../assets/images/custom/multiCurrency.svg';
import { Account, Holding } from '../../../../../interfaces';
import { UserContext } from '../../../../../providers/userContextProvider';
import { currencySymbol, getCurrencyFlag } from '../../../../../util/currency';
import { CustomFieldFormats } from '../../../../../interfaces/customField';

export const AccountCell = ({
  account, type, totalMarketValue, useExternalStatisticsEnabled, displayCurrency, isFirst,
}: { account: Account, type: string, totalMarketValue: number, useExternalStatisticsEnabled?: boolean, displayCurrency?: boolean, isFirst?: boolean }) => {
  const { t } = useTranslation('accountTypes');
  const { sys } = useThemeTokens();
  const { activeCurrency } = useContext(UserContext);
  const [groupedMarketValue, setGroupedMarketValue] = useState<{ currency?: CurrencyCodes, marketValueCents?: number }[]>([]);
  const statistics = useExternalStatisticsEnabled ? account.custodianStatistics : account.statistics;
  // The simpleReturnAmount is actually coming in cents.
  // TODO: update history-service to return simpleReturnAmountCents and deprecate simpleReturnAmount
  const simpleReturnAmountCents = useExternalStatisticsEnabled ? statistics?.simpleReturnAmountCents : statistics?.simpleReturnAmount;

  const accountHolders = () => {
    const names = [
      account.user.firstName,
      ...(account?.affiliations ?? []).filter((aff: any) => ['JOINT', 'AUTHORIZED_INDIVIDUAL', 'TRUSTEE'].includes(aff.type)).map((affiliation: any) => affiliation.user.firstName),
    ];
    return names.join(', ');
  };

  useEffect(() => {
    if (useExternalStatisticsEnabled && statistics?.originalCurrencies) {
      const marketValueByCurrency = statistics.originalCurrencies.filter((elem) => elem.currency !== activeCurrency);
      marketValueByCurrency.sort((a: any, b: any) => (a.currency > b.currency ? 1 : (a.currency < b.currency ? -1 : 0)));
      setGroupedMarketValue(marketValueByCurrency);
    } else if (!useExternalStatisticsEnabled && statistics?.holdings) {
      const marketValueByCurrency = statistics.holdings.reduce((acc: { currency: CurrencyCodes, marketValueCents: number }[], holding: Holding) => {
        if (holding.originalCurrency && holding.originalCurrency.currency !== activeCurrency) {
          const { originalCurrency } = holding;
          const currencyHolding = acc.find((elem) => elem.currency === originalCurrency.currency);
          if (currencyHolding) {
            currencyHolding.marketValueCents += originalCurrency.totalCents ?? 0;
          } else {
            acc.push({
              currency: originalCurrency.currency ?? CurrencyCodes.CAD,
              marketValueCents: originalCurrency.totalCents ?? 0,
            });
          }
        }
        return acc;
      }, []);
      marketValueByCurrency.sort((a: any, b: any) => (a.currency > b.currency ? 1 : (a.currency < b.currency ? -1 : 0)));
      setGroupedMarketValue(marketValueByCurrency);
    }
  }, [statistics, useExternalStatisticsEnabled, activeCurrency]);

  const getUnrealizedGainsAndLoss = () => (
      <>
        {`${formatMoneyValue(simpleReturnAmountCents ?? 0)}
        (${formatPercentValue(statistics?.simpleReturnPercent ?? 0)})`}
      </>
  );

  const getTwrr = (): number => statistics?.timeWeightedReturn ?? 0;

  const getMwrr = (): number => statistics?.moneyWeightedReturn ?? 0;

  const getPercentageValue = () => {
    const value = (((statistics?.marketValueCents ?? 0) / totalMarketValue) * 100).toFixed(1);
    return (
      <TableCell number isFirst={isFirst} leadingComponent={displayCurrency && account?.baseCurrency ? getLeadingComponent() : undefined}>
        {`${value}%`}
      </TableCell>
    );
  };
  const getCurrentValue = () => formatMoneyValue(statistics?.marketValueCents ?? 0, currencySymbol[statistics?.currency as CurrencyCodes ?? CurrencyCodes.CAD]);

  const getCustomFieldValue = () => {
    if (useExternalStatisticsEnabled && account?.custodianCustomFields) {
      const accountCustomField = account.custodianCustomFields.find((elem) => elem.key === type);
      return accountCustomField?.value ?? '-';
    }
    if (account?.customFields) {
      const accountCustomField = account.customFields.find((elem) => elem.key === type);
      if (accountCustomField?.customField?.format) {
        const { format } = accountCustomField.customField;
        switch (format) {
          case CustomFieldFormats.SIMPLE_TEXT:
            return accountCustomField?.value ?? '-';
          case CustomFieldFormats.CURRENCY:
            return formatMoneyValue(accountCustomField?.value ?? 0);
          case CustomFieldFormats.NUMBER:
            return accountCustomField?.value ?? 0;
          default:
            return '-';
        }
      }
    }
    return '-';
  };

  const getLeadingComponent = () => (
    <Box
      sx={{
        height: 20,
        width: 20,
        borderRadius: 10,
        overflow: 'hidden',
      }}
    >
      <img alt={account?.baseCurrency} src={getCurrencyFlag(account?.baseCurrency as CurrencyCodes)} style={{ objectFit: 'cover', height: '100%' }} />
    </Box>
  );

  switch (type) {
    case 'holder':
      return (
        <TableCell isFirst={isFirst} leadingComponent={displayCurrency && account?.baseCurrency ? getLeadingComponent() : undefined}>{accountHolders()}</TableCell>
      );
    case 'nickname':
      return (
        <TableCell isFirst={isFirst} leadingComponent={displayCurrency && account?.baseCurrency ? getLeadingComponent() : undefined}>{account?.nickName ?? '-'}</TableCell>
      );
    case 'name':
      return (
        <TableCell isFirst={isFirst} leadingComponent={displayCurrency && account?.baseCurrency ? getLeadingComponent() : undefined}>{t(`accountTypes:${account.type}`)}</TableCell>
      );
    case 'accountNumber':
      return (
        <TableCell isFirst={isFirst} leadingComponent={displayCurrency && account?.baseCurrency ? getLeadingComponent() : undefined}>{account.custodianAccountNumber}</TableCell>
      );
    case 'program':
      return (
        <TableCell isFirst={isFirst} leadingComponent={displayCurrency && account?.baseCurrency ? getLeadingComponent() : undefined}>
          {translateBackend((account?.subAccounts ?? [])[0]?.theme?.translatedName)}
        </TableCell>
      );
    case 'unrealizedGainLoss':
      return (
        <TableCell
          number
          isFirst={isFirst}
          sx={{
            textAlign: 'right',
            color: (simpleReturnAmountCents ?? 0) === 0 ? sys.color.onSurface : (simpleReturnAmountCents ?? 0) >= 0 ? sys.color.positive : sys.color.negative,
          }}
          leadingComponent={displayCurrency && account?.baseCurrency ? getLeadingComponent() : undefined}
        >
          {getUnrealizedGainsAndLoss()}
        </TableCell>
      );
    case 'twrr':
      return (
        <TableCell
          number
          isFirst={isFirst}
          sx={{ textAlign: 'right', color: getTwrr() === 0 ? sys.color.onSurface : getTwrr() >= 0 ? sys.color.positive : sys.color.negative }}
          leadingComponent={displayCurrency && account?.baseCurrency ? getLeadingComponent() : undefined}
        >
          {formatPercentValue(getTwrr())}
        </TableCell>
      );
    case 'mwrr':
      return (
        <TableCell
          number
          isFirst={isFirst}
          sx={{ textAlign: 'right', color: getMwrr() === 0 ? sys.color.onSurface : getMwrr() >= 0 ? sys.color.positive : sys.color.negative }}
          leadingComponent={displayCurrency && account?.baseCurrency ? getLeadingComponent() : undefined}
        >
          {formatPercentValue(getMwrr())}
        </TableCell>
      );
    case 'percentOfTotal':
      return getPercentageValue();
    case 'currentValue':
      return (
        <TableCell
          right
          bold
          isFirst={isFirst}
          number
          leadingComponent={
            <Box display='flex' alignItems='center' gap={1}>
              {displayCurrency && account?.baseCurrency && getLeadingComponent()}
              {(groupedMarketValue.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {groupedMarketValue.map((elem) => (
                        <Typography sx={{ color: sys.color.surface }} key={elem.currency} variant='trimmedSmall'>
                          {`${elem.currency} ${formatMoneyValue(elem.marketValueCents, '', 2, false)}`}
                        </Typography>
                      ))}
                    </>
                  }
                  placement='top'
                >
                  <Box sx={{ width: '20px', height: '20px' }}>
                    <img src={MultiCurrency} alt='multi-currency' style={{ width: '20px', height: '20px' }} />
                  </Box>
                </Tooltip>
              ))}
            </Box>
          }
        >
          {getCurrentValue()}
        </TableCell>
      );
    default:
      return (
        <TableCell isFirst={isFirst}>
        {getCustomFieldValue()}
        </TableCell>
      );
  }
};
