/* eslint-disable no-console */
import React, {
  ReactNode,
} from 'react';
import {
  AuthorizationServiceConfiguration,
  AuthorizationRequest,
  RedirectRequestHandler,
  AuthorizationNotifier,
  TokenRequest,
  GRANT_TYPE_AUTHORIZATION_CODE,
  BaseTokenRequestHandler,
  FetchRequestor,
} from '@openid/appauth';

interface AuthContextType {
  loginPing: (clientHostedLoginURL: string, redirectUrl: string, clientHostedLoginClientId: string, clientHostedLoginScope: string) => Promise<void>;
  handleCallback: (clientHostedLoginURL: string, redirectUrl: string, clientHostedLoginClientId: string) => Promise<void>;
}

interface CIAMProviderProps {
  AuthContext: React.Context<AuthContextType | undefined>;
  children: ReactNode;
}

const authorizationHandler = new RedirectRequestHandler();
const notifier = new AuthorizationNotifier();
authorizationHandler.setAuthorizationNotifier(notifier);
const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());

export const buildNewTokenRequest = async (
  clientId: string,
  redirectUrl: string,
  code: string,
  code_verifier: string,
) => {
  const tokenRequest = new TokenRequest({
    client_id: clientId,
    redirect_uri: `${redirectUrl}?authCode=true`,
    grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
    code,
    extras: {
      code_verifier: code_verifier ?? '',
    },
  });

  return tokenRequest;
};

export const CIAMProvider: React.FC<CIAMProviderProps> = ({ AuthContext, children }) => {
  const loginPing = async (clientHostedLoginURL: string, redirectUrl: string, clientHostedLoginClientId: string, clientHostedLoginScope: string) => {
    const request = new AuthorizationRequest({
      client_id: clientHostedLoginClientId,
      redirect_uri: `${redirectUrl}?authCode=true`,
      scope: clientHostedLoginScope,
      response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
      extras: { response_mode: 'fragment', prompt: 'login' },
    });

    AuthorizationServiceConfiguration.fetchFromIssuer(
      clientHostedLoginURL,
      new FetchRequestor(),
    ).then((config) => {
      authorizationHandler.performAuthorizationRequest(config, request);
    }).catch((error) => {
      console.log('Something bad happened', error);
    });
  };

  const handleCallback = async (redirectUrl: string) => {
    setupAuthenticationListener(redirectUrl);

    await authorizationHandler.completeAuthorizationRequestIfPossible();
  };

  const setupAuthenticationListener = (redirectUrl: string) => {
    notifier.setAuthorizationListener(async (authorizationRequest, authorizationResponse, authorizationError) => {
      if (authorizationError) {
        console.error('Error in setupAuthenticationListener', authorizationError);
      } else {
        const tokenRequest = new TokenRequest({
          client_id: 'DWEWeb',
          redirect_uri: redirectUrl,
          grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
          code: authorizationResponse?.code,
          extras: {
            code_verifier: authorizationRequest?.internal?.code_verifier ?? '',
          },
        });

        console.log(JSON.stringify(tokenRequest, null, 3));

        try {
          const config = await AuthorizationServiceConfiguration.fetchFromIssuer(
            'https://login-uat.atb.com',
            new FetchRequestor(),
          );
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          await tokenHandler.performTokenRequest(config, tokenRequest) || {};
        } catch (error) {
          console.log('Token error:', error);
        }
      }
    });
  };

  return (
    <AuthContext.Provider value={{
      loginPing, handleCallback,
    }}>
      {children}
    </AuthContext.Provider>
  );
};
