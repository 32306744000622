import PeopleIcon from '@mui/icons-material/People';
import PieChartIcon from '@mui/icons-material/PieChart';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import SettingsIcon from '@mui/icons-material/Settings';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import InsightsIcon from '@mui/icons-material/Insights';
import { ovAnalyticsEvents } from '../../../util/analytics/analytics';
import { Section } from '../../2-component/navSection/navSection';

export const sectionsConfig: Section[] = [
  {
    name: 'menu.workflows',
    icon: InsightsIcon,
    subSections: [
      {
        name: 'menu.workflows',
        path: '/workflows',
        accessKey: 'WORKFLOWS',
      },
    ],
  },
  {
    name: 'menu.clientManagement',
    icon: PeopleIcon,
    subSections: [
      {
        name: 'menu.households',
        path: '/households',
        accessKey: 'HOUSEHOLDS',
      },
      {
        /* superseded by INDIVIDUAL_CLIENTS, TODO: remove this once OV-8830 finished */
        name: 'menu.individuals',
        path: '/clients',
        accessKey: 'CLIENTS',
      },
      {
        name: 'menu.individuals',
        path: '/clients',
        accessKey: 'INDIVIDUAL_CLIENTS',
      },
      {
        /* superseded by NON_INDIVIDUAL_CLIENTS, TODO: remove this once OV-8830 finished */
        name: 'menu.corporations',
        path: '/corporations',
        accessKey: 'CORPORATIONS',
      },
      {
        name: 'menu.nonIndividualClients',
        path: '/nonIndividualClients',
        accessKey: 'NON_INDIVIDUAL_CLIENTS',
      },
    ],
  },
  {
    name: 'menu.portfolioManagement',
    icon: PieChartIcon,
    subSections: [
      {
        name: 'menu.modelPortfolios',
        path: '/buildModels',
        accessKey: 'BUILD_MODELS',
      },
      {
        name: 'menu.modelPortfoliosV2',
        path: '/modelPortfolios',
        accessKey: 'MODEL_PORTFOLIOS',
      },
      {
        name: 'menu.productShelfPage',
        path: '/productShelf',
        accessKey: 'PRODUCT_SHELF',
      },
      {
        name: 'menu.assetClassesPage',
        path: '/assetClasses',
        accessKey: 'ASSET_CLASSES',
      },
      {
        name: 'menu.tradingSchedules',
        path: '/schedules',
        accessKey: 'SCHEDULES',
      },
    ],
  },
  {
    name: 'menu.trading',
    icon: SsidChartIcon,
    subSections: [
      {
        name: 'menu.rebalancing',
        path: '/rebalances',
        accessKey: 'REBALANCING',
      },
      {
        name: 'menu.rebalanceReport',
        path: '/rebalanceReport',
        accessKey: 'REBALANCE_REPORT',
      },
      {
        name: 'menu.portfolioOptimizer',
        path: '/portfolioOptimizers',
        accessKey: 'PORTFOLIO_OPTIMIZER',
      },
      {
        name: 'menu.bulkTrader',
        path: '/bulkTrader',
        accessKey: 'BULK_TRADER',
      },
      {
        name: 'menu.taxLossHarvesting',
        path: '/taxLossHarvesting',
        accessKey: 'TAX_LOSS_HARVESTING',
      },
    ],
  },
  {
    name: 'menu.compliance',
    icon: FactCheckIcon,
    subSections: [
      {
        name: 'menu.accountsReview',
        path: '/accountsReview',
        accessKey: 'ACCOUNTS_REVIEW',
        stats: 'subAccountsToReviewCount',
      },
      {
        name: 'menu.complianceReview',
        path: '/complianceReview',
        accessKey: 'COMPLIANCE_REVIEW',
        stats: 'complianceReviewCount',
      },
      {
        name: 'menu.transactionMonitoringTool',
        path: '/transactionMonitoringTool',
        accessKey: 'SUSPICIOUS_TRANSACTIONS',
        stats: 'alertsCount',
      },
      {
        name: 'menu.transactions',
        path: '/transactions',
        accessKey: 'TRANSACTIONS',
      },
      {
        name: 'menu.elderlyClients',
        path: '/elderlyClients',
        accessKey: 'ELDERLY_CLIENTS',
      },
      {
        name: 'menu.annualSuitabilityReview',
        path: '/annualSuitabilityReview',
        accessKey: 'ANNUAL_SUITABILITY_REVIEW',
        stats: 'annualSuitabilityReviewCount',
      },
      {
        name: 'menu.bankAccountsToReview',
        path: '/BankAccountsToReview',
        accessKey: 'BANK_ACCOUNTS_TO_REVIEW',
        stats: 'bankAccountAwaitingReviewCount',
      },
      {
        name: 'menu.institutionsToReview',
        path: '/institutionsToReview',
        accessKey: 'INSTITUTIONS_TO_REVIEW',
        stats: 'institutionAwaitingReviewCount',
      },
    ],
  },
  {
    name: 'menu.reports',
    icon: InsertChartIcon,
    subSections: [
      {
        name: 'menu.reports',
        path: '/reports',
        accessKey: 'REPORTS',
      },
    ],
  },
  {
    name: 'menu.feeAndBilling',
    icon: MonetizationOnIcon,
    subSections: [
      {
        name: 'menu.billingManagement',
        path: '/billingManagement',
        accessKey: 'BILLING_MANAGEMENT',
      },
      {
        name: 'menu.feeGrids',
        path: '/feeGrids',
        accessKey: 'FEE_GRIDS',
      },
      {
        name: 'menu.billingSchedules',
        path: '/billingSchedules',
        accessKey: 'BILLING_SCHEDULES',
      },
      {
        name: 'menu.revenueShareReport',
        path: '/revenueShareReport',
        accessKey: 'ORGANIZATION_REVENUE_SHARE',
      },
    ],
  },
  {
    name: 'menu.systemManagement',
    icon: SettingsIcon,
    subSections: [
      {
        name: 'menu.reconciliation',
        path: '/reconciliation',
        accessKey: 'RECONCILIATION',
        stats: 'flagsCount',
      },
      {
        name: 'menu.statementsReview',
        path: '/statementsReview',
        accessKey: 'STATEMENTS_REVIEW',
        stats: 'statementsToReviewCount',
      },
      {
        name: 'menu.bulkImport',
        path: '/bulkImport',
        accessKey: 'BULK_IMPORT',
      },
      {
        name: 'menu.newsAndInsights',
        path: '/newsAndInsights',
        accessKey: 'NEWS_AND_INSIGHT',
      },
      {
        name: 'menu.devSettings',
        path: '/devSettings',
        accessKey: 'DEVELOPER_SETTINGS',
      },
      {
        name: 'menu.orgSettings',
        path: '/orgSettings',
        accessKey: 'ORGANIZATION_SETTINGS',
      },
    ],
  },
];

export const handleAnalytics = (name: string, sendAnalytic: (e: any) => void) => {
  switch (name) {
    case 'Organization': {
      sendAnalytic(ovAnalyticsEvents.dashboardOrganizationSelect);
      break;
    }
    case 'Basic': {
      sendAnalytic(ovAnalyticsEvents.dashboardBasicSelect);
      break;
    }
    case 'Portfolio Manager':
    case 'Portfolio Manager v2':
    case 'Portfolio Manager v3': {
      sendAnalytic(ovAnalyticsEvents.dashboardPortfolioManagerSelect);
      break;
    }
    case 'Compliance': {
      sendAnalytic(ovAnalyticsEvents.dashboardComplianceSelect);
      break;
    }
    case 'Operations': {
      sendAnalytic(ovAnalyticsEvents.dashboardOperationsSelect);
      break;
    }
    case 'menu.individuals': {
      sendAnalytic(ovAnalyticsEvents.clientManagementClientsSelect);
      break;
    }
    case 'menu.households': {
      sendAnalytic(ovAnalyticsEvents.clientManagementHouseholdSelect);
      break;
    }
    case 'menu.corporations': {
      // superseded by clientManagementNonIndividualClientsSelect (below), TODO: remove this once OV-8830 finished
      sendAnalytic(ovAnalyticsEvents.clientManagementCorporationsSelect);
      break;
    }
    case 'menu.nonIndividualClients': {
      sendAnalytic(ovAnalyticsEvents.clientManagementNonIndividualClientsSelect);
      break;
    }
    case 'menu.modelPortfolios': {
      sendAnalytic(ovAnalyticsEvents.portfolioManagementModelPortfoliosSelect);
      break;
    }
    case 'menu.productShelfPage': {
      sendAnalytic(ovAnalyticsEvents.portfolioManagementProductShelfSelect);
      break;
    }
    case 'menu.assetClassesPage': {
      sendAnalytic(ovAnalyticsEvents.portfolioManagementAssetClassesSelect);
      break;
    }
    case 'menu.tradingSchedules': {
      sendAnalytic(ovAnalyticsEvents.portfolioManagementTradingSchedulesSelect);
      break;
    }
    case 'menu.rebalancing': {
      sendAnalytic(ovAnalyticsEvents.tradingRebalancingSelect);
      break;
    }
    case 'menu.rebalanceReportV1': {
      sendAnalytic(ovAnalyticsEvents.tradingRebalanceReportV1Select);
      break;
    }
    case 'menu.rebalanceReport': {
      sendAnalytic(ovAnalyticsEvents.tradingRebalanceReportV1Select);
      break;
    }
    case 'menu.portfolioOptimizer': {
      sendAnalytic(ovAnalyticsEvents.tradingPortfolioOptimizerSelect);
      break;
    }
    case 'menu.bulkTrader': {
      sendAnalytic(ovAnalyticsEvents.tradingBulkTraderSelect);
      break;
    }
    case 'menu.bulkImport': {
      sendAnalytic(ovAnalyticsEvents.systemManagementBulkImportSelect);
      break;
    }
    case 'menu.taxLossHarvesting': {
      sendAnalytic(ovAnalyticsEvents.tradingTLHSelect);
      break;
    }
    case 'menu.accountsReview': {
      sendAnalytic(ovAnalyticsEvents.complianceAccountsReviewSelect);
      break;
    }
    case 'menu.complianceReview': {
      sendAnalytic(ovAnalyticsEvents.complianceComplianceReviewSelect);
      break;
    }
    case 'menu.transactionMonitoringTool': {
      sendAnalytic(ovAnalyticsEvents.complianceSuspiciousTransfersSelect);
      break;
    }
    case 'menu.transactions': {
      sendAnalytic(ovAnalyticsEvents.complianceAllTransactionsSelect);
      break;
    }
    case 'menu.elderlyClients': {
      sendAnalytic(ovAnalyticsEvents.complianceElderlyClientsSelect);
      break;
    }
    case 'menu.annualSuitabilityReview': {
      sendAnalytic(ovAnalyticsEvents.complianceAnnualProfileUpdateSelect);
      break;
    }
    case 'menu.bankAccountsToReview': {
      sendAnalytic(ovAnalyticsEvents.complianceNewBankAccountApprovalSelect);
      break;
    }
    case 'menu.institutionsToReview': {
      sendAnalytic(ovAnalyticsEvents.complianceInstitutionsApprovalSelect);
      break;
    }
    case 'menu.reports': {
      sendAnalytic(ovAnalyticsEvents.reportsSelect);
      break;
    }
    case 'menu.feeGrids': {
      sendAnalytic(ovAnalyticsEvents.feesAndBillingFeeGridSelect);
      break;
    }
    case 'menu.billingSchedules': {
      sendAnalytic(ovAnalyticsEvents.feesAndBillingBillingSchedulesSelect);
      break;
    }
    case 'menu.revenueShareReport': {
      sendAnalytic(ovAnalyticsEvents.feesAndBillingRevenueShareSelect);
      break;
    }
    case 'menu.billingManagement': {
      sendAnalytic(ovAnalyticsEvents.feesAndBillingIncurredFeeSelect);
      break;
    }
    case 'menu.reconciliation': {
      sendAnalytic(ovAnalyticsEvents.systemManagementReconciliationSelect);
      break;
    }
    case 'menu.statementsReview': {
      sendAnalytic(ovAnalyticsEvents.systemManagementStatementsReviewSelect);
      break;
    }
    case 'menu.devSettings': {
      sendAnalytic(ovAnalyticsEvents.systemManagementIntegrationSettingsSelect);
      break;
    }
    case 'menu.orgSettings': {
      sendAnalytic(ovAnalyticsEvents.systemManagementOrganizationSettingsSelect);
      break;
    }
    default: {
      break;
    }
  }
};
