/* eslint-disable no-param-reassign */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from '@apollo/client';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import { SelectFieldEditor } from 'ovComponents/4-module/configurableOptionFields/basicOrSelectField';
import {
  CUSTOM_FIELDS_FORMAT_MAP,
  CUSTOM_FIELDS_WORKFLOW_STEP_MAP,
  CustomField,
  CustomFieldConditionalRulesComparisonTypes,
  CustomFieldFormats,
  CustomFieldObjectTypes,
  CustomFieldTypes,
  CustomFieldWorkflowSteps,
} from '../../../../interfaces/customField';
import {
  Badge,
  Box,
  Button,
  ConfirmationModal,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  Form,
  IconButton,
  Menu,
  MenuItem,
  SelectField,
  Switch,
  TextField,
  Tooltip,
  TranslatableTextField,
} from '../../..';
import BuildCustomFieldRules from './buildCustomFieldRules';
import { FieldOptions, FormErrors, validateFields } from '../../../4-module/workflowCompletion/subSteps/utils';
import { TRANSITION_CUSTOM_FIELD, UPDATE_CUSTOM_FIELD } from './customFields.queries';
import { ObjectType, objectTypes } from './objectTypeMenuBuilder';

const EditCustomField = ({
  afterUpdate,
  customFieldToUpdate,
  open,
  handleClose,
  inherited,
}: {
  afterUpdate: () => void;
  customFieldToUpdate: CustomField;
  open: boolean;
  handleClose: () => void;
  inherited?: boolean;
}) => {
  const { t } = useTranslation(['orgSettings']);
  const [isTriggerRulesEnabled, setIsTriggerRulesEnabled] = useState(false);
  const [customField, setCustomField] = useState(customFieldToUpdate);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [localOpen, setLocalOpen] = useState(open);
  const [allOptions, setAllOptions] = useState([
    {
      displayText: {
        en: '',
        fr: '',
      },
      value: '',
    },
  ]);
  const [errors, setErrors] = useState<FormErrors>(null);
  const [focused, setFocused] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([
    {
      value: {
        selectField: {
          checked: true,
          options: customFieldToUpdate.selectOptions,
        },
      },
    },
  ]);

  const onArchiveCustomField = () => {
    setDeleteDialogOpen(true);
  };
  const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<null | HTMLElement>(null);
  const contextMenuOpen = Boolean(contextMenuAnchorEl);
  const openContextMenu = (onElement: HTMLElement) => {
    setContextMenuAnchorEl(onElement);
  };
  const closeContextMenu = () => setContextMenuAnchorEl(null);
  const menuItems = [
    <MenuItem key='menu1' onClick={onArchiveCustomField}>
      {t('shared:archive')}
    </MenuItem>,
  ];

  const [updateCustomField, { loading }] = useMutation(UPDATE_CUSTOM_FIELD, {
    variables: {
      input: {
        translatedName: {
          en: customField.translatedName?.en,
          fr: customField.translatedName?.fr,
        },
        translatedDescription: customField.translatedDescription?.en
          ? {
            en: customField.translatedDescription?.en,
            fr: customField.translatedDescription?.fr,
          }
          : null,
        customFieldId: customField.id,
        format: customField.format,
        selectOptions: [CustomFieldTypes.MULTIPLE_SELECT, CustomFieldTypes.SINGLE_SELECT].includes(customField.type as CustomFieldTypes)
          ? allOptions.map((opt: any) => ({
            displayText: {
              en: opt?.displayText?.en,
              fr: opt?.displayText?.fr,
            },
            value: opt.value,
          }))
          : undefined,
        workflowStep: customField.workflowStep ?? undefined,
        conditionalRules: customField.conditionalRules
          ? customField.conditionalRules.map((m: any) => {
            delete m.__typename;
            return m;
          })
          : undefined,
      },
    },
  });

  useEffect(() => {
    const val: any = selectedOptions[0].value;
    setAllOptions(val.selectField?.options ?? []);
  }, [selectedOptions]);

  useEffect(() => {
    setLocalOpen(open);
    setCustomField(customFieldToUpdate);
    const availableConditions = customFieldToUpdate.conditionalRules && customFieldToUpdate.conditionalRules.length > 0 ? true : false;
    setIsTriggerRulesEnabled(availableConditions);
    setSelectedOptions([
      {
        value: {
          selectField: {
            checked: true,
            options: customFieldToUpdate.selectOptions,
          },
        },
      },
    ]);
  }, [customFieldToUpdate, open]);

  const [transitionCustomField] = useMutation(TRANSITION_CUSTOM_FIELD, {
    variables: {
      input: {
        customFieldId: customFieldToUpdate?.id,
        transition: 'archive',
      },
    },
  });

  const update = async (event: any) => {
    const formErrors = validate();
    if (formErrors) {
      setFocused(Object.keys(formErrors));
      return;
    }
    event.preventDefault();
    await updateCustomField();
    setFocused([]);
    setErrors(null);
    afterUpdate();
  };
  const fieldOptions: FieldOptions = useMemo(
    () => ({
      translatedName: { required: true },
      format: { required: true },
      objectType: { required: true },
    }),
    [customField],
  );

  const validate = useCallback(
    (candidateFields?: string[]): FormErrors => {
      const newErrors = validateFields(fieldOptions, customField, candidateFields);
      setErrors(newErrors);
      return newErrors;
    },
    [customField, fieldOptions],
  );

  useEffect(() => {
    validate(focused);
  }, [validate, focused]);

  if (!customField) {
    return <></>;
  }
  return (
    <>
      <Dialog open={localOpen} title={t('customFieldModal.editTitle')} onClose={handleClose} maxWidth='sm' fullWidth>
        <Form>
          <DialogTitle onClose={(!menuItems || inherited) ? handleClose : undefined}>
            <Box display='flex' justifyContent='space-between' width='100%' alignItems='center'>
              {t('customFieldModal.editTitle')}
              {!inherited && menuItems && (
                <IconButton
                  size='small'
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    openContextMenu(event.currentTarget);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              )}
            </Box>
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              overflow: 'hidden',
            }}
          >
            {inherited && (
              <Tooltip title={t('shared:inheritedTooltip', { model: 'Custom Field', organization: customField.organization?.name })}>
                <Badge label={t('shared:inheritedFlag')} />
              </Tooltip>
            )}
            <Box>
              <TranslatableTextField
                fullWidth
                required={true}
                onChange={(value) => {
                  const translatedNameVal = !value.en && !value.fr;
                  setCustomField({ ...customField, translatedName: translatedNameVal ? undefined : value });
                }}
                value={customField.translatedName || {}}
                label={t('customFieldModal.translatedName')}
                rows={3}
                error={!!errors?.translatedName}
                errorText={errors?.translatedName?.message}
                onBlur={() => {
                  setFocused([...focused, 'translatedName']);
                }}
              />
            </Box>
            <Box>
              <TranslatableTextField
                fullWidth
                onChange={(value) => {
                  setCustomField({ ...customField, translatedDescription: value });
                }}
                value={customField.translatedDescription || {}}
                label={t('customFieldModal.translatedDescription')}
                rows={4}
              />
            </Box>
            <Box>
              <TextField label={t('customFieldModal.type')} fullWidth value={t(`customFieldModal.types.${customField.type}`)} disabled />
            </Box>
            <Box>
              <SelectField
                fullWidth
                data-testid='formatField'
                value={customField.format}
                label={t('customFieldModal.format')}
                onChange={(e: any) => {
                  setCustomField({ ...customField, format: e.target.value as CustomFieldFormats });
                  setFocused([...focused, 'format']);
                }}
                error={!!errors?.format}
                errorText={errors?.format?.message}
                required={true}
              >
                {CUSTOM_FIELDS_FORMAT_MAP[customField.type as CustomFieldTypes].map((field: string) => (
                  <MenuItem value={field} key={field}>
                    {t(`customFieldModal.formats.${field}`)}
                  </MenuItem>
                ))}
              </SelectField>
            </Box>
            {[CustomFieldTypes.MULTIPLE_SELECT, CustomFieldTypes.SINGLE_SELECT].includes(customField.type as CustomFieldTypes) && (
              <>
                <Box>
                  <SelectFieldEditor
                    option={selectedOptions[0]}
                    key={1}
                    options={selectedOptions}
                    setOptions={setSelectedOptions}
                    i={0}
                    enableTranslation
                    displayTextLabel={t('customFieldModal.displayText')}
                  />
                </Box>
              </>
            )}

            <Box>
              <SelectField
                fullWidth
                data-testid='objectTypeField'
                value={customField.objectType}
                disabled
                label={t('customFieldModal.objectType')}
                error={!!errors?.objectType}
                errorText={errors?.objectType?.message}
                onChange={(e: any) => {
                  setCustomField({ ...customField, objectType: e.target.value as CustomFieldObjectTypes });
                  setFocused([...focused, 'objectType']);
                }}
                required={true}
              >
                {objectTypes.map((objectType: ObjectType) => (
                  <MenuItem value={objectType.value} key={objectType.key}>
                    {objectType.label}
                  </MenuItem>
                ))}
              </SelectField>
            </Box>

            <Box>
              <TextField
                fullWidth
                select
                data-testid='workflowStepField'
                value={customField.workflowStep}
                label={t('customFieldModal.workflowStep')}
                onChange={(e: any) => setCustomField({ ...customField, workflowStep: e.target.value as CustomFieldWorkflowSteps })}
              >
                {CUSTOM_FIELDS_WORKFLOW_STEP_MAP[customField.objectType as CustomFieldObjectTypes].map((workflow: string) => (
                  <MenuItem value={workflow} key={workflow}>
                    {t(`customFieldModal.workflowOptions.${workflow}`)}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box>
              <Switch
                key='isTriggerRulesEnabled'
                checked={isTriggerRulesEnabled}
                onChange={(e: any) => {
                  if (!e) {
                    setCustomField({ ...customField, conditionalRules: [] });
                  } else {
                    const initialRule = { field: '', comparison: CustomFieldConditionalRulesComparisonTypes.EQUALS, value: '' };
                    setCustomField({ ...customField, conditionalRules: [initialRule] });
                  }
                }}
                label={t('customFieldModal.enableConditionalRules')}
              />
            </Box>

            {customField.conditionalRules && customField.conditionalRules.length > 0 && (
              <Box>
                <BuildCustomFieldRules customField={customField} setCustomField={setCustomField} />
              </Box>
            )}
          </DialogContent>
          <DialogFooter>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant='tonal'
                label={t('shared:cancel')}
                onClick={() => setLocalOpen(false)}
                sx={{ mr: 1 }}
              />
              <Button
                disabled={inherited || loading}
                label={t('shared:update')}
                onClick={update}
              />
            </Box>
          </DialogFooter>
          {!inherited && menuItems && (
            <Menu
              anchorEl={contextMenuAnchorEl}
              open={contextMenuOpen}
              onClose={closeContextMenu}
              onClick={closeContextMenu}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              sx={{ cursor: 'pointer' }}
            >
              {menuItems}
            </Menu>
          )}
        </Form>
      </Dialog>
      {deleteDialogOpen && (
        <ConfirmationModal
          title={t('editCustomFieldDialog.title')}
          bodyText={t('editCustomFieldDialog.body')}
          open={deleteDialogOpen}
          maxWidth='sm'
          onConfirm={async () => {
            await transitionCustomField().then(() => {
              setDeleteDialogOpen(false);
            });
            afterUpdate();
          }}
          onCancel={() => {
            setDeleteDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export default EditCustomField;
