import { gql } from '@apollo/client';

export const FETCH_USERS = gql`
  query fetchUsers($input: FetchUsersInput!) {
    fetchUsers(input: $input) {
      users {
        id
        firstName
        lastName
        primaryEmail
      }
    }
  }
`;
export const PREVIEW_FORM_AGREEMENT = gql`
  query previewFormAgreement($input: PreviewFormAgreementInput!) {
    previewFormAgreement(input: $input) {
      html
    }
  }
`;
export const FETCH_ACCOUNTS = gql`
  query fetchAccounts($input: FetchAccountsInput!) {
    fetchAccounts(input: $input) {
      totalCount
      accounts { id type }
    }
  }
`;

export const FETCH_SUB_ACCOUNTS = gql`
  query fetchSubAccounts($input: FetchSubAccountsInput!) {
    fetchSubAccounts(input: $input) {
      subAccounts {
        id
        account {
          type
        }
      }
    }
  }
`;
export const FETCH_GOALS = gql`
  query fetchGoals($input: FetchGoalsInput!) {
    fetchGoals(input: $input) {
      goals {
        id
        name
        type
      }
    }
  }
`;
export const FETCH_SCHEDULED_TRANSFERS = gql`
  query fetchScheduledTransfers($input: FetchScheduledTransfersInput!) {
    fetchScheduledTransfers(input: $input) {
      scheduledTransfers {
        id
        amountCents
        type
        frequency
      }
    }
  }
`;
export const FETCH_CLIENT_GROUPS = gql`
  query fetchClientGroups($input: FetchClientGroupsInput!) {
    fetchClientGroups(input: $input) {
      clientGroups {
        id
        name
        type
      }
    }
  }
`;
