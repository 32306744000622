import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { formatMoneyValue } from '../../../../util';
import { Box, Typography } from '../../../1-primative';
import { FETCH_ACCOUNT_CONTRIBUTIONS } from './accountHighlights.queries';
import { TransactionTypes } from '../../../../interfaces/transaction';
import { Section } from './accountHighlights';

export const AccountHighlightsContribution = ({
  section, accountId, useCustodianData,
}: { section: Section, accountId: string, useCustodianData?: boolean }) => {
  const today = dayjs().format('YYYY-MM-DD');
  const firstDayOfYear = dayjs().startOf('year').format('YYYY-MM-DD');
  const lastFebDay = dayjs().month(1).endOf('month').format('YYYY-MM-DD');
  const dateDiff = dayjs(today).diff(dayjs(lastFebDay));
  const firstMarDay = dayjs().month(2).startOf('month').format('YYYY-MM-DD');
  const firstDayOfPriorYear = dayjs().startOf('year').subtract(1, 'year').format('YYYY-MM-DD');
  const lastDayOfPriorYear = dayjs().endOf('year').endOf('day').subtract(1, 'year')
    .format('YYYY-MM-DD');

  const [contributions, setContributions] = useState(0);
  const [startDate, setStartDate] = useState<string | undefined>(firstDayOfYear);
  const [endDate, setEndDate] = useState<string | undefined>(today);
  const [types, setTypes] = useState<TransactionTypes[] | undefined>();
  const [useFiscalDate, setUseFiscalDate] = useState(false);

  const { loading, data } = useQuery(FETCH_ACCOUNT_CONTRIBUTIONS(useCustodianData), {
    variables: {
      accountId,
      startDate,
      endDate,
      types,
      useFiscalDate,
    },
  });

  const [fetchYearGovContributions, { data: yearGovContributionsData }] = useLazyQuery(FETCH_ACCOUNT_CONTRIBUTIONS(useCustodianData), {
    variables: {
      accountId,
      startDate,
      endDate,
      types: [TransactionTypes.GOV_CONTRIBUTIONS],
    },
  });

  const setContributionsVariables = useCallback(() => {
    switch (section.key) {
      case 'rrsp60DaysContributions':
        setStartDate(firstDayOfYear);
        setTypes(undefined);
        setUseFiscalDate(false);
        if (dateDiff > 0) {
          setEndDate(lastFebDay);
        } else {
          setEndDate(today);
        }
        break;
      case 'rrspLast10MonthsContributions':
        setStartDate(firstMarDay);
        setEndDate(today);
        setTypes(undefined);
        setUseFiscalDate(false);
        break;
      case 'respGovContributions':
        setStartDate(undefined);
        setEndDate(undefined);
        setTypes([TransactionTypes.GOV_CONTRIBUTIONS]);
        setUseFiscalDate(false);
        break;
      case 'iraCurrentYearContributions':
        setStartDate(firstDayOfYear);
        setEndDate(today);
        setTypes(undefined);
        setUseFiscalDate(true);
        break;
      case 'iraPriorYearContributions':
        setStartDate(firstDayOfPriorYear);
        setEndDate(lastDayOfPriorYear);
        setTypes(undefined);
        setUseFiscalDate(true);
        break;
      case 'priorYearWithdrawals':
        setStartDate(firstDayOfPriorYear);
        setEndDate(lastDayOfPriorYear);
        setTypes(undefined);
        setUseFiscalDate(false);
        break;
      default:
        setStartDate(firstDayOfYear);
        setEndDate(today);
        setUseFiscalDate(false);
        setTypes(undefined);
        break;
    }
  }, [section.key, firstDayOfYear, today, lastFebDay, firstMarDay, dateDiff, firstDayOfPriorYear, lastDayOfPriorYear]);

  const setContributionsData = useCallback(() => {
    if (data?.fetchAccount?.account) {
      const statistics = useCustodianData ? data.fetchAccount.account?.custodianStatistics : data.fetchAccount.account?.statistics;
      const govContribution = useCustodianData ? 0 : yearGovContributionsData?.fetchAccount?.account?.statistics?.depositContributionCents ?? 0;

      let contribution = 0;
      switch (section.key) {
        case 'rrsp60DaysContributions':
        case 'rrspLast10MonthsContributions':
        case 'respGovContributions':
        case 'defaultContributions':
        case 'iraCurrentYearContributions':
        case 'iraPriorYearContributions':
          contribution = statistics?.depositContributionCents ?? 0;
          break;
        case 'respContributions':
          contribution = (statistics?.depositContributionCents ?? 0) - govContribution;
          break;
        case 'defaultWithdrawals':
        case 'priorYearWithdrawals':
          contribution = statistics?.withdrawContributionCents ?? 0;
          break;
        case 'minWithdrawal':
          contribution = statistics?.minWithdrawalCents ?? 0;
          break;
        case 'maxWithdrawal':
          contribution = statistics?.maxWithdrawalCents ?? 0;
          break;
        default:
          contribution = 0;
          break;
      }
      setContributions(contribution);
    }
  }, [data, useCustodianData, yearGovContributionsData, section.key]);

  useEffect(() => {
    if (section.key === 'respContributions' && !useCustodianData) fetchYearGovContributions();
  }, [section.key, useCustodianData, fetchYearGovContributions]);

  useEffect(() => {
    setContributionsVariables();
    if (data?.fetchAccount?.account) setContributionsData();
  }, [setContributionsVariables, data, setContributionsData]);

  return (
    <Box
      display='flex'
      justifyContent='space-between'
    >
      <Typography variant='bodyMedium' colorVariant='variant' sx={{ mr: 2 }}>{section.label}</Typography>
      <Typography variant='bodyMedium' weight='bold'>{loading ? '-' : formatMoneyValue(contributions)}</Typography>
    </Box>
  );
};
