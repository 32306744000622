import { useContext } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import {
  CircularProgress, Box, ThemeProvider, createTheme,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Auth0Provider from 'providers/auth0Provider';
import { useThemeOptions } from './theme';
import UserContextProvider, { UserContext } from './providers/userContextProvider';
import OvApolloProvider from './providers/ovApolloProvider';
import { ToastContextProvider } from './providers/globalToastProvider';
import { AppRouter } from './components/layout/appRouter';
import ThemeTokenContextProvider, { useThemeTokens } from './providers/themeTokenProvider';
import { AnalyticsProvider } from './providers/analyticsProvider';
import { AuthProvider } from './providers/AuthProvider';

const InternalApp = () => {
  const { loading, userContext } = useContext(UserContext);
  const { sys } = useThemeTokens();
  const theme = createTheme(useThemeOptions());

  if (process.env.REACT_APP_ENV) {
    datadogRum.setUser({
      id: userContext.id,
      organization: userContext.organization?.name,
      role: userContext.role?.translatedName?.en,
      navigationStyle: userContext.role?.navigationStyle,
    });
  }

  return (
    <Box sx={{ backgroundColor: sys.color.background, height: '100vh' }}>
      {loading ? (
        <Box sx={{
          position: 'absolute', top: 0, left: 0, width: '100%', height: '100vh',
        }}>
          <CircularProgress sx={{
            position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
          }} />
        </Box>
      ) : (
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>

      )}
    </Box>
  );
};

function App() {
  if (process.env.REACT_APP_ENV) {
    datadogLogs.init({
      clientToken: process.env.REACT_APP_DATADOG_LOG_CLIENT_TOKEN || '',
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      service: 'onevest-app',
      env: process.env.REACT_APP_ENV,
      version: process.env.REACT_APP_DD_RUM_VERSION || '',
    });

    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID || '',
      clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN || '',
      site: 'datadoghq.com',
      service: 'onevest-app',
      version: process.env.REACT_APP_DD_RUM_VERSION || '',
      env: process.env.REACT_APP_ENV,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
    });
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ToastContextProvider>
        <OvApolloProvider>
          <AuthProvider>
            <Auth0Provider>
              <UserContextProvider>
                <AnalyticsProvider>
                  <ThemeTokenContextProvider>
                    <InternalApp />
                  </ThemeTokenContextProvider>
                </AnalyticsProvider>
              </UserContextProvider>
            </Auth0Provider>
          </AuthProvider>
        </OvApolloProvider>
      </ToastContextProvider>
    </LocalizationProvider>
  );
}

export default App;
