import { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useStats, ObjectType } from '../../../providers/statsHooks';
import Holdings from '../../../pages/client/components/stats/holdings';

const CollapseHoldings = ({
  type, id,
  isExpanded = false, removeTarget = false,
}: { type: ObjectType, id: string,
  isExpanded?: boolean, removeTarget?: boolean,
}) => {
  const { t } = useTranslation('client');
  const [expanded, setExpanded] = useState(isExpanded);
  const { statistics } = useStats(
    { type, id },
  );

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} sx={{
      boxShadow: '0px -4px 25px rgba(0, 0, 0, 0.05)',
      borderRadius: '4px',
    }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id="panel1a-header"
        sx={{ '.MuiAccordionSummary-content': { justifyContent: 'space-between !important' } }}
      >
        <Typography fontWeight={600} fontSize="20px">{t('assetsOverview.holdings')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {statistics?.holdings && (
          <Holdings
            holdings={statistics.holdings} id={id}
            type={type} removeTarget={removeTarget}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default CollapseHoldings;
