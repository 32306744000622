/* keep this synchronized with account-service */
export enum AffiliationTypes {
  AuthorizedIndividual = 'AUTHORIZED_INDIVIDUAL',
  BeneficialOwner = 'BENEFICIAL_OWNER',
  ContingentBeneficiary = 'CONTINGENT_BENEFICIARY',
  Contributor = 'CONTRIBUTOR',
  Director = 'DIRECTOR',
  Grantor = 'GRANTOR',
  Joint = 'JOINT',
  Other = 'OTHER',
  PowerOfAttorney = 'POWER_OF_ATTORNEY',
  PrimaryBeneficiary = 'PRIMARY_BENEFICIARY',
  Protector = 'PROTECTOR',
  Settlor = 'SETTLOR',
  Successor = 'SUCCESSOR',
  ThirdParty = 'THIRD_PARTY',
  Trustee = 'TRUSTEE',
  Decedent = 'DECEDENT',
}
