import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination, Grid,
  Tooltip,
  IconButton,
  TextField,
  Button,
  Chip,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import TuneIcon from '@mui/icons-material/Tune';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { BillingCycle, BillingCycleStates, stateColor } from '../../../interfaces/billingCycle';
import { colors } from '../../../theme/colors';
import { UserContext, usePermissions } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import { GroupedBilling } from '../../../interfaces/groupedBilling';
import { FeeReportRow, FeeReportDataRow } from './feeReportRow';
import OrganizationSelect from '../../../components/inputs/organizationSelect';
import FeeGridSelect from '../../../components/inputs/feeGridSelect';
import BillingScheduleSelect from '../../../components/inputs/billingScheduleSelect';
import ConfirmationModal from '../../../components/modals/confirmationModal';
import { stickyColumn } from '../../../util/styles';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { LocalizedDatePicker } from '../../../components/fields/localizedDatePicker';
import DownloadBillingCycle from './downloadBillingCycle';

dayjs.extend(utc);

const coloumStyle = { ...stickyColumn, borderRight: 0, boxShadow: `inset -2px 0px 0 ${colors.black}` };

const SUB_ACCOUNT_BILLING_RESPONSE = `
  id
  billingCycle { id }
  subAccount {
    id
    statistics { moneyAvailableCents }
    account {
      id
      type
      custodianAccountNumber
    }
    goal { id type }
    financialProduct { id translatedName { en } }
  }
  feeCents
  salesTaxCents
  adjustedFeeCents
  adjustedSalesTaxCents
  projectedFeeAndTaxCents
  feePaymentAccount {
    id
    type
    custodianAccountNumber
    statistics { moneyAvailableCents }
  }
  marketValueCentsOnLastDay
  chargeableMarketValueCentsOnLastDay
  feeTier { id name }
  billingSchedule { id frequency }
  billingDate
  startDate
  endDate
`;

export const TRANSITION_BILLING_CYCLE = gql`
  mutation transitionBillingCycle($input: TransitionBillingCycleInput!) {
    transitionBillingCycle(input: $input) {
      billingCycle {
        id
      }
    }
  }
`;

export const FETCH_GROUPED_BILLINGS = gql`
  query fetchGroupedBillings($input: FetchGroupedBillingsInput!) {
    fetchGroupedBillings(input: $input) {
      totalCount
      groupedBillings {
        id
        clientGroup { id type name organization { id name } }
        user { id firstName lastName organization { id name } }
        accounts {
          account { id type }
          subAccountBillings {
            ${SUB_ACCOUNT_BILLING_RESPONSE}
          }
        }
        users {
          user { id firstName lastName }
          accounts {
            account { id type }
            subAccountBillings {
              ${SUB_ACCOUNT_BILLING_RESPONSE}
            }
          }
        }
      }
    }
  }
`;

export const FETCH_BILLING_CYCLE = gql`
  query fetchBillingCycle ($billingCycleId: ObjectID!) {
    fetchBillingCycle (billingCycleId: $billingCycleId) {
      billingCycle { id state endDate organization { id } }
    }
  }
`;

const FeeReportTable = () => {
  const { permissions } = usePermissions();
  const navigate = useNavigate();
  const { activeOrganization } = useContext(UserContext);
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['feeAndBilling', 'shared', 'components']);
  const { billingCycleId } = useParams();
  const [userId, setUserId] = usePageState('', 'userId');
  const [organizationId, setOrganizationId] = usePageState(activeOrganization.id ?? '', 'org');
  const [billingScheduleId, setBillingScheduleId] = usePageState('', 'billingSchedule');
  const [feeTierId, setFeeTierId] = usePageState('', 'feeTier');
  const [dateAfter, setDateAfter] = usePageState('', 'dateAfter');
  const [dateBefore, setDateBefore] = usePageState('', 'dateBefore');
  const [page, setPage] = usePageState(1, 'page');
  const [billingCycleInfo, setBillingCycleInfo] = useState<BillingCycle | null>(null);
  const [approveConfirmationOpen, setApproveConfirmationOpen] = useState(false);
  const [transitionBillingCycle] = useMutation(TRANSITION_BILLING_CYCLE);

  useEffect(() => {
    if (activeOrganization.id) {
      setOrganizationId(activeOrganization.id);
      setFeeTierId('');
      setBillingScheduleId('');
      setUserId('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization.id]);

  const pageSize = 15;
  const {
    loading, error, data, previousData,
  } = useQuery(FETCH_GROUPED_BILLINGS, {
    variables: {
      input: {
        filter: {
          organizationId: organizationId || activeOrganization.id,
          feeTierIds: feeTierId || undefined,
          billingScheduleIds: billingScheduleId || undefined,
          userIds: userId || undefined,
          billingDateAfter: dateAfter || undefined,
          billingDateBefore: dateBefore || undefined,
          billingCycleIds: billingCycleId || undefined,
        },
        pagination: { perPage: pageSize, offSet: (page - 1) * pageSize },
      },
    },
  });

  const { loading: billingCycleLoading, refetch } = useQuery(FETCH_BILLING_CYCLE, {
    variables: { billingCycleId },
    onCompleted: (res: any) => {
      setBillingCycleInfo(res.fetchBillingCycle?.billingCycle);
    },
    skip: !billingCycleId,
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <Box sx={{ overflowX: 'scroll' }}>
      {(loading || billingCycleLoading) ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Grid container sx={{ p: 2 }} spacing={1}>
            {!billingCycleId && (
              <Grid item xs={2.5}>
                <OrganizationSelect
                  label={t('feeAndBilling:feeReport.table.organization')}
                  onChange={(event) => {
                    setOrganizationId(event.target.value);
                    setFeeTierId('');
                    setBillingScheduleId('');
                    setUserId('');
                  }}
                  value={organizationId}
                  childrenFor={activeOrganization.id}
                  size='small'
                />
              </Grid>
            )}
            <Grid item xs={2}>
              <FeeGridSelect
                label={t('feeAndBilling:feeReport.table.feeGrid')}
                value={feeTierId}
                setValue={(newValue) => setFeeTierId(newValue)}
                organizationId={billingCycleInfo?.organization?.id || organizationId}
                size='small'
                includeAnyOption
              />
            </Grid>
            {!billingCycleId && (<>
              <Grid item xs={2}>
                <BillingScheduleSelect
                  label={t('feeAndBilling:feeReport.table.billingSchedule')}
                  setValue={(newValue) => setBillingScheduleId(newValue)}
                  value={billingScheduleId}
                  organizationId={organizationId}
                  size='small'
                  includeAnyOption
                />
              </Grid>
              <Grid item xs={2}>
                <LocalizedDatePicker
                  label={t('feeAndBilling:feeReport.filters.billingDateFrom')}
                  value={dateAfter || null}
                  onChange={(date) => setDateAfter(dayjs(date).format('YYYY-MM-DD'))}
                  renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                  InputProps={{ endAdornment: dateAfter && (<IconButton onClick={() => setDateAfter('')}><ClearIcon /></IconButton>) }}
                />
              </Grid>
              <Grid item xs={2}>
                <LocalizedDatePicker
                  label={t('feeAndBilling:feeReport.filters.billingDateTo')}
                  value={(dateBefore || null)}
                  onChange={(date) => setDateBefore(dayjs(date).format('YYYY-MM-DD'))}
                  renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                  InputProps={{ endAdornment: dateBefore && (<IconButton onClick={() => setDateBefore('')}><ClearIcon /></IconButton>) }}
                />
              </Grid>
            </>)}
            <Grid item xs={0.5} display={'flex'}>
              <Tooltip title={t('feeAndBilling:feeGrid.clearFilter')} placement="right">
                <IconButton sx={{ cursor: 'pointer' }} onClick={() => {
                  setUserId('');
                  setOrganizationId(activeOrganization.id ?? '');
                  setFeeTierId('');
                  setBillingScheduleId('');
                  setDateAfter('');
                  setDateBefore('');
                  setPage(1);
                }} >
                  <TuneIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            {billingCycleId && (<>
              <Grid item display={'flex'}>
                <Chip
                  color={stateColor(billingCycleInfo?.state)}
                  variant='filled'
                  label={(t(`feeAndBilling:billingCycle.${billingCycleInfo?.state}`)).toUpperCase()}
                />
              </Grid>
              {billingCycleInfo?.state === BillingCycleStates.AWAITING_REVIEW && (
                <Grid item display={'end'}>
                  <Button
                    type='submit'
                    sx={{ marginLeft: 1 }}
                    variant='contained'
                    size='small'
                    value={billingCycleId}
                    onClick={() => setApproveConfirmationOpen(true)}
                  >
                    {t('Approve')}
                  </Button>
                </Grid>
              )}
            </>)}

            {permissions.includes('read:api_exports') && billingCycleId && (
              <Grid item xs={1}>
                <DownloadBillingCycle filter={{ billingCycleIds: billingCycleId, organizationId }} queryFilter={{ feeTierIds: feeTierId }} />
              </Grid>
            )}
          </Grid>
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell sx={coloumStyle}><Typography variant='overline'>{t('feeAndBilling:feeReport.table.client')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('feeAndBilling:feeReport.table.organization')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('feeAndBilling:feeReport.table.billingSchedule')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('feeAndBilling:feeReport.table.billingDate')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('feeAndBilling:feeReport.table.feeGrid')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('feeAndBilling:feeReport.table.from')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('feeAndBilling:feeReport.table.to')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right"><Typography variant='overline'>{t('feeAndBilling:feeReport.table.billableAumLastDay')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">
                  <Typography variant='overline'>{t('feeAndBilling:feeReport.table.billableAumWithReductionsApplied')}</Typography>
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right"><Typography variant='overline'>{t('feeAndBilling:feeReport.table.accuredFees')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right"><Typography variant='overline'>{t('feeAndBilling:feeReport.table.salesTax')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right"><Typography variant='overline'>{t('feeAndBilling:feeReport.table.totalFeesAndTax')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right"><Typography variant='overline'>{t('feeAndBilling:feeReport.table.projectedFeeAndTax')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right"><Typography variant='overline'>{t('feeAndBilling:feeReport.table.feePaymentAccount')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right"><Typography variant='overline'>{t('feeAndBilling:feeReport.table.cashAvailable')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.fetchGroupedBillings?.groupedBillings?.map((groupedBilling: GroupedBilling) => (
                <FeeReportRow
                  groupedBilling={groupedBilling}
                  key={groupedBilling.id}
                  hover
                  onSelectFeeRow={(row: FeeReportDataRow) => {
                    if (row.objectType === 'SUB_ACCOUNT') {
                      navigate(`/billingManagement/${billingCycleId}/subAccount/${row.objectId}`);
                    }
                  }}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
                />
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchGroupedBillings?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
        </>
      )}
      <ConfirmationModal
        open={approveConfirmationOpen}
        onCancel={() => setApproveConfirmationOpen(false)}
        onConfirm={async () => {
          await transitionBillingCycle({
            variables: {
              input: { billingCycleId, transition: 'approve' },
            },
          });
          setApproveConfirmationOpen(false);
          showToast({ severity: 'info', message: t('feeAndBilling:feeReport.approveBillingCycleConfirmationDialog.toastMessage') });
          refetch();
        }}
        title={t('feeAndBilling:feeReport.approveBillingCycleConfirmationDialog.title')}
        bodyText={t('feeAndBilling:feeReport.approveBillingCycleConfirmationDialog.text')}
      />
    </Box>
  );
};

export default FeeReportTable;
