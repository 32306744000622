import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { AddTwoTone } from '@mui/icons-material';
import { Box, Link, Typography } from '../../../../../1-primative';
import { CompletionBox } from '../../../../../3-pattern/completionBox/completionBox';
import { useThemeTokens } from '../../../../../../providers/themeTokenProvider';
import { AffiliateModal, UPDATE_AFFILIATIONS } from './affiliateModal';
import { RelationType } from '../accountConfig';
import { Button, EmptyState, TextField } from '../../../../../2-component';
import { UserContext } from '../../../../../../providers/userContextProvider';

export enum AffiliateType {
  AUTHORIZED_INDIVIDUAL = 'AUTHORIZED_INDIVIDUAL',
  BENEFICIAL_OWNER = 'BENEFICIAL_OWNER',
  CONTINGENT_BENEFICIARY = 'CONTINGENT_BENEFICIARY',
  CONTRIBUTOR = 'CONTRIBUTOR',
  DIRECTOR = 'DIRECTOR',
  GRANTOR = 'GRANTOR',
  JOINT = 'JOINT',
  OTHER = 'OTHER',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
  PRIMARY_BENEFICIARY = 'PRIMARY_BENEFICIARY',
  PROTECTOR = 'PROTECTOR',
  SETTLOR = 'SETTLOR',
  SUCCESSOR = 'SUCCESSOR',
  THIRD_PARTY = 'THIRD_PARTY',
  TRUSTEE = 'TRUSTEE',
  DECEDENT = 'DECEDENT',
}

export const Affiliate = ({
  type,
  account,
  affiliates = [],
  multi = false,
  showAllocation = false,
  showRelationship = false,
  showMissingAffiliate = true,
  fields = [],
  defaultRelationType,
  refetch,
  updateAccount,
  allAffiliates,
  onEdit,
  updateMode = false,
  edittable = true,
  title,
  useAccountHoldersAddress,
  error,
  errorText,
  supportText,
}: {
  type: AffiliateType;
  account: any;
  affiliates: any[];
  multi: boolean;
  showAllocation?: boolean;
  showRelationship?: boolean;
  showMissingAffiliate?: boolean;
  fields?: any[];
  defaultRelationType?: RelationType;
  refetch?: any;
  updateAccount?: any;
  allAffiliates: any[];
  onEdit: () => void;
  updateMode?: boolean;
  edittable?: boolean;
  title?: string;
  useAccountHoldersAddress?: boolean;
  error?: boolean;
  errorText?: string,
  supportText?: string,
}) => {
  const { userContext } = useContext(UserContext);
  const [open, setOpen] = useState<boolean>(false);
  const [action, setAction] = useState<'create' | 'edit'>('create');
  const [activeAffiliate, setActiveAffiliate] = useState<any>(null);
  const { t } = useTranslation(['affiliationTypes', 'client']);
  const { sys } = useThemeTokens();
  const affiliateName = (affiliate: any) => `${affiliate.user.firstName} ${affiliate.user.lastName}`;

  const [updateAffiliations] = useMutation(UPDATE_AFFILIATIONS);

  const removeAffiliate = (affiliateId: string) => {
    const newAff = allAffiliates.filter((item: any) => item.id !== affiliateId);

    updateAffiliations({
      variables: {
        input: {
          accountId: account.accountId,
          affiliations: newAff.map((item: any) => ({
            allocation: item.type === type ? Math.floor(100 / ((allAffiliates.filter((x: any) => x.type === item.type).length || 0) - 1)) : item.allocation,
            relation: item.relation,
            type: item.type,
            userId: item.user.id,
          })),
        },
      },
      onCompleted: () => {
        refetch && refetch();
      },
    });
  };

  if (affiliates.length === 0 && !edittable) {
    if (showMissingAffiliate) {
      return (
        <EmptyState>
          <Typography variant='bodyLarge'>{t('noLinkedAffiliate', { type: t(type === 'OTHER' && defaultRelationType ? defaultRelationType : type) })}</Typography>
        </EmptyState>
      );
    }
    return <></>;
  }

  return (
    <>
      <CompletionBox
        testId={`affiliate-completion-box-${type}`}
        variant={updateMode ? 'view' : 'edit'}
        state={affiliates[0] ? 'complete' : 'todo'}
        edittable={affiliates[0] ? edittable : true}
        title={
          affiliates[0]
            ? title ?? t(type === 'OTHER' && defaultRelationType ? defaultRelationType : type)
            : title ?? t(`button.${type === 'OTHER' && defaultRelationType ? defaultRelationType : type}`)
        }
        onEdit={() => {
          setAction('edit');
          setActiveAffiliate(affiliates[0]);
          setOpen(true);
        }}
        onRemove={() => removeAffiliate(affiliates[0].id)}
        onAdd={() => {
          setAction('create');
          setActiveAffiliate(affiliates[0]);
          setOpen(true);
        }}
        error={error}
        errorText={errorText}
      >
        {affiliates[0] ? (
          <>
            <Typography variant='bodyMedium'>
              <span style={{ color: sys.color.onSurfaceVariant }}>{t('name')}:</span> <b>{affiliateName(affiliates[0])}</b>
            </Typography>
            {showRelationship && (
              <Typography variant='bodyMedium'>
                <span style={{ color: sys.color.onSurfaceVariant }}>{t('relationship')}:</span> <b>{t(`affiliationRelationship.${affiliates[0].relation}`)}</b>
              </Typography>
            )}
            {showAllocation && edittable && (
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                sx={{
                  background: sys.color.surfaceContainerVariant,
                  padding: '4px 8px 8px 12px',
                  ml: -1.5,
                  mt: 0.5,
                  borderRadius: sys.borderRadius.md,
                }}
              >
                <Typography variant='bodyMedium'>{t('allocation')}</Typography>
                <TextField
                  data-testid='affiliate-allocation'
                  type='number'
                  trailingIcon='percent'
                  value={affiliates[0].allocation}
                  size='small'
                  sx={{ width: '100px' }}
                  onChange={(e: any) => {
                    const newAff = allAffiliates.map((item: any) => {
                      if (item.id === affiliates[0].id) {
                        return { ...item, allocation: parseInt(e.target.value, 10) };
                      }
                      return item;
                    });
                    onEdit();
                    updateAccount({ ...account, affiliations: newAff });
                  }}
                  label={''}
                />
              </Box>
            )}
            {showAllocation && !edittable && (
              <Typography variant='bodyMedium'>
                <span style={{ color: sys.color.onSurfaceVariant }}>{t('allocation')}:</span> <b>{affiliates[0].allocation}%</b>
              </Typography>
            )}
            {!edittable && supportText !== '' && (
              <Typography mt={1} variant='bodyMedium'>
                <Link target='_blank' href={userContext.organization?.supportUrl || 'https://onevest.zendesk.com/hc/en-us/requests/new'}>
                  {supportText ?? t('contactSupport')}
                </Link>
              </Typography>
            )}
          </>
        ) : (
          <Typography variant='bodyMedium'>{t(`description.${account.type}.${type === 'OTHER' && defaultRelationType ? defaultRelationType : type}`)}</Typography>
        )}
      </CompletionBox>
      {affiliates.length > 1
        && affiliates.slice(1).map((affiliate: any) => (
          <CompletionBox
            variant={updateMode ? 'view' : 'edit'}
            state={'complete'}
            edittable={edittable}
            title={t(type)}
            onEdit={() => {
              setAction('edit');
              setActiveAffiliate(affiliate);
              setOpen(true);
            }}
            onRemove={() => removeAffiliate(affiliate.id)}
            onAdd={() => {
              setActiveAffiliate(affiliate);
              setOpen(true);
            }}
          >
            <>
              <Typography variant='bodyMedium'>
                <span style={{ color: sys.color.onSurfaceVariant }}>{t('name')}:</span> <b>{affiliateName(affiliate)}</b>
              </Typography>
              {showRelationship && (
                <Typography variant='bodyMedium'>
                  <span style={{ color: sys.color.onSurfaceVariant }}>{t('relationship')}:</span> <b>{t(`affiliationRelationship.${affiliate.relation}`)}</b>
                </Typography>
              )}
              {showAllocation && edittable && (
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  sx={{
                    background: sys.color.surfaceContainerVariant,
                    padding: '4px 8px 8px 12px',
                    ml: -1.5,
                    mt: 0.5,
                    borderRadius: sys.borderRadius.md,
                  }}
                >
                  <Typography variant='bodyMedium'>{t('allocation')}</Typography>
                  <TextField
                    data-testid='affiliate-allocation'
                    type='number'
                    trailingIcon='percent'
                    value={affiliate.allocation}
                    size='small'
                    sx={{ width: '100px' }}
                    onChange={(e: any) => {
                      const newAff = allAffiliates.map((item: any) => {
                        if (item.id === affiliate.id) {
                          return { ...item, allocation: parseInt(e.target.value, 10) };
                        }
                        return item;
                      });
                      onEdit();
                      updateAccount({ ...account, affiliations: newAff });
                    }}
                    label={''}
                  />
                </Box>
              )}
              {showAllocation && !edittable && (
                <Typography variant='bodyMedium'>
                  <span style={{ color: sys.color.onSurfaceVariant }}>{t('allocation')}:</span> <b>{affiliate.allocation}%</b>
                </Typography>
              )}
            </>
          </CompletionBox>
        ))}
      {multi && edittable && affiliates.filter((x: any) => x.type === type).length >= 1 && (
        <Button
          variant='text'
          label={t(`button.${type}`)}
          leadingIcon={AddTwoTone}
          onClick={() => {
            setActiveAffiliate({ relation: defaultRelationType || undefined, user: {} });
            setAction('create');
            setOpen(true);
          }}
          sx={{ mb: 1 }}
        />
      )}
      <AffiliateModal
        data-testid='affiliate-modal'
        open={open}
        setOpen={setOpen}
        type={type}
        accountId={account.accountId}
        fields={fields}
        affiliate={activeAffiliate || { relation: defaultRelationType || undefined, user: {} }}
        allAffiliates={allAffiliates}
        affiliates={affiliates}
        refetch={refetch}
        action={action}
        useAccountHoldersAddress={useAccountHoldersAddress}
        account={account}
        title={title}
      />
    </>
  );
};
