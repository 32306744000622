/* eslint-disable array-callback-return */
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box, Card, Grid, Stack, Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { UPDATE_AFFILIATE, UPDATE_USER } from 'pages/accountsDetail/components/newAffiliate';
import {
  Account,
  AccountTypes,
  AffiliationTypes,
  EntityTypes,
  User,
} from '../../../interfaces';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { EditableRelation } from '../../corporations/newCorporation';
import { AffiliationRelations } from '../../../interfaces/affiliationRelations';
import EditRelationshipUser from '../../households/components/editRelationshipUser';
import AffiliatesCard from '../../corporation/components/AffiliatesCard';
import { FETCH_ACCOUNT } from '..';
import { Relationship } from './newAffiliateUser';
import FormModal from '../../../components/modals/formModal';
import { Affiliation } from '../../../interfaces/affiliation';
import InfoIcon from '../../../assets/images/icons-filled/information-rounded.svg';
import NewAffiliate from './newAffiliate';

export const ADD_AFFILIATION = gql`
  mutation addAffiliation($input: AddAffiliationInput!){
    addAffiliation(input: $input){
      account {
        id
      }
      incompleteFields
    }
  }
`;

export const REMOVE_AFFILIATION = gql`
  mutation removeAffiliation($input: RemoveAffiliationInput!){
    removeAffiliation(input: $input){
      account{
        id
      }
    }
  }
`;
export const FETCH_USER_RELATED_ENTITIES = (permissions: string[]) => gql`
  query fetchUserRelatedEntities($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id type state
        ${permissions.includes('read:client_low_risk_pii') ? 'phone primaryEmail firstName middleName lastName' : ''}
        entityName
        relatedEntities {
          relation
          entity {
            id type state
            ${permissions.includes('read:client_low_risk_pii') ? 'phone primaryEmail firstName middleName lastName' : ''}
            entityName
          }
        }
      }
    }
  }
`;

const Affiliates = ({ account }: { account: Account }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('affiliationTypes');
  const [showModal, setShowModal] = useState(false);
  const { permissions } = usePermissions();
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [affiliateUserId, setAffiliateUserId] = useState('');
  const { activeOrganization } = useContext(UserContext);

  const [relatedEntities, setRelatedEntities] = useState<User[]>();
  useQuery(FETCH_USER_RELATED_ENTITIES(permissions), {
    variables: { userId: account.user.id },
    onCompleted: ((data) => {
      setRelatedEntities(data.fetchUser.user.relatedEntities.map((e: any) => e.entity));
    }),
  });

  const getAllHouseholdAndRelatedEntityUsers = (): User[] => {
    const relationUsers: User[] = [];
    if (account.type.includes('CORPORATE')) {
      account.corporationClientGroup?.relationships?.map((h: any) => {
        relationUsers.push(h.user);
      });
      relatedEntities?.map((e: any) => relationUsers.push(e));
      return relationUsers;
    }
    return [];
  };

  const affiliationTypesList = Array.from(new Set(account?.affiliations?.map((x) => x.type))).sort();
  const cTAValidationByAffiliateType = (type: AffiliationTypes) => {
    if ([
      AccountTypes.RESP,
      AccountTypes.RESP_ADULT,
      AccountTypes.RESP_FAMILY,
      AccountTypes.RESP_FAMILY_JOINT,
      AccountTypes.RESP_SINGLE,
      AccountTypes.RESP_SINGLE_JOINT,
    ].includes(account.type)) {
      return true;
    }
    const applicableAffiliateCount = account?.affiliations?.filter((aff) => (aff.type === type)).length || 0;
    if (applicableAffiliateCount > 0 && (type === AffiliationTypes.Other || type === AffiliationTypes.Contributor)) {
      return false;
    }
    return true;
  };
  const variant1 = [AccountTypes.PERSONAL];
  const variant2 = [AccountTypes.LIRA, AccountTypes.RLSP, AccountTypes.LRSP];
  const variant3 = [AccountTypes.RIF_SPOUSAL];
  const variant31 = [AccountTypes.RRSP_SPOUSAL];
  const variant32 = [AccountTypes.RRSP, AccountTypes.USA_BROKERAGE, AccountTypes.USA_IRA, AccountTypes.USA_RO_IRA, AccountTypes.USA_RT_IRA, AccountTypes.USA_ESA];
  const variant4 = [AccountTypes.TFSA, AccountTypes.RRIF, AccountTypes.PRIF, AccountTypes.LIF, AccountTypes.RLIF, AccountTypes.LRIF];
  const variant5 = [AccountTypes.RESP_SINGLE, AccountTypes.RESP_FAMILY];
  const variant6 = [AccountTypes.CORPORATE_CASH, AccountTypes.CORPORATE_CHARITY, AccountTypes.CORPORATE_ESTATE, AccountTypes.CORPORATE_TRUST];
  const variant7 = [AccountTypes.RESP_FAMILY_JOINT, AccountTypes.RESP_SINGLE_JOINT];
  const variant8 = [AccountTypes.FHSA];
  const variant9 = [AccountTypes.USA_INH_IRA, AccountTypes.USA_INH_RT_IRA];

  const getAffiliationTypeByAccount = (): AffiliationTypes[] => {
    const accountType = account.type;
    const userEntityType = account.user && account.user.type ? account.user.type : EntityTypes.INDIVIDUAL;
    if (variant1.includes(accountType)) return [];
    if (variant2.includes(accountType)) return [AffiliationTypes.PrimaryBeneficiary, AffiliationTypes.Other];
    if (variant3.includes(accountType)) return [AffiliationTypes.PrimaryBeneficiary, AffiliationTypes.Contributor, AffiliationTypes.Successor];
    if (variant31.includes(accountType)) return [AffiliationTypes.PrimaryBeneficiary, AffiliationTypes.Contributor];
    if (variant32.includes(accountType)) return [AffiliationTypes.PrimaryBeneficiary];
    if (variant4.includes(accountType) && accountType !== AccountTypes.TFSA) return [AffiliationTypes.PrimaryBeneficiary, AffiliationTypes.Successor, AffiliationTypes.Other];
    if (accountType === AccountTypes.TFSA) return [AffiliationTypes.PrimaryBeneficiary, AffiliationTypes.Successor];
    if (variant5.includes(accountType)) return [AffiliationTypes.PrimaryBeneficiary, AffiliationTypes.Other];
    if (variant7.includes(accountType)) return [AffiliationTypes.PrimaryBeneficiary, AffiliationTypes.Joint, AffiliationTypes.Other];
    if (variant8.includes(accountType)) return [AffiliationTypes.PrimaryBeneficiary, AffiliationTypes.Successor];
    if (variant9.includes(accountType)) return [AffiliationTypes.Decedent];
    if (accountType === AccountTypes.CORPORATE_CASH && userEntityType === EntityTypes.TRUST) {
      const typeList = [
        AffiliationTypes.AuthorizedIndividual,
        AffiliationTypes.BeneficialOwner,
        AffiliationTypes.Settlor,
        AffiliationTypes.Grantor,
        AffiliationTypes.Protector,
      ];
      if (account.user && account.user.powerOfAttorneyGranted) {
        typeList.push(AffiliationTypes.PowerOfAttorney);
      }
      if (account.user && account.user.isForThirdParty) {
        typeList.push(AffiliationTypes.ThirdParty);
      }
      return typeList;
    }
    if (accountType === AccountTypes.CORPORATE_CASH && userEntityType !== EntityTypes.TRUST) {
      const typeList = [
        AffiliationTypes.AuthorizedIndividual,
        AffiliationTypes.BeneficialOwner, AffiliationTypes.Director,
      ];
      if (userEntityType !== EntityTypes.INDIVIDUAL) {
        if (account.user && account.user.powerOfAttorneyGranted) {
          typeList.push(AffiliationTypes.PowerOfAttorney);
        }
        if (account.user && account.user.isForThirdParty) {
          typeList.push(AffiliationTypes.ThirdParty);
        }
      }
      return typeList;
    }
    // check type as well
    if (accountType === AccountTypes.CORPORATE_TRUST) return [AffiliationTypes.AuthorizedIndividual, AffiliationTypes.BeneficialOwner, AffiliationTypes.Settlor];
    if (accountType === AccountTypes.CORPORATE_ESTATE) return [AffiliationTypes.AuthorizedIndividual, AffiliationTypes.BeneficialOwner, AffiliationTypes.Settlor];
    if (accountType === AccountTypes.CORPORATE_CHARITY) return [AffiliationTypes.AuthorizedIndividual, AffiliationTypes.BeneficialOwner];
    if (accountType === AccountTypes.CASH_JOINT) return [AffiliationTypes.Joint];
    if (accountType === AccountTypes.RESP_ADULT) return [AffiliationTypes.PrimaryBeneficiary];
    return Object.values(AffiliationTypes);
  };

  const isAddAffiliateDisabled = (type: AffiliationTypes): boolean => {
    if (account.type === AccountTypes.RESP_SINGLE && type === AffiliationTypes.Other) return false;
    return [AccountTypes.RESP_ADULT, AccountTypes.RESP_SINGLE, AccountTypes.CASH_JOINT].includes(account.type);
  };

  const isSignatureRequired = (type: AffiliationTypes): boolean => {
    if (variant6.includes(account.type) && type === AffiliationTypes.AuthorizedIndividual) return true;
    return false;
  };

  const [addAffiliation] = useMutation(ADD_AFFILIATION, {
    refetchQueries:
      [{ query: FETCH_ACCOUNT(permissions), variables: { id: account.id } }],
    onCompleted: (data) => {
      if (data?.addAffiliation?.incompleteFields && data.addAffiliation.incompleteFields.length > 0) {
        setIncompleteFields(data.addAffiliation.incompleteFields);
        setShowModal(true);
      }
    },
  });

  const [removeAffiliation] = useMutation(REMOVE_AFFILIATION, {
    refetchQueries: [{ query: FETCH_ACCOUNT(permissions), variables: { id: account.id } }],
  });

  const [removeAffiliationAllocation] = useMutation(REMOVE_AFFILIATION, {
  });

  const addAffiliate = async (relationship: Relationship): Promise<void> => {
    setAffiliateUserId(relationship.userId);
    const signatureRequired = isSignatureRequired(relationship.type);
    addAffiliation({
      variables: {
        input: {
          accountId: account.id,
          ...relationship,
          signatureRequired,
        },
      },
    });
  };

  const removeAffiliate = async (affiliate: Affiliation): Promise<void> => {
    removeAffiliation({
      variables: {
        input: {
          accountId: account.id,
          affiliationId: affiliate.id,
        },
      },
    });
  };

  const removeAndAddAffiliate = async (affiliate: Affiliation, relationship: any): Promise<void> => {
    removeAffiliationAllocation({
      variables: {
        input: {
          accountId: account.id,
          affiliationId: affiliate.id,
        },
      },
      onCompleted: () => {
        addAffiliate(
          relationship,
        );
      },
    });
  };

  const [updateAffiliation] = useMutation(account.user.affiliateOnly ? UPDATE_AFFILIATE : UPDATE_USER, {
    refetchQueries: [{
      query: FETCH_ACCOUNT(permissions), variables: { id: account.id },
    }],
  });

  const updateAffiliate = async (affiliate: Affiliation, newType: AffiliationTypes, gender: string, allocationNumber: number): Promise<void> => {
    if (affiliate.type === AffiliationTypes.PrimaryBeneficiary && account.type.includes('RESP')) {
      updateAffiliation({
        variables: {
          input: {
            userId: affiliate.user.id,
            gender,
          },
        },
      }).then();
    } else if (affiliate.allocation !== allocationNumber) {
      const relationship: Relationship = {
        type: affiliate.type,
        allocation: allocationNumber,
        relation: affiliate.relation,
        userId: affiliate.user.id,
      };
      removeAndAddAffiliate(affiliate, relationship);
    } else {
      if (affiliate.type === newType) return;
      const relationship: Relationship = {
        type: newType,
        allocation: affiliate.allocation,
        relation: affiliate.relation,
        userId: affiliate.user.id,
      };
      removeAndAddAffiliate(affiliate, relationship);
    }
  };

  const isOtherFilterAccount = (accountType: string, type: string): boolean => {
    const accounts = /(LIRA|LRSP|RLSP|LIF|RRIF|RLIF|LRIF|PRIF)/;
    return (accounts.test(accountType) && type === AffiliationTypes.Other);
  };

  return (
    <Box>
      {
        affiliationTypesList.map((type, index: number) => <Card key={index} variant="outlined" sx={{ mb: '20px', border: '1.5px solid #EAECEE', boxShadow: '0px 4px 6px rgba(58, 96, 133, 0.1)' }}>
          <Box key={type} sx={{ margin: '15px 15px' }}>
            <Box key={index} display="flex" alignItems="center" sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} mr="24px">
              <Typography variant="h6" sx={{ fontWeight: 600, mr: 0.7 }}>{t(`affiliationTypes:${type}`)}</Typography>
              <Box>
                {cTAValidationByAffiliateType(type) && (<NewAffiliate
                  allowedTypes={getAffiliationTypeByAccount()}
                  affiliationType={type}
                  relation={account.type.includes('CORPORATE') ? AffiliationRelations.Other : undefined}
                  organizationId={activeOrganization.id ?? ''}
                  accountType={account.type}
                  buttonType={getAffiliationTypeByAccount().some((x) => x === type) && !isAddAffiliateDisabled(type) ? 'BUTTON' : 'ICON'}
                  modalButton={t(`affiliationTypes:button.${type}`)}
                  onUserVerify={(relationship: Relationship | undefined) => {
                    if (relationship) {
                      addAffiliate(relationship);
                    }
                  }}
                  relationUsers={account.type.includes('CORPORATE') ? getAllHouseholdAndRelatedEntityUsers() : undefined}
                />)}
              </Box>
            </Box>
            {
              account?.affiliations?.filter((x) => x.type === type).map((relation: any, index2: number) => <Grid key={index2} item md={12} xs={12}>
                <Box sx={{ margin: '10px 0px' }}>
                {!isOtherFilterAccount(account.type, type) ? (
                    <AffiliatesCard
                      key={index}
                      isIndividual={account.user.type === EntityTypes.INDIVIDUAL}
                      user={relation.user}
                      type={t(`affiliationTypes:affiliationRelationship.${relation.relation}`)}
                      mainAccountType={account && account.type ? account.type : undefined}
                      affiliateType={relation.type}
                      allocation={relation.allocation}
                      icon={
                        <EditRelationshipUser
                          allowedTypes={getAffiliationTypeByAccount()}
                          affiliationType={type}
                          email={relation.user.primaryEmail}
                          accountType={account.type}
                          gender={relation.user.gender}
                          allocation={relation.user.allocation}
                          icon={<EditIcon sx={{ color: '#2B4763' }} data-testid="edit-icon" />}
                          onUserVerify={(relationship: EditableRelation | undefined) => {
                            updateAffiliate(relation, relationship?.type as AffiliationTypes, relationship?.gender || '', relationship?.allocation || 0);
                          }}
                          onUserDelete={() => {
                            removeAffiliate(relation);
                          }}
                        />
                      }
                    />
                ) : (
                    <AffiliatesCard
                      key={index}
                      isIndividual={account.user.type === EntityTypes.INDIVIDUAL}
                      user={relation.user}
                      type={t(`affiliationTypes:affiliationRelationship.${relation.relation}`)}
                      mainAccountType={account && account.type ? account.type : undefined}
                      affiliateType={relation.type}
                      allocation={relation.allocation}
                    />
                )
                }
                </Box>
              </Grid>)
            }
          </Box>
        </Card>)
      }
      {
        getAffiliationTypeByAccount().map((type, index: number) => !affiliationTypesList.includes(type)
          && <Card key={index} variant="outlined" sx={{ mb: '20px', border: '1.5px solid #EAECEE', boxShadow: '0px 4px 6px rgba(58, 96, 133, 0.1)' }}>
            <Box key={type} sx={{ margin: '15px 15px' }}>
              <Box key={index} display="flex" alignItems="center" sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} mr="24px">
                <Typography variant="h6" sx={{ fontWeight: 600, mr: 0.7 }}>{t(`affiliationTypes:${type}`)}</Typography>
              </Box>
              <Stack direction="column" alignItems="center" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <img src={InfoIcon} alt="risk-level-icon" />
                <Typography variant="subtitle2">{t('affiliationTypes:noLinkedAffiliate', { type: t(`affiliationTypes:noLinkedAffiliateType.${type}`) })}</Typography>
              </Stack>
              <Box display="flex" alignItems="center" sx={{
                mb: '20px', width: '100%', display: 'flex', justifyContent: 'center',
              }}>
                <NewAffiliate
                  allowedTypes={getAffiliationTypeByAccount()}
                  affiliationType={type}
                  organizationId={activeOrganization.id ?? ''}
                  buttonType="BUTTON"
                  accountType={account.type}
                  relation={account.type.includes('CORPORATE') ? AffiliationRelations.Other : undefined}
                  modalButton={t(`affiliationTypes:button.${type}`)}
                  onUserVerify={(relationship: Relationship | undefined) => {
                    if (relationship) {
                      addAffiliate(relationship);
                    }
                  }}
                  relationUsers={account.type.includes('CORPORATE') ? getAllHouseholdAndRelatedEntityUsers() : undefined}
                />
              </Box>
            </Box>
          </Card>)
      }
      <FormModal
        key={'information-modal'}
        open={showModal}
        loading={false}
        title={t('affiliationTypes:additionalInfoModal.title')}
        children={
          [
            <Box key={'modal-info'} sx={{ margin: '20px' }}>
              <Typography>{t('affiliationTypes:additionalInfoModal.body')}</Typography>
              <Typography>
                <ol>
                  {
                    incompleteFields.map((message, index) => <li key={index}>{message}</li>)
                  }
                </ol>
              </Typography>
            </Box>,
          ]
        }
        formButton={t('affiliationTypes:Proceed')}
        onSubmit={() => navigate(`/clients/${affiliateUserId}`)}
        handleClose={() => {
          setShowModal(false);
          setIncompleteFields([]);
        }}
      />
    </Box>
  );
};

export default Affiliates;
