import { CustomField, CustomFieldConditionalRulesComparisonTypes } from '../interfaces/customField';

export const evaluateRule = (
  object: any,
  rule: { field: string, comparison: CustomFieldConditionalRulesComparisonTypes, value: any },
) => {
  const objectValue = object[rule.field];
  const expectedValue = rule.value;
  const { comparison } = rule;
  if (!objectValue) return false;
  switch (comparison) {
    case CustomFieldConditionalRulesComparisonTypes.EQUALS:
      return objectValue === expectedValue;
    case CustomFieldConditionalRulesComparisonTypes.NOT_EQUALS:
      return objectValue !== expectedValue;
    case CustomFieldConditionalRulesComparisonTypes.INCLUDES:
      if (Array.isArray(objectValue)) {
        return objectValue.includes(expectedValue);
      }
      return false;
    case CustomFieldConditionalRulesComparisonTypes.NOT_INCLUDES:
      if (Array.isArray(objectValue)) {
        return !objectValue.includes(expectedValue);
      }
      return true;
    case CustomFieldConditionalRulesComparisonTypes.IN:
      return !!expectedValue.split(',')?.includes(objectValue);
    case CustomFieldConditionalRulesComparisonTypes.NOT_IN:
      return !expectedValue.split(',')?.includes(objectValue);
    case CustomFieldConditionalRulesComparisonTypes.GREATER_THAN:
      return objectValue > expectedValue;
    case CustomFieldConditionalRulesComparisonTypes.LESS_THAN:
      return objectValue < expectedValue;
    case CustomFieldConditionalRulesComparisonTypes.GREATER_THAN_AGO:
      if (objectValue instanceof Date) {
        const currentDate = new Date();
        const differenceInMilliseconds: any = currentDate.getTime() - objectValue.getTime();
        return differenceInMilliseconds > expectedValue;
      }
      return false;
    case CustomFieldConditionalRulesComparisonTypes.LESS_THAN_AGO:
      if (objectValue instanceof Date) {
        const currentDate = new Date();
        const differenceInMilliseconds: any = currentDate.getTime() - objectValue.getTime();
        return differenceInMilliseconds < expectedValue;
      }
      return false;
    default:
      return false;
  }
};

export const evaluateTriggerRules = (customField: CustomField, objectPayload?: any) => {
  if (!customField.conditionalRules || customField.conditionalRules.length === 0) {
    return true;
  }
  if (!objectPayload) {
    return false;
  }
  let result = true;
  for (const rule of customField.conditionalRules) {
    result = evaluateRule(objectPayload, rule);
    if (!result) break;
  }

  return result;
};
