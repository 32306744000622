/* eslint-disable no-else-return */
import { useState } from 'react';
import {
  TextField, ListItem, MenuItem, Button, InputAdornment, Box,
} from '@mui/material';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Search } from '@mui/icons-material';
import NewClient from '../../clients/components/newClient';
import CreateNewModal from '../../../components/modals/createNewModal';
import { AffiliationRelations } from '../../../interfaces/affiliationRelations';
import {
  Account,
  AccountTypes, AffiliationTypes, Goal,
  isRESPAccount, Organization, PhysicalAddress, SubAccount, User, UserStates,
} from '../../../interfaces';
import AddressField from '../../../components/inputs/addressField';
import { ClientSelect } from '../../../components/inputs/clientSelect';
import { usePermissions } from '../../../providers/userContextProvider';
import { generateClientNameString } from '../../../util';
import { LocalizedDatePicker } from '../../../components/fields/localizedDatePicker';

export const FETCH_USER_DATA = (permissions: string[]) => gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'middleName' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'affiliateOnly' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'primaryEmail' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'phone' : ''}
        ${permissions.includes('read:client_high_risk_pii') ? 'dateOfBirth' : ''}
        ${permissions.includes('read:client_high_risk_pii') ? 'gender' : ''}
        ${permissions.includes('read:client_high_risk_pii') ? 'physicalAddress { city country houseNumber province neighborhood postal streetName unitNumber }' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'employmentStatus' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'companyType' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'jobTitle' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'studentAreaOfStudy' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'maritalStatus' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'citizenships' : ''}
        ${permissions.includes('read:client_low_risk_pii') ? 'inProvinceSince' : ''}
        sinExists
     }
    }
  }
`;

export interface Relationship {
  type: AffiliationTypes,
  allocation?: number,
  relation: AffiliationRelations,
  userId: string,
}
export const CREATE_AFFILIATE = gql`
  mutation createAffiliate($input: CreateAffiliateInput!){
    createAffiliate(input: $input){
      user{
        id
      }
    }
  }
`;

export const FETCH_USERS = gql`
query fetchUsers($input: FetchUsersInput!) {
  fetchUsers(input: $input) {
      users {
        id
      }
    }
  }
`;

export const UPDATE_AFFILIATE = gql`
mutation updateAffiliate($input: UpdateAffiliateInput!) {
  updateAffiliate(input: $input) {
      user {
        id
      }
    }
  }
`;

export const UPDATE_USER = gql`
mutation updateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;
export interface RelationUser {
  id: string,
  firstName: string,
  lastName: string,
  middleName?: string,
  dateOfBirth?: string,
  physicalAddress: any,
  subAccounts: SubAccount[],
  accounts: Account[],
  goals: Goal[],
  organization: Organization,
  state: UserStates,
}
const NewAffiliate = (
  {
    onUserVerify,
    organizationId,
    affiliationType, relation, allowedTypes, buttonType, modalButton,
    accountType,
    relationUsers,
  }: {
    onUserVerify: (relationship?: Relationship | undefined) => void,
    organizationId: string,
    allowedTypes: string[],
    relation?: AffiliationRelations,
    affiliationType: AffiliationTypes,
    modalButton?: string,
    buttonType?: 'BUTTON' | 'ICON',
    accountType: AccountTypes,
    relationUsers?: User[],
  },
) => {
  const { t } = useTranslation(['clients', 'household']);
  const [isNewUser, setIsNewUser] = useState(false);
  const [hideSubmitButton, setHideSubmitButton] = useState(true);
  const { permissions } = usePermissions();
  const physicalAddress: PhysicalAddress = {
    city: '',
    country: undefined,
    houseNumber: '',
    neighborhood: '',
    postal: '',
    province: '',
    streetName: '',
    unitNumber: '',
  };

  const affiliateUser = {
    organizationId,
    affiliationType,
    relation,
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    dateOfDeath: '',
    physicalAddress,
    citizenships: ['CA'],
    sin: '',
    allocation: 0,
    employmentStatus: '',
    jobTitle: '',
    companyType: '',
    primaryEmail: '',
    userId: '',
    inProvinceSince: '',
    affiliateOnly: undefined,
    sinExists: false,
  };

  const options = relationUsers || [];
  const [value, setValue] = useState<User | null>();
  const [inputValue, setInputValue] = useState('');

  const isInCompleteAddress = (address: PhysicalAddress) => (!(address.country && address.city && address.province && address.postal && address.houseNumber && address.streetName));
  const [user, setUser] = useState(affiliateUser);

  const [updateAffiliation] = useMutation(user.affiliateOnly ? UPDATE_AFFILIATE : UPDATE_USER);

  const [createAffiliate, { loading }] = useMutation(CREATE_AFFILIATE, {
    variables: {
      input: {
        ...user,
        affiliationType: undefined,
        relation: undefined,
        userId: undefined,
        allocation: undefined,
        affiliateOnly: undefined,
        inProvinceSince: user.inProvinceSince !== '' ? user.inProvinceSince : undefined,
        physicalAddress: isInCompleteAddress(user.physicalAddress) ? undefined : user.physicalAddress,
        dateOfBirth: user.dateOfBirth !== '' ? user.dateOfBirth : undefined,
        dateOfDeath: user.dateOfDeath !== '' ? user.dateOfDeath : undefined,
        employmentStatus: user.employmentStatus !== '' ? user.employmentStatus ?? undefined : undefined,
        sinExists: undefined,
        sin: user.sin,
      },
    },
  });
  const [client, setClient] = useState<User>();
  const [fetchUser, { loading: userLoading }] = useLazyQuery(FETCH_USER_DATA(permissions), {
    variables: {
      userId: client?.id,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (res: any) => {
      const userData = res.fetchUser.user;
      setUser({
        ...user,
        userId: userData.id,
        sinExists: userData.userData,
        organizationId,
        affiliationType,
        relation,
        firstName: userData.firstName,
        middleName: userData.middleName,
        lastName: userData.lastName,
        gender: userData.gender,
        citizenships: userData.citizenships,
        allocation: user.allocation,
        jobTitle: userData.jobTitle,
        companyType: userData.companyType,
        primaryEmail: userData.primaryEmail,
        affiliateOnly: userData.affiliateOnly,
        physicalAddress: {
          city: userData?.physicalAddress?.city,
          country: userData?.physicalAddress?.country,
          houseNumber: userData?.physicalAddress?.houseNumber,
          neighborhood: userData?.physicalAddress?.neighborhood,
          postal: userData?.physicalAddress?.postal,
          province: userData?.physicalAddress?.province,
          streetName: userData?.physicalAddress?.streetName,
          unitNumber: userData?.physicalAddress?.unitNumber,
        },
        dateOfBirth: userData.dateOfBirth,
        employmentStatus: userData.employmentStatus,
        inProvinceSince: userData.inProvinceSince,
      });
    },
  });

  const isGenderRequired = (): boolean => {
    const accountTypesRESP = [
      AccountTypes.RESP_FAMILY,
      AccountTypes.RESP_SINGLE,
      AccountTypes.RESP_ADULT,
      AccountTypes.RESP_FAMILY_JOINT,
      AccountTypes.RESP_SINGLE_JOINT,
    ];

    if (accountTypesRESP.includes(accountType)) return true;

    return false;
  };
  const isDobRequired = (): boolean => {
    const accountTypes1 = [
      AccountTypes.TFSA,
      AccountTypes.RIF_SPOUSAL,
      AccountTypes.RRSP_SPOUSAL,
      AccountTypes.RRIF,
      AccountTypes.PRIF,
      AccountTypes.LRIF,
      AccountTypes.RLIF,
      AccountTypes.LIF,
      AccountTypes.LIRA,
      AccountTypes.LRSP,
      AccountTypes.RLSP,
      AccountTypes.RRSP,
    ];
    const accountTypes2 = [
      AccountTypes.TFSA,
      AccountTypes.RIF_SPOUSAL,
      AccountTypes.RRIF,
      AccountTypes.PRIF,
      AccountTypes.LRIF,
      AccountTypes.RLIF,
      AccountTypes.LIF,
    ];
    const accountTypes3 = [
      AccountTypes.RESP_ADULT,
      AccountTypes.RESP_FAMILY,
      AccountTypes.RESP_FAMILY_JOINT,
      AccountTypes.RESP_SINGLE,
      AccountTypes.RESP_SINGLE_JOINT,
    ];
    if (accountType.includes('CORPORATE')) return false;
    if (accountTypes1.includes(accountType)
      && [AffiliationTypes.PrimaryBeneficiary].includes(user.affiliationType)
      && user.relation
    ) return false;

    if (accountTypes1.includes(accountType)
      && [AffiliationTypes.PrimaryBeneficiary].includes(user.affiliationType)
      && !user.relation
    ) return false;

    if (accountTypes2.includes(accountType)
      && [AffiliationTypes.Successor].includes(user.affiliationType)
      && user.relation
      && [
        AffiliationRelations.Spouse,
        AffiliationRelations.CommonLaw,
      ].includes(user.relation)
    ) return false;

    if (accountTypes3.includes(accountType)
      && [AffiliationTypes.Other].includes(user.affiliationType)
    ) return false;
    return true;
  };
  const isDodRequired = (): boolean => {
    if ((accountType === AccountTypes.USA_INH_IRA || AccountTypes.USA_INH_RT_IRA)
     && [AffiliationTypes.Decedent].includes(user.affiliationType)
    ) return true;
    return false;
  };
  const isAddressRequired = (): boolean => {
    const accountTypes = [
      AccountTypes.TFSA,
      AccountTypes.RIF_SPOUSAL,
      AccountTypes.RRSP_SPOUSAL,
      AccountTypes.RRIF,
      AccountTypes.PRIF,
      AccountTypes.LRIF,
      AccountTypes.RLIF,
      AccountTypes.LIF,
      AccountTypes.LIRA,
      AccountTypes.LRSP,
      AccountTypes.RLSP,
      AccountTypes.RRSP,
    ];
    if (accountType.includes('CORPORATE')) return false;
    if (accountTypes.includes(accountType) && [AffiliationTypes.PrimaryBeneficiary].includes(user.affiliationType)) return false;
    return true;
  };

  const isEmployementRequired = (): boolean => {
    const accountTypes1 = [AccountTypes.RRSP_SPOUSAL, AccountTypes.RIF_SPOUSAL];
    const accountTypes2 = [AccountTypes.RRIF, AccountTypes.PRIF, AccountTypes.LRIF, AccountTypes.RLIF, AccountTypes.LIF];
    const accountTypes3 = [AccountTypes.LIRA, AccountTypes.LRSP, AccountTypes.RLSP];
    if (((accountTypes1.includes(accountType) && [AffiliationTypes.Contributor].includes(user.affiliationType))
      || (accountTypes2.includes(accountType) && [AffiliationTypes.Other].includes(user.affiliationType))
      || (accountTypes3.includes(accountType) && [AffiliationTypes.Other].includes(user.affiliationType)))
      && user.relation && [AffiliationRelations.CommonLaw, AffiliationRelations.Spouse].includes(user.relation)) return true;
    return false;
  };

  const isAllocationRequired = (): boolean => {
    const avoidingAccountTypes = [
      AccountTypes.RESP_FAMILY,
      AccountTypes.RESP_SINGLE,
      AccountTypes.RESP_ADULT,
      AccountTypes.RESP_FAMILY_JOINT,
      AccountTypes.RESP_SINGLE_JOINT,
    ];
    if (avoidingAccountTypes.includes(accountType)) return false;
    if ([AffiliationTypes.PrimaryBeneficiary, AffiliationTypes.BeneficialOwner, AffiliationTypes.Decedent].includes(user.affiliationType)) return true;
    return false;
  };

  const accountsRelationMap: any[] = [
    {
      account: ['RESP_FAMILY'],
      relationships: [
        { PRIMARY_BENEFICIARY: ['CHILD', 'GRANDCHILD', 'SIBLING'] },
        { OTHER: ['PRIMARY_CAREGIVER', 'GUARDIAN'] },
      ],
    },
    {
      account: ['RESP_FAMILY_JOINT'],
      relationships: [
        { PRIMARY_BENEFICIARY: ['CHILD', 'GRANDCHILD', 'SIBLING'] },
        { JOINT: ['SPOUSE', 'COMMON_LAW'] },
        { OTHER: ['PRIMARY_CAREGIVER', 'GUARDIAN'] },
      ],
    },
    {
      account: ['RESP_SINGLE_JOINT'],
      relationships: [
        { PRIMARY_BENEFICIARY: ['CHILD', 'GRANDCHILD', 'SIBLING', 'NEPHEW_NIECE', 'OTHER'] },
        { JOINT: ['SPOUSE', 'COMMON_LAW'] },
        { OTHER: ['PRIMARY_CAREGIVER', 'GUARDIAN'] },
      ],
    },
    {
      account: ['RESP_SINGLE'],
      relationships: [
        { PRIMARY_BENEFICIARY: ['CHILD', 'GRANDCHILD', 'SIBLING', 'NEPHEW_NIECE', 'OTHER'] },
        { OTHER: ['PRIMARY_CAREGIVER', 'GUARDIAN'] },
      ],
    },
    {
      account: ['RESP_ADULT'],
      relationships: [
        { PRIMARY_BENEFICIARY: ['OTHER'] },
      ],
    },
    {
      account: ['RIF_SPOUSAL', 'RRSP_SPOUSAL'],
      relationships: [
        { PRIMARY_BENEFICIARY: ['CHILD', 'SPOUSE', 'COMMON_LAW', 'PARENT', 'GRANDPARENT', 'GRANDCHILD', 'SIBLING', 'NEPHEW_NIECE', 'OTHER'] },
        { CONTRIBUTOR: ['SPOUSE', 'COMMON_LAW'] },
        { SUCCESSOR: ['SPOUSE', 'COMMON_LAW'] },
      ],
    },
    {
      account: ['USA_BROKERAGE', 'USA_IRA', 'USA_RO_IRA', 'USA_RT_IRA'],
      relationships: [
        { PRIMARY_BENEFICIARY: ['CHILD, COMMON_LAW, GRANDCHILD, GRANDPARENT, GUARDIAN, NEPHEW_NIECE, OTHER, PARENT, PRIMARY_CAREGIVER, SIBLING, SPOUSE'] },
      ],
    },
    {
      account: ['USA_ESA'],
      relationships: [
        { PRIMARY_BENEFICIARY: ['CHILD', 'GRANDCHILD', 'SIBLING', 'NEPHEW_NIECE', 'OTHER'] },
      ],
    },
  ];

  const relationships = Object.values(AffiliationRelations);

  const getRelationship = (type: AffiliationTypes): AffiliationRelations[] => {
    if ([AffiliationTypes.AuthorizedIndividual, AffiliationTypes.BeneficialOwner].includes(type)
      && accountType.includes('CORPORATE')) return [AffiliationRelations.Other];

    if (isRESPAccount(accountType) || [AccountTypes.RIF_SPOUSAL, AccountTypes.RRSP_SPOUSAL, AccountTypes.USA_ESA].includes(accountType)) {
      const relations = accountsRelationMap.find((x) => x.account.includes(accountType))?.relationships.find((o: any) => Object.keys(o)[0] === type);
      if (relations) return Object.values(relations)[0] as AffiliationRelations[];
    }

    if (type === AffiliationTypes.Successor || type === AffiliationTypes.Other) return [AffiliationRelations.CommonLaw, AffiliationRelations.Spouse];
    return relationships.filter((x) => ![AffiliationRelations.Guardian, AffiliationRelations.PrimaryCaregiver].includes(x));
  };
  const genderList = ['MALE', 'FEMALE'];
  const employementStatusList = ['EMPLOYED', 'RETIRED', 'SELF_EMPLOYED', 'STUDENT', 'UNEMPLOYED'];

  const updateField = (event: any) => {
    const key: 'primaryEmail' | 'firstName' | 'lastName' = event.target.id;
    setUser({ ...user, [key]: event.target.value as string });
  };

  const updateAllocation = (event: any) => {
    const key: 'allocation' = event.target.id;
    setUser({ ...user, [key]: event.target.value as number });
  };

  const updateType = (event: any) => {
    setUser({ ...user, affiliationType: event.target.value as AffiliationTypes });
  };

  const updateRelation = (event: any) => {
    setUser({ ...user, relation: event.target.value as AffiliationRelations });
  };

  const addRelationship = async () => {
    updateAffiliation({
      variables: {
        input: {
          ...user,
          physicalAddress: isInCompleteAddress(user.physicalAddress) ? undefined : user.physicalAddress,
          organizationId: undefined,
          affiliationType: undefined,
          allocation: undefined,
          relation: undefined,
          inProvinceSince: undefined,
          affiliateOnly: undefined,
          dateOfBirth: user.dateOfBirth !== '' ? user.dateOfBirth : undefined,
          dateOfDeath: user.dateOfDeath !== '' ? user.dateOfDeath : undefined,
          employmentStatus: user.employmentStatus !== '' ? user.employmentStatus ?? undefined : undefined,
          userId: user.userId,
          sin: undefined,
          sinExists: undefined,
        },
      },
    }).then(() => onUserVerify({
      userId: user.userId,
      allocation: isAllocationRequired() ? Number(user.allocation) : undefined,
      type: user.affiliationType as AffiliationTypes,
      relation: user.relation as AffiliationRelations,
    }));
    setIsNewUser(false);
    setHideSubmitButton(true);
    setUser(affiliateUser);
  };

  const addNewUser = async () => {
    const response = await createAffiliate();
    if (response?.data.createAffiliate.user.id) {
      setHideSubmitButton(false);
      setUser({ ...user, userId: response?.data.createAffiliate.user.id });
      setIsNewUser(true);
    }
  };

  const addExistingUser = async () => {
    if (user.userId) {
      setHideSubmitButton(false);
      setUser(user);
      setIsNewUser(true);
    }
  };

  const validateAllocation = isAllocationRequired() ? Number(user.allocation) > 0 : true;
  const disabled = !(user.relation && user.organizationId && user.affiliationType && validateAllocation);
  const addUserDisabled = !(user.affiliationType && user.organizationId && user.firstName && user.lastName && user.affiliationType && user.relation);

  const isAccountSet1 = [AccountTypes.RESP_FAMILY, AccountTypes.RESP_SINGLE].includes(accountType);

  const isAccountSet2 = [
    AccountTypes.TFSA,
    AccountTypes.RRIF, AccountTypes.PRIF, AccountTypes.LRIF, AccountTypes.RLIF,
    AccountTypes.LIF, AccountTypes.LIRA, AccountTypes.LRSP, AccountTypes.RLSP,
    AccountTypes.RRSP_SPOUSAL, AccountTypes.RIF_SPOUSAL, AccountTypes.RRSP,
  ].includes(accountType);

  const isAccountSet3 = [
    AccountTypes.TFSA, AccountTypes.RIF_SPOUSAL,
    AccountTypes.RRIF, AccountTypes.PRIF, AccountTypes.LRIF,
    AccountTypes.RLIF, AccountTypes.LIF,
  ].includes(accountType);

  const isAccountSet4 = [AccountTypes.RRSP_SPOUSAL, AccountTypes.RIF_SPOUSAL].includes(accountType);

  const isAccountSet5 = [
    AccountTypes.RRIF, AccountTypes.PRIF,
    AccountTypes.LRIF, AccountTypes.RLIF, AccountTypes.LIF,
  ].includes(accountType);

  const isAccountSet6 = [
    AccountTypes.LIRA, AccountTypes.LRSP,
    AccountTypes.RLSP,
  ].includes(accountType);

  const isAccountSet7 = [
    AccountTypes.USA_INH_IRA,
    AccountTypes.USA_INH_RT_IRA,
  ].includes(accountType);

  const validateForm = (): boolean => {
    if (user.affiliationType === AffiliationTypes.Other
      && user.relation === AffiliationRelations.PrimaryCaregiver
      && isAccountSet1) {
      return !(user.affiliationType && user.organizationId
        && user.firstName && user.lastName
        && !isInCompleteAddress(user.physicalAddress));
    } else if (user.affiliationType === AffiliationTypes.Other && user.relation === AffiliationRelations.Guardian && isAccountSet1) {
      return !(user.affiliationType && user.organizationId
        && user.firstName && user.lastName
        && !isInCompleteAddress(user.physicalAddress));
    } else if (user.affiliationType === AffiliationTypes.PrimaryBeneficiary
      && user.relation
      && [
        AffiliationRelations.Child,
        AffiliationRelations.Grandchild,
        AffiliationRelations.Sibling,
        AffiliationRelations.NephewNiece,
        AffiliationRelations.Other,
      ].includes(user.relation)
      && isAccountSet1) {
      return !(user.affiliationType && user.organizationId
        && user.firstName && user.lastName
        && user.dateOfBirth
        && user.gender
        && !isInCompleteAddress(user.physicalAddress));
    } else if (user.affiliationType === AffiliationTypes.PrimaryBeneficiary
      && isAccountSet2) {
      return !(user.affiliationType && user.organizationId
        && user.firstName && user.lastName
        && user.relation
        && user.allocation);
    } else if (user.affiliationType === AffiliationTypes.Successor
      && (AffiliationRelations.Spouse === user.relation || AffiliationRelations.CommonLaw === user.relation)
      && isAccountSet3) {
      return !(user.affiliationType && user.organizationId
        && user.firstName && user.lastName
        && !isInCompleteAddress(user.physicalAddress));
    } else if (user.affiliationType === AffiliationTypes.Contributor
      && user.relation
      && [
        AffiliationRelations.CommonLaw,
        AffiliationRelations.Spouse,
      ].includes(user.relation)
      && isAccountSet4) {
      return !(user.affiliationType && user.organizationId
        && user.firstName && user.lastName
        && user.relation
        && user.dateOfBirth
        && !isInCompleteAddress(user.physicalAddress));
    } else if (user.affiliationType === AffiliationTypes.Other
      && (AffiliationRelations.Spouse === user.relation || AffiliationRelations.CommonLaw === user.relation)
      && isAccountSet5) {
      return !(user.affiliationType && user.organizationId
        && user.firstName && user.lastName
        && user.relation
        && user.dateOfBirth
        && !isInCompleteAddress(user.physicalAddress));
    } else if (user.affiliationType === AffiliationTypes.Other
      && (AffiliationRelations.Spouse === user.relation || AffiliationRelations.CommonLaw === user.relation)
      && isAccountSet6) {
      return !(user.affiliationType && user.organizationId
        && user.firstName && user.lastName
        && user.relation
        && user.dateOfBirth
        && !isInCompleteAddress(user.physicalAddress));
    } else if (user.affiliationType === AffiliationTypes.Decedent
      && isAccountSet7) {
      return !(user.affiliationType && user.organizationId
        && user.firstName && user.lastName
        && user.relation
        && user.dateOfBirth
        && user.dateOfDeath
        && !isInCompleteAddress(user.physicalAddress));
    }
    return addUserDisabled;
  };
  return (
    <CreateNewModal
      loading={loading || userLoading}
      disabled={disabled}
      title={t('affiliationTypes:addNewAffiliate')}
      buttonText={t('affiliationTypes:saveAffiliate')}
      onSubmit={addRelationship}
      modalButton={modalButton}
      sx={{ float: 'right', m: 1 }}
      icon={<></>}
      buttonType={buttonType ?? 'ICON'}
      hideSubmit={hideSubmitButton}
      onClose={() => {
        setUser(affiliateUser);
        setIsNewUser(false);
        setClient(undefined);
        setHideSubmitButton(true);
      }}
    >
      {!isNewUser && (<>
        <ListItem>
          {!accountType.includes('CORPORATE') && (<ClientSelect
            label={t('client')}
            autoFocus
            user={client}
            setUser={(newClientData) => {
              setClient(newClientData);
              setUser({
                ...user,
                firstName: newClientData?.firstName || '',
                lastName: newClientData?.lastName || '',
                sinExists: newClientData?.sinExists ?? false,
              });
              if (newClientData?.id) {
                fetchUser({
                  variables: {
                    userId: newClientData.id,
                  },
                });
              }
            }}
            fullWidth
            isJointAccount={user.affiliationType === AffiliationTypes.Joint}
          />)}
          {accountType.includes('CORPORATE') && (<Autocomplete
            id='controllable-relation-users'
            onChange={(event: any, newValue: User | null) => {
              setValue(newValue);
              if (newValue?.id) {
                fetchUser({
                  variables: {
                    userId: newValue.id,
                  },
                });
              }
              setUser({
                ...user,
                userId: newValue?.id || '',
                firstName: newValue?.firstName || '',
                lastName: newValue?.lastName || '',
                relation: AffiliationRelations.Other,
              });
              if (newValue) {
                setClient(newValue);
              }
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            value={value}
            getOptionLabel={(option) => generateClientNameString(option)}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {generateClientNameString(option)}
              </Box>
            )}
            options={options}
            fullWidth
            renderInput={(params) => <TextField
              {...params}
              label={t('client')}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />}
          />)}
        </ListItem>

        <ListItem>
          <TextField
            select
            label={t('household:relationModal.relationship')}
            data-testid="relation-user-type"
            fullWidth
            value={user.relation ?? ''}
            onChange={updateRelation}
            id='relation'
          >
            {getRelationship(user.affiliationType).map(
              (rel, index) => <MenuItem key={index} value={rel}>{t(`affiliationTypes:affiliationRelationship.${rel}`)}</MenuItem>,
            )}
          </TextField>
        </ListItem>

        <ListItem>
          <Button variant="contained" disabled={!(client && user.relation)} onClick={addExistingUser} fullWidth>{t('household:relationModal.addAffiliate')}</Button>
        </ListItem>
        <ListItem>
          {user.affiliationType !== AffiliationTypes.Joint && !accountType.includes('CORPORATE') && (
            <Button variant="outlined" onClick={() => setIsNewUser(true)} fullWidth>{t('household:relationModal.createAffiliate')}</Button>)}
          {user.affiliationType === AffiliationTypes.Joint && (
            <NewClient buttonType='BUTTON' modalButton={t('household:relationModal.createUser')} icon="" fullWidth />)}
        </ListItem>
      </>)}

      {isNewUser ? (
        <>
          <ListItem>
            <TextField
              select
              autoFocus
              label={t('household:relationModal.affiliationType')}
              data-testid="relation-user-type"
              fullWidth
              value={user.affiliationType}
              onChange={updateType}
            >
              {allowedTypes.map((type) => <MenuItem key={type} value={type}>{t(`affiliationTypes:${type}`)}</MenuItem>)}
            </TextField>
          </ListItem>
          {!relation
            && <ListItem>
              <TextField
                select
                label={t('household:relationModal.relationship')}
                data-testid="relation-user-type"
                fullWidth
                value={user.relation ?? ''}
                onChange={updateRelation}
                id='relation'
              >
                {getRelationship(user.affiliationType).map(
                  (rel, index) => <MenuItem key={index} value={rel}>{t(`affiliationTypes:affiliationRelationship.${rel}`)}</MenuItem>,
                )}
              </TextField>
            </ListItem>
          }
          {isAllocationRequired()
            && <ListItem>
              <TextField
                label={t('household:relationModal.allocation')}
                fullWidth
                data-testid="relation-user-allocation"
                value={user.allocation}
                onChange={updateAllocation}
                error={(Number(user.allocation) > 100)}
                id='allocation'
                type='number'
                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment>, inputProps: { min: 0, max: 100 } }}
              />
            </ListItem>
          }

          <ListItem>
            <TextField
              label={t('household:relationModal.firstName')}
              fullWidth
              data-testid="relation-user-firstName"
              value={user.firstName}
              onChange={updateField}
              id='firstName'
            />
          </ListItem>
          <ListItem>
            <TextField
              label={t('household:relationModal.middleName')}
              fullWidth
              data-testid="relation-user-middleName"
              value={user.middleName}
              onChange={updateField}
              id='middleName'
            />
          </ListItem>
          <ListItem>
            <TextField
              label={t('household:relationModal.lastName')}
              fullWidth
              data-testid="relation-user-lastName"
              value={user.lastName}
              onChange={updateField}
              id='lastName'
            />
          </ListItem>
          {!client && (<ListItem>
            <TextField
              label={t('client:details.sin')}
              fullWidth
              data-testid="sin"
              value={user.sin}
              onChange={(e) => setUser({ ...user, sin: e.target.value })}
              error={!/^[0-9]{9}$/.test(user.sin) && user.sin.length > 0}
              helperText={!/^[0-9]{9}$/.test(user.sin) && user.sin.length > 0 ? t('client:profile.fieldValidations.sin') : ''}
              id='sin'
              type='text'
            />
          </ListItem>)}
          {isDobRequired() && (<ListItem>
            <LocalizedDatePicker
              label={t('client:details.dateOfBirth')}
              value={user.dateOfBirth}
              openTo='year'
              onChange={(date) => setUser({ ...user, dateOfBirth: dayjs(date?.toString()).format('YYYY-MM-DD') })}
              renderInput={(params) => <TextField fullWidth {...params} error={false} />}
            />
          </ListItem>)}
          {isDodRequired() && (<ListItem>
            <LocalizedDatePicker
              label={t('client:details.dateOfDeath')}
              value={user.dateOfDeath}
              openTo='year'
              onChange={(date) => setUser({ ...user, dateOfDeath: dayjs(date?.toString()).format('YYYY-MM-DD') })}
              renderInput={(params) => <TextField fullWidth {...params} error={false} />}
            />
          </ListItem>)}
          {isGenderRequired() && (<ListItem>
            <TextField
              select
              label={t('client:details.gender')}
              data-testid="gender"
              fullWidth
              value={user.gender}
              onChange={(e) => setUser({ ...user, gender: e.target.value })}
            >
              {genderList.map((item) => <MenuItem key={item} value={item}>{t(`client:edit.genderOptions.${item}`)}</MenuItem>)}
            </TextField>
          </ListItem>)}
          {isAddressRequired() && (<ListItem>
            <AddressField
              label={t('client:details.physicalAddress')}
              address={user.physicalAddress}
              onChange={async (newPhysicalAddress) => setUser({ ...user, physicalAddress: newPhysicalAddress })}
            />
          </ListItem>)}

          {isAddressRequired() && user?.physicalAddress?.province === 'QC' && (<ListItem>
            <LocalizedDatePicker
              label={t('client:details.inProvinceSince')}
              value={user.inProvinceSince}
              openTo='year'
              onChange={(date) => setUser({ ...user, inProvinceSince: dayjs(date?.toString()).format('YYYY-MM-DD') })}
              renderInput={(params) => <TextField fullWidth {...params} error={false} />}
            />
          </ListItem>)}

          {isEmployementRequired() && (<>
            <ListItem>
              <TextField
                select
                label={t('client:details.employmentBox.employmentStatusTitle')}
                data-testid="employmentStatusTitle"
                fullWidth
                value={user.employmentStatus}
                onChange={(e) => setUser({ ...user, employmentStatus: e.target.value })}
              >
                {employementStatusList.map((item) => <MenuItem key={item} value={item}>{t(`client:edit.employmentStatusOptions.${item}`)}</MenuItem>)}
              </TextField>
            </ListItem>
            {!['STUDENT', 'UNEMPLOYED'].includes(user.employmentStatus) && (<>
              <ListItem>
                <TextField
                  label={t('client:details.employmentBox.jobTitle')}
                  fullWidth
                  data-testid="jobTitle"
                  value={user.jobTitle}
                  onChange={(e) => setUser({ ...user, jobTitle: e.target.value })}
                  id='jobTitle'
                />
              </ListItem>
              <ListItem>
                <TextField
                  label={t('client:details.employmentBox.companyTypeTitle')}
                  fullWidth
                  data-testid="companyTypeTitle"
                  value={user.companyType}
                  onChange={(e) => setUser({ ...user, companyType: e.target.value })}
                  id='companyTypeTitle'
                />
              </ListItem>
            </>)}
          </>)}
        </>
      ) : null}
      {isNewUser && hideSubmitButton ? (<ListItem>
        <Button disabled={validateForm()} variant="contained" onClick={addNewUser} fullWidth>{t('household:relationModal.createAUser')}</Button>
      </ListItem>) : null}
    </CreateNewModal>
  );
};

export default NewAffiliate;
