import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { FormTemplate } from '../../../../interfaces';
import { Box, Typography } from '../../../1-primative';
import { translateBackend } from '../../../../assets/i18n/config';
import {
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from '../../..';
import PreviewObject from './previewObject';
import { useLocalization } from '../../../../util/useLocalization';
import { UserContext } from '../../../../providers/userContextProvider';
import { getTitleLabelType } from './formCategories';

const TemplateViewerModal = ({
  onModalClose,
  formTemplate,
  origin,
}: {
  onModalClose: () => void,
  formTemplate: FormTemplate,
  origin: 'EDITOR' | 'TABLE',
}) => {
  const { t } = useTranslation(['shared', 'client', 'orgSettings']);
  const { localizedDateTime } = useLocalization();
  const [objectId, setObjectId] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const { activeOrganization } = useContext(UserContext);
  const navigate = useNavigate();
  const [previewhtml, setPreviewHtml] = useState('');
  const [applyHtml, setApplyHtml] = useState('');
  const previewNotAvailable = t('client:documents.previewNotAvailable');
  const isInherited = (l: FormTemplate) => l.organization?.id !== activeOrganization.id;
  return (
    <Dialog onClose={onModalClose} open={true} fullWidth maxWidth={previewhtml && showPreview ? 'lg' : 'sm'}>
      <DialogTitle onClose={onModalClose}>
        <Box mx={2} display='flex' justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
          {showPreview && previewhtml
            ? formTemplate.translatedDisplayName && formTemplate.translatedDisplayName.en !== ''
              ? translateBackend(formTemplate.translatedDisplayName)
              : t(`formTypes:${formTemplate.type}`)
            : ''}
          {!previewhtml && !showPreview && t('orgSettings:documentManagement.previewObject.selectTitle', { objectType: getTitleLabelType(formTemplate.type) })}
          {previewhtml && showPreview && (
            <PreviewObject
              onSelectObjectToPreview={(html, selectedObjectId) => {
                setPreviewHtml(html);
                setObjectId(selectedObjectId);
              }}
              formTemplateType={formTemplate.type}
              placement='TITLE'
              selectedId={objectId}
            />
          )}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ overflow: 'auto' }}>
        {!showPreview && (
          <PreviewObject
            onSelectObjectToPreview={(html, selectedObjectId) => {
              // setPreviewHtml(html);
              setApplyHtml(html);
              setObjectId(selectedObjectId);
            }}
            formTemplateType={formTemplate.type}
            placement='CONTENT'
          />
        )}
        <Box margin={2}>
          {previewhtml && showPreview && (
            <Box display='flex' alignItems={'center'} gap={2}>
              <Badge label={t(`orgSettings:documentManagement.templateState.${formTemplate.state}`)} color={formTemplate.state === 'ACTIVE' ? 'positive' : 'neutral'} />
              <Typography colorVariant='variant' variant='labelSmall'>
                {t('orgSettings:documentManagement.lastUpdatedAt')}
              </Typography>
              <Typography colorVariant='variant' variant='labelSmall'>
                {localizedDateTime(formTemplate?.updatedAt)}
              </Typography>
            </Box>
          )}

          {previewhtml && showPreview && (
            <Typography>
              <div
                className='content'
                dangerouslySetInnerHTML={{
                  __html: previewhtml === '' ? previewNotAvailable : previewhtml,
                }}
              ></div>
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogFooter>
        <Box mr={2} gap={1} display='flex' justifyContent='end'>
          {showPreview && !isInherited(formTemplate) && (
            <Button
              onClick={() => {
                if (origin === 'EDITOR') {
                  onModalClose();
                } else {
                  navigate(`/orgSettings/formTemplate/${formTemplate?.id}`);
                }
              }}
              type='button'
              variant='filled'
              leadingIcon={EditIcon}
              label={''}
            >
              {t('orgSettings:documentManagement.action.edit')}
            </Button>
          )}
          {showPreview && (
            <Button
              onClick={() => {
                // download code here
              }}
              type='button'
              variant='outlined'
              leadingIcon={DownloadIcon}
              label={''}
            >
              {t('orgSettings:documentManagement.action.download')}
            </Button>
          )}
          {!showPreview && (
            <Button
              onClick={() => {
                // apply code here
                setPreviewHtml(applyHtml);
                setShowPreview(true);
              }}
              type='button'
              variant='filled'
              label={''}
            >
              {t('orgSettings:documentManagement.action.apply')}
            </Button>
          )}
        </Box>
      </DialogFooter>
    </Dialog>
  );
};
export default TemplateViewerModal;
